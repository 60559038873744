import { Container } from 'dependency-injection';
import { merge as observableMerge, NEVER, Observable } from 'rxjs';
import { filter, map, mergeMapTo, switchMap, tap } from 'rxjs/operators';
import { Router } from '../Router';
import { SourceService } from '../SourceService';
import { StripeService } from '../StripeService';
import { analyticsFactory } from './analyticsFactory';
import { postProcessingFactory } from './postProcessingFactory';
import { SourceWithMetadata } from '../SourceService/types';
import { path } from 'ramda';
import { ReturnTargetComponentProps } from 'shared/components/ReturnTarget/component';
import { State } from 'router5';

const getPaymentAfterFreePeriodWillFail = (routeSnapshot: State) => {
  const paymentAfterFreePeriodWillFailParam = path([
    'params',
    'paymentAfterFreePeriodWillFail',
  ])(routeSnapshot);

  return (
    paymentAfterFreePeriodWillFailParam === 'true' ||
    paymentAfterFreePeriodWillFailParam === true
  );
};

type transformType = () => Observable<ReturnTargetComponentProps>;

export const returnTargetTransformFactory = (
  container: Container,
  isMigrationFlow: boolean = false,
): transformType => () => {
  const sourceService = container.get(SourceService);
  const stripeService = container.get(StripeService);
  const { route$, routeSnapshot } = container.get(Router);

  const fetchSource$ = route$.pipe(
    map(({ params }) => ({
      clientSecret: params.client_secret,
      id: params.source,
    })),
    filter(({ clientSecret, id }) => !!clientSecret && !!id),
    switchMap(({ clientSecret, id }) =>
      stripeService.retrieveSource({ clientSecret: clientSecret!, id: id! }),
    ),
    tap(({ source, error }) => {
      if (source) {
        sourceService.nextSource(source as SourceWithMetadata);
      }

      if (error) {
        throw error;
      }
    }),
    mergeMapTo(NEVER),
  );

  const postProcessing$ = postProcessingFactory(
    container,
    getPaymentAfterFreePeriodWillFail(routeSnapshot),
    isMigrationFlow,
  );
  const analytics$ = analyticsFactory(container);

  return observableMerge(
    postProcessing$,
    fetchSource$,
    analytics$,
  ) as typeof postProcessing$;
};
