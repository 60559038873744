import { injectable } from 'inversify';
import { BehaviorSubject } from 'rxjs';
import { CouponService } from '../CouponService';

@injectable()
export class CouponFormService {
  // Observable to store the input from the coupon field. This couponCode is not yet verfified,
  // The result of the verification is stored in the 'validatedCoupon$' Observable in the CouponService
  readonly couponCode$ = new BehaviorSubject<string>('');
  readonly couponFormVisible$ = new BehaviorSubject<boolean>(false);
  readonly buttonLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly couponService: CouponService) {}

  setCouponCode = (couponCode: string) => {
    this.couponCode$.next(couponCode);
  };

  showCouponForm = () => {
    this.couponFormVisible$.next(true);
  };

  setButtonLoading = (newValue: boolean) => {
    this.buttonLoading$.next(newValue);
  };

  submitForm = () => {
    this.setButtonLoading(true);

    this.couponService
      .submitCouponCode(this.couponCode$.value)
      .subscribe(() => {
        this.setButtonLoading(false);
      });
  };
}
