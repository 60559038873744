import { postConstruct, injectable } from 'inversify';
import * as Sentry from '@sentry/browser';
import { getEnvVariable } from 'utils/env';
import config, { isFunctionalTestEnv } from 'environment-config';
import { ErrorsService } from '../ErrorsService';
import { UserService } from '../UserService';
import { filter, map } from 'rxjs/operators';

const setExtraScope = (scope: Sentry.Scope, options: {}) => {
  for (let prop in options) {
    if (Object.prototype.hasOwnProperty.call(options, prop)) {
      scope.setExtra(prop, options[prop]);
    }
  }
};

@injectable()
export class ErrorLoggerService {
  constructor(
    private readonly userService: UserService,
    private readonly errorsService: ErrorsService,
  ) {
    if (isFunctionalTestEnv) {
      return;
    }

    Sentry.init({
      dsn: config.sentryProjectUri,
      release: getEnvVariable('REACT_APP_GIT_COMMIT'),
      environment: config.environmentName,
      ignoreErrors: [
        'Network Error',
        'Internal Server Error',
        'TiApp is not defined',
        'Image at url',
        'Unable to load library',
      ],
    });
  }

  @postConstruct()
  connectUserService() {
    this.userService.user$
      .pipe(
        filter((user) => !!user),
        map((user) => user!.uuid),
      )
      .subscribe(this.addUser);
  }

  captureMessage(message: string, options: {} = {}) {
    Sentry.withScope((scope) => {
      setExtraScope(scope, options);
      Sentry.captureMessage(message);
    });
  }

  readonly displayExceptionAndLogToSentry = (
    exception: Error,
    options: {} = {},
  ) => {
    this.errorsService.addError(exception, 'error.message.default');

    this.sendExceptionToSentry(exception, options);
  };

  readonly sendExceptionToSentry = (exception: Error, options: {} = {}) => {
    Sentry.withScope((scope) => {
      setExtraScope(scope, options);
      Sentry.captureException(exception);
    });
  };

  addUser = (uuid: string) => {
    Sentry.setUser({ id: uuid });
  };
}
