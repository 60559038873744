import { injectable } from 'inversify';
import {
  BehaviorSubject,
  merge as observableMerge,
  NEVER,
  Observable,
  of as observableOf,
  Subject,
  timer as observableTimer,
} from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';

export type DefinedDisplayError = {
  error: Error;
  errorId: string;
  timeout: number;
};

export type DisplayError =
  | DefinedDisplayError
  | {
      error: void;
      errorId: void;
      timeout: 0;
    };

@injectable()
export class ErrorsService {
  error$: Observable<DefinedDisplayError | false>;

  private readonly incomingError$ = new Subject<DisplayError>();
  private readonly errorSubject$ = new BehaviorSubject<
    DefinedDisplayError | false
  >(false);

  constructor() {
    this.setupIncomingErrors();
  }

  addError = (error: Error, errorId: string, timeout: number = 10000): void => {
    this.incomingError$.next({
      error,
      errorId,
      timeout,
    });
  };

  removeCurrentError() {
    this.incomingError$.next({
      timeout: 0 as 0,
      error: undefined,
      errorId: undefined,
    });
  }

  setupIncomingErrors() {
    this.incomingError$
      .pipe(
        switchMap((error) =>
          observableMerge(
            error.timeout === Infinity // this will never hide the error message
              ? (NEVER as Observable<never>)
              : (observableTimer(error.timeout).pipe(
                  mapTo(false),
                ) as Observable<false>),
            error.timeout === 0 ? NEVER : observableOf(error),
          ),
        ),
      )
      .subscribe(this.errorSubject$);

    this.error$ = this.errorSubject$.asObservable();
  }
}
