import {
  combineLatest as observableCombineLatest,
  Observable,
  of as observableOf,
  ReplaySubject,
} from 'rxjs';
import { injectable } from 'inversify';
import { SubscriptionTokenService } from '../SubscriptionToken/service';
import { catchSubscriptionError } from '../SubscriptionToken/errorHandler';
import { map, switchMap, take, catchError, tap } from 'rxjs/operators';
import {
  ValidatedCoupon,
  ValidateCouponRequestPayload,
  CouponCodeStatus,
} from './types';
import { filterEmpty } from 'utils';
import { SubscriptionPlansService, displayId } from '../SubscriptionPlans';
import { UserService } from '../UserService';
import {
  createPayload,
  getCouponErrorId,
  hasValidFreeOptInCoupon,
  CouponErrorId,
} from './helpers';
import {
  SubscriptionTokenResponse,
  Coupon,
  SubscriptionStreamResponse,
} from '../SubscriptionToken/types';
import { ErrorsService } from '../ErrorsService';
import { AnalyticsService } from '../AnalyticsService';
import { Router } from '../Router';
import { PaymentMethodName } from '../PaymentMethodsService';
import { halError } from 'utils/errors';
import { sanitizeStripeCouponMetadata } from 'utils/stripe';

interface Response {
  response: SubscriptionStreamResponse;
  error?: halError[] | Error | CouponErrorId;
}

type SuccessCallback = (x: {
  subscriptionToken: string;
  coupon: Coupon;
  couponCode: string;
  autoApply: boolean;
}) => Response;

type ErrorHandler = (x: {
  errors: halError[] | Error;
  couponCode: string;
  autoApply: boolean;
}) => Observable<Response>;

@injectable()
export class CouponService {
  /*
  Subject to store the result of a coupon validation.
  Initially the subject doens't emit any value.
  When there is no couponCode found in the url the subject is set to undefined
  When there is a coupon code found in the url it will be validated and the result stored here.
  The payment screen will wait for this subject to be either undefined, or have a validated coupon
  */
  readonly validatedCouponSubject$ = new ReplaySubject<
    ValidatedCoupon | undefined
  >(1);

  readonly validatedCoupon$: Observable<ValidatedCoupon | undefined>;

  constructor(
    private readonly subscriptionTokenService: SubscriptionTokenService,
    private readonly subscriptionPlansService: SubscriptionPlansService,
    private readonly userService: UserService,
    private readonly errorsService: ErrorsService,
    private readonly analyticsService: AnalyticsService,
    private readonly router: Router,
  ) {
    this.validatedCoupon$ = this.validatedCouponSubject$.asObservable();
  }

  setEmptyCoupon = () => {
    this.validatedCouponSubject$.next(undefined);
  };

  setValidatedCoupon = (validatedCoupon: ValidatedCoupon) => {
    this.validatedCouponSubject$.next(validatedCoupon);
  };

  validateCoupon = (
    payload: ValidateCouponRequestPayload,
    autoApply: boolean,
  ) => {
    return this.subscriptionTokenService.fetch(payload).pipe(
      map(({ data }: { data: SubscriptionTokenResponse }) => {
        const { coupon_code } = payload;
        const { _errors, subscription_token, coupon } = data;

        if (data._errors) {
          // eslint-disable-next-line no-throw-literal
          throw { errors: _errors, couponCode: coupon_code, autoApply };
        }

        return {
          subscriptionToken: subscription_token,
          coupon,
          couponCode: coupon_code,
          autoApply,
        };
      }),
    );
  };

  submitCouponCode = (couponCode: string, autoApply: boolean = false) => {
    return observableCombineLatest([
      filterEmpty(this.userService.user$),
      this.subscriptionPlansService.selectedPlanWithDefault$,
    ]).pipe(
      switchMap(([user, subscriptionPlan]) =>
        this.validateCoupon(
          createPayload(user, subscriptionPlan, couponCode),
          autoApply,
        ),
      ),
      take(1),
      map(this.onSubmitCouponCodeSuccess),
      catchError(this.onSubmitCouponCodeFailure),
    );
  };

  onSubmitCouponCodeFailure: ErrorHandler = ({
    errors,
    couponCode,
    autoApply,
  }) => {
    const couponError = getCouponErrorId(errors);

    if (couponError) {
      this.analyticsService.track('Redeem Coupon: Failed', {
        coupon_code: couponCode,
        reason: couponError,
      });

      this.setValidatedCoupon({
        couponCode,
        status: CouponCodeStatus.Invalid,
        error: couponError,
        isAutoApplied: autoApply,
      });

      return observableOf({
        response: SubscriptionStreamResponse.CouponInValidError,
        error: couponError,
      });
    }

    return catchSubscriptionError(this.errorsService.addError)(errors);
  };

  onSubmitCouponCodeSuccess: SuccessCallback = (response) => {
    const { couponCode, subscriptionToken, coupon, autoApply } = response;
    this.analyticsService.track('Redeem Coupon: Success', {
      coupon_code: couponCode,
    });

    this.subscriptionTokenService.subscriptionToken$.next(subscriptionToken);

    coupon.metadata = sanitizeStripeCouponMetadata(coupon.metadata);

    this.setValidatedCoupon({
      couponCode,
      status: CouponCodeStatus.Valid,
      coupon,
      isAutoApplied: autoApply,
    });

    return {
      response: SubscriptionStreamResponse.Success,
    };
  };

  setPaymentMethodIfFreeOptInCoupon = () => {
    observableCombineLatest([
      this.subscriptionPlansService.selectedPlanWithDefault$,
      this.validatedCoupon$,
    ])
      .pipe(
        take(1),
        tap(([selectedPlan, validatedCoupon]) => {
          if (hasValidFreeOptInCoupon(validatedCoupon)) {
            const { navigateToPaymentPage } = this.router;

            navigateToPaymentPage({
              productId: displayId(selectedPlan),
              paymentMethod: PaymentMethodName.FreeOptIn,
            });
          }
        }),
      )
      .subscribe();
  };
}
