import { injectable } from 'inversify';
import { RequestService } from '../RequestService';
import { map } from 'rxjs/operators';

interface BuyCreditsProps {
  creditBundleId: string;
  fullName: string;
  country: string;
  email: string;
}

interface CreditBundlesResponse {
  payment_intent_client_secret: string;
  invoice_id: string;
}

@injectable()
export class BuyCreditsSubmitService {
  constructor(private readonly requestService: RequestService) {}

  submitBuyCreditsRequest = ({
    creditBundleId,
    fullName,
    country,
    email,
  }: BuyCreditsProps) =>
    this.requestService
      .post<CreditBundlesResponse>('credit_buy', {
        credit_bundle_id: creditBundleId,
        full_name: fullName,
        billing_country: country,
        email,
      })
      .pipe(map(({ data }) => data));
}
