import { injectable } from 'inversify';
import {
  merge as observableMerge,
  Observable,
  of as observableOf,
  timer as observableTimer,
  BehaviorSubject,
} from 'rxjs';
import { map, switchMap, switchMapTo, take } from 'rxjs/operators';
import { RequestService } from '../RequestService';
import { isFunctionalTestEnv } from 'environment-config';
import { includeCounterToRequestOptionsIfFunctionalTest } from 'utils/functionalTest';

const DEFAULT_RETRY_AFTER_SECONDS = 5;

enum InvoiceStatus {
  Processed = 'processed',
  Pending = 'pending',
  NotFound = 'not_found',
}

type StatusResponse = {
  status: InvoiceStatus;
};

@injectable()
export class InvoiceStatusService {
  readonly invoiceId$ = new BehaviorSubject<string | null>(null);

  public functionalTestCounter: number;

  public invoiceStatus$ = (invoiceId: string) => {
    const options = includeCounterToRequestOptionsIfFunctionalTest(
      isFunctionalTestEnv,
      this.functionalTestCounter,
      { whitelistStatusCodes: [404] },
    );

    return this.requestService
      .get<StatusResponse>(
        'invoice_status',
        {
          invoice_id: invoiceId,
        },
        options,
      )
      .pipe(take(1));
  };

  public isInvoiceProcessed$ = (invoiceId: string) =>
    this.invoiceStatus$(invoiceId).pipe(
      map(({ data: { status }, response }) => {
        let isInvoiceProcessed: boolean;

        switch (status) {
          case InvoiceStatus.Processed:
            isInvoiceProcessed = true;
            break;
          case InvoiceStatus.Pending:
            isInvoiceProcessed = false;
            break;
          case InvoiceStatus.NotFound:
            throw new Error(`Invoice not found: "${invoiceId}"`);
          default:
            throw new Error(`Unknown invoice status: "${status}"`);
        }

        const retryAfterSeconds =
          Number(response.headers.get('retry-after')) ||
          DEFAULT_RETRY_AFTER_SECONDS;

        this.functionalTestCounter = this.functionalTestCounter + 1;

        return {
          isInvoiceProcessed,
          retryAfter: retryAfterSeconds * 1000,
        };
      }),
    );

  public isInvoiceProcessedRetry$ = (invoiceId: string): Observable<boolean> =>
    this.isInvoiceProcessed$(invoiceId).pipe(
      switchMap(({ isInvoiceProcessed, retryAfter }) => {
        if (isInvoiceProcessed) {
          // everything is cool and we can stop retrying
          return observableOf(true);
        }

        return observableMerge(
          // submit it early
          observableOf(false),
          // but also retry again
          observableTimer(retryAfter).pipe(
            switchMapTo(this.isInvoiceProcessedRetry$(invoiceId)),
          ),
        );
      }),
    );

  constructor(private readonly requestService: RequestService) {
    this.functionalTestCounter = 0;
  }
}
