import { propEq } from 'ramda';
import { lifecycle } from '@blendle/recompose';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import {
  SubscriptionPlansService,
  SubscriptionTypes,
  AnalyticsService,
  TranslationService,
  PaymentMethodsService,
  SegmentAnalyticsService,
} from 'shared/services';
import { Container } from 'inversify';

const isOptOut = propEq('value', SubscriptionTypes.OptOut);

export const paymentScreenShownAnalytics = (container: Container) =>
  lifecycle({
    componentDidMount() {
      const analyticsService = container.get(AnalyticsService);
      const segmentAnalyticsService = container.get(SegmentAnalyticsService);
      const translationService = container.get(TranslationService);
      const { subscriptionType$ } = container.get(SubscriptionPlansService);
      const { selectedMethodWithDefault$ } = container.get(
        PaymentMethodsService,
      );

      subscriptionType$.pipe(first(), filter(isOptOut)).subscribe(async () => {
        const { GoogleAnalytics } = await import(
          /* webpackChunkName: "GoogleAnalyticsService" */ 'shared/services/GoogleAnalyticsService'
        );
        const googleAnalytics = container.get(GoogleAnalytics);
        googleAnalytics.trackPaymentRequest();
      });

      observableCombineLatest([
        translationService.analyticsLocationName$,
        selectedMethodWithDefault$,
      ])
        .pipe(
          first(),
          map(([locale, method]) => {
            analyticsService.track(
              'Provider Subscription/Payment Screen Shown',
              {
                locale: locale!,
                payment_method: method.type,
              },
            );
            segmentAnalyticsService.page({
              pageName: 'Subscription Payment',
              properties: {
                locale: locale!,
                payment_method: method.type,
              },
            });
          }),
        )
        .subscribe();
    },
  });
