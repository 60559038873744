import { SubscriptionPlan } from '../SubscriptionPlans/types';
import { ValidatedCoupon } from '../CouponService/types';

/*
Initially we only had iDeal and CreditCard, at that point the name paymentMethod made sense.
Right now users can also start a subscription using existing details or redeem an opt-in coupon.
The concept of a paymentMethod becomes a bit stange.
Our payment page is now more a place where you get a premium subscirption
and in case you need to pay for it you select a way of payment that will be used for the future payments.
therefor it might make sense to refactor/restructure the paymentMethodService
*/
export enum PaymentMethodName {
  Card = 'card',
  IDeal = 'ideal',
  // this string is 'existing_source' since initially this method consisted only sources,
  // now its both sources and payment methods. Keeping the string like this
  // so that the name in the analytics events stays consistent.
  ExistingMethod = 'existing_source',
  FreeOptIn = 'free_opt_in',
  SepaDebit = 'sepa_debit',
}

export interface PaymentMethodResponse {
  payment_methods: PaymentMethod[];
  existing_payment_mehtods: ExistingPaymentMethod[];
  sources: ExistingSource[];
}

interface BasePaymentMethod {
  type: string;
  name: string;
  authorization_payment?: { eur: number };
}

export interface PaymentMethodCard extends BasePaymentMethod {
  type: PaymentMethodName.Card;
}

export type Bank = string;

export interface PaymentMethodIDeal extends BasePaymentMethod {
  type: PaymentMethodName.IDeal;
}

export interface PaymentMethodSepaDebit extends BasePaymentMethod {
  type: PaymentMethodName.SepaDebit;
}

interface BaseSource {
  source_id: string;
  last_4_digits: string;
  country: string;
}

interface Logo {
  svg: string;
  png: string;
}

export interface CardSource extends BaseSource {
  type: PaymentMethodName.Card;
  expiration_month: number;
  expiration_year: number;
  brand: string;
  logo: Logo;
}

export interface SepaDebitSource extends BaseSource {
  type: PaymentMethodName.SepaDebit;
  name: string;
  code: string;
  bank_name?: string;
  logo: Logo;
}

export type ExistingSource = CardSource | SepaDebitSource;

interface BaseExistingPaymentMethod {
  payment_method_id: string;
  last_4_digits: string;
  country: string;
}

export interface CardPaymentMethod extends BaseExistingPaymentMethod {
  type: PaymentMethodName.Card;
  expiration_month: number;
  expiration_year: number;
  brand: string;
  logo: Logo;
}

export interface DebitSepaPaymentMethod extends BaseExistingPaymentMethod {
  type: PaymentMethodName.SepaDebit;
  name: string;
  code: string;
  bank_name?: string;
  logo: Logo;
}

export type ExistingPaymentMethod = CardPaymentMethod | DebitSepaPaymentMethod;

export interface PaymentMethodExistingSepaDebitOrCard
  extends BasePaymentMethod {
  type: PaymentMethodName.ExistingMethod;
  existingMethods: ReadonlyArray<ExistingSource | ExistingPaymentMethod>;
}

export interface PaymentMethodFreeOptIn extends BasePaymentMethod {
  type: PaymentMethodName.FreeOptIn;
}

export type PaymentMethod =
  | PaymentMethodCard
  | PaymentMethodIDeal
  | PaymentMethodExistingSepaDebitOrCard
  | PaymentMethodFreeOptIn
  | PaymentMethodSepaDebit;

export type supportedPaymentTypes =
  | PaymentMethodName.Card
  | PaymentMethodName.IDeal
  | PaymentMethodName.ExistingMethod
  | PaymentMethodName.FreeOptIn
  | PaymentMethodName.SepaDebit;

export enum StripePaymentFlows {
  PaymentMethod = 'PaymentMethod',
  Source = 'Source',
  SepaDebitSource = 'SepaDebitSource',
  ExistingMethod = 'ExistingMethod',
  FreeOptIn = 'FreeOptIn',
}

export interface PaymentMethodsProps {
  paymentMethods: ReadonlyArray<PaymentMethod>;
  selectMethod: (method: string) => void;
  active: string | undefined;
  selectedBank?: Bank;
  handleBankSelection: (value: Bank) => void;
  isDeeplink?: boolean;
  plan: SubscriptionPlan;
  validatedCoupon?: ValidatedCoupon;
}
