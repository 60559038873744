import { injectable } from 'inversify';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { filterEmpty } from 'utils/filter-empty';
import { getSessionId } from 'utils/storage';
import { Router } from '../Router';
import { UserService } from '../UserService';
import { UtmService } from '../UtmService';
import {
  internalLocationForRouteAndEntryPoint,
  version,
  trackingUid,
} from './helpers';
import { AbTestPayload } from './types';
import {
  Analytics,
  AnalyticsInstance,
  EventProperties,
  PageParams,
} from '@blendle/analytics';
import { isSegmentEnabled } from 'features';
import config from 'environment-config';

@injectable()
export class SegmentAnalyticsService {
  private readonly analytics: AnalyticsInstance;
  private readonly enabled: boolean;
  private readonly sessionId = getSessionId();

  constructor(
    private readonly userService: UserService,
    private readonly utmService: UtmService,
    private readonly routerService: Router,
  ) {
    this.enabled = isSegmentEnabled();

    if (this.enabled) {
      this.analytics = Analytics({
        environment: config.segmentEnvironmentName,
      });
      this.analytics.init();

      this.identify().subscribe();
    }
  }

  track(eventName: string, properties: EventProperties = {}) {
    if (!this.enabled) {
      return;
    }

    this.defaultProperties$.subscribe((defaultProperties) => {
      this.analytics.track({
        eventName,
        properties: {
          ...properties,
          ...defaultProperties,
        },
        options: this.options,
      });
    });
  }

  page({ pageName, properties }: PageParams = {}) {
    if (!this.enabled) {
      return;
    }

    this.defaultProperties$.subscribe((defaultProperties) => {
      this.analytics.page({
        pageName,
        properties: {
          ...properties,
          ...defaultProperties,
        },
        options: this.options,
      });
    });
  }

  private identify() {
    return this.userService.user$.pipe(
      filterEmpty,
      take(1),
      map((user) => {
        this.analytics.identify({
          userId: trackingUid(user),
          options: this.options,
        });
      }),
    );
  }

  private get defaultProperties$() {
    const internalLocation$ = this.userService.signupEntryPoint$.pipe(
      map((signupEntryPoint) =>
        internalLocationForRouteAndEntryPoint(
          this.routerService,
          signupEntryPoint,
        ),
      ),
    );

    const user$ = this.userService.user$.pipe(filterEmpty, take(1));

    const trackingUid$ = user$.pipe(map((user) => trackingUid(user)));

    const abTest$ = user$.pipe(
      map((user) => {
        const abTests = (user._embedded && user._embedded.ab_tests) || [];

        const abTestData = abTests.reduce((reducedValue, current) => {
          reducedValue[current.name] = current.group;
          return reducedValue;
        }, {} as AbTestPayload);

        if (abTests.length > 0) {
          abTestData.ab_tests_ids = abTests.map(({ name }) => name).join(',');
        }

        return abTestData;
      }),
    );

    return combineLatest([trackingUid$, abTest$, internalLocation$]).pipe(
      map(([trackingUid, abTest, internalLocation]) => ({
        ...abTest,
        ...this.utmService.getUtmAsAdIdPayload(),
        blendle_session_id: this.sessionId,
        userId: trackingUid,
        internal_location: internalLocation,
        sent_to_segment: true,
      })),
      take(1),
    );
  }

  private get options() {
    return {
      context: {
        app: {
          build: version,
        },
        campaign: {
          ...this.utmService.getUtmAsSegmentAnalyticsPayload(),
        },
      },
    };
  }
}
