import { injectable } from 'inversify';

import { RequestService } from '../RequestService';
import { map } from 'rxjs/operators';

export enum SubscriptionCreateStatus {
  Consumed = 'consumed',
  Processed = 'processed',
  Pending = 'pending',
  Unprocessable = 'unprocessable',
  Chargeable = 'chargeable',
  Unknown = 'unknown',
}

enum SubscriptionErrorCodes {
  InvalidNumber = 'invalid_number', // The card number is not a valid credit card number.
  InvalidExpiryMonth = 'invalid_expiry_month', // The card's expiration month is invalid.
  InvalidExpiryYear = 'invalid_expiry_year', // The card's expiration year is invalid.
  InvalidCVC = 'invalid_cvc', // The card's security code is invalid.
  InvalidSwipeData = 'invalid_swipe_data', // The card's swipe data is invalid.
  IncorrectNumber = 'incorrect_number', // The card number is incorrect.
  ExpiredCard = 'expired_card', // The card has expired.
  IncorrectCVC = 'incorrect_cvc', // The card's security code is incorrect.
  IncorrectZIP = 'incorrect_zip', // The card's zip code failed validation.
  CardDeclined = 'card_declined', // The card was declined.
  Missing = 'missing', // There is no card on a customer that is being charged.
  ProcessingError = 'processing_error', // An error occurred while processing the card.
}

export enum IntentStatus {
  RequiresPaymentMethod = 'requires_payment_method',
  RequiresConfirmation = 'requires_confirmation',
  RequiresAction = 'requires_action',
  Processing = 'processing',
  Canceled = 'canceled',
  Succeeded = 'succeeded',
}

export interface Intent {
  status: IntentStatus;
  client_secret: string;
}

export type CreateSubscriptionResponse =
  | {
      status: void;
      payment_intent: void;
      pending_setup_intent: void;
      _errors: {
        code: SubscriptionErrorCodes;
        id: string;
        message: string;
        param: string;
      }[];
    }
  | {
      _errors: void;
      status: SubscriptionCreateStatus;
      payment_intent?: Intent;
      pending_setup_intent?: Intent;
    };

interface TokenPayload {
  stripe_token: string;
  subscription_token: string;
}
interface PaymentMethodPayload {
  payment_method_id: string;
  subscription_token: string;
}

interface ExistingSourcePayload {
  source_id: string;
  subscription_token: string;
}

interface FreeOptInSourcePayload {
  subscription_token: string;
}

@injectable()
export class SubscriptionsService {
  constructor(private readonly requestService: RequestService) {}

  createSubscription(
    payload:
      | PaymentMethodPayload
      | TokenPayload
      | ExistingSourcePayload
      | FreeOptInSourcePayload,
  ) {
    return this.requestService
      .post<CreateSubscriptionResponse>(
        'create_subscription',
        payload,
        {},
        {
          whitelistStatusCodes: [402],
        },
      )
      .pipe(map(({ data }) => data));
  }
}
