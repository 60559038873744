import { hasValidCoupon } from 'shared/services/CouponService';
import { ValidatedCoupon } from 'shared/services/CouponService/types';
import { rootNode, joinRouteParts } from '../router';
import { Router as Router5 } from 'router5';
import { pipe, filter, compose, join, not, isEmpty } from 'ramda';
import {
  StartSubscriptionChildRouteName,
  BuyCreditsChildRouteName,
} from 'routes';

const joinQueryParams = pipe(filter(compose(not, isEmpty)), join('&'));

interface GetRedirectUrlProps {
  utmAsUrlParams: string;
  returnPath: string;
  subscriptionToken?: string;
  validatedCoupon?: ValidatedCoupon;
  invoiceId?: string;
  bank?: string;
  paymentMethod?: string;
  numberOfCredits?: number;
  productId?: string;
}

export const getRedirectUrl = ({
  utmAsUrlParams,
  subscriptionToken,
  returnPath,
  validatedCoupon,
  invoiceId,
  bank,
  paymentMethod,
  numberOfCredits,
  productId,
}: GetRedirectUrlProps) => {
  const baseUrl = `${window.location.origin}${returnPath}`;

  const queryParams = [
    utmAsUrlParams,
    ...(subscriptionToken ? [`subscription_token=${subscriptionToken}`] : []),
    ...(invoiceId ? [`invoice_id=${invoiceId}`] : []),
    ...(bank ? [`bank=${bank}`] : []),
    ...(paymentMethod ? [`payment_method=${paymentMethod}`] : []),
    ...(numberOfCredits ? [`number_of_credits=${numberOfCredits}`] : []),
    ...(productId ? [`product_id=${productId}`] : []),
    hasValidCoupon(validatedCoupon)
      ? `couponCode=${validatedCoupon!.couponCode}&autoApply=${
          validatedCoupon!.isAutoApplied
        }`
      : '',
  ];

  return `${baseUrl}?${joinQueryParams(queryParams)}`;
};

interface GetReturnTypeProps {
  currentRoute: string;
  returnChildRoute: StartSubscriptionChildRouteName | BuyCreditsChildRouteName;
  buildPath: Router5['buildPath'];
}

export function getReturnPath({
  currentRoute,
  returnChildRoute,
  buildPath,
}: GetReturnTypeProps): string {
  const base = rootNode(currentRoute);
  const route = joinRouteParts(base, returnChildRoute);

  return buildPath(route, {});
}
