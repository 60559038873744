import {
  SubscriptionTokenRequestPayload,
  Coupon,
} from '../SubscriptionToken/types';

export enum CouponCodeStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
}

export interface ValidatedCoupon {
  couponCode: string;
  status: CouponCodeStatus;
  error?: string;
  coupon?: Coupon;
  isAutoApplied: boolean;
}

export interface ValidateCouponRequestPayload
  extends SubscriptionTokenRequestPayload {
  coupon_code: string;
}
