import styled, { css, keyframes } from 'styled-components/macro';

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loadingBeforeRule = css`
  animation: ${loading} 1.3s infinite ease 1.5s;
`;

const loadingAfterRule = css`
  animation: ${loading} 1.3s infinite ease;
`;

export const ResizeSpinLoader = styled.div`
  border-radius: 50%;
  box-shadow: inset 0 0 0 1em;
  color: #333;
  font-size: 5px;
  height: 10em;
  margin: 55px auto;
  position: relative;
  text-indent: -99999em;
  transform: translateZ(0);
  width: 10em;
  &:before {
    ${loadingBeforeRule}
    background: #fff;
    border-radius: 50%;
    border-radius: 10.2em 0 0 10.2em;
    content: '';
    height: 10.2em;
    left: -0.1em;
    position: absolute;
    top: -0.1em;
    transform-origin: 5.2em 5.1em;
    width: 5.2em;
  }
  &:after {
    ${loadingAfterRule}
    background: #fff;
    border-radius: 50%;
    border-radius: 0 10.2em 10.2em 0;
    content: '';
    height: 10.2em;
    left: 5.1em;
    position: absolute;
    top: -0.1em;
    transform-origin: 0px 5.1em;
    width: 5.2em;
  }
`;
