import { injectable } from 'inversify';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { createEventHandler } from '@blendle/recompose';
import { startWith } from 'rxjs/operators';
import { ErrorsService } from '../ErrorsService';
import { CouponErrorId } from '../CouponService';

@injectable()
export class ChangeSubscriptionFormService {
  readonly couponCodeError$ = new BehaviorSubject<CouponErrorId | undefined>(
    undefined,
  );

  readonly buttonLoading$ = new BehaviorSubject(false);

  ibanValid$: Observable<boolean>;
  handleIbanFormStatusChange: (value: boolean) => void;

  submit$: Observable<React.FormEvent<HTMLFormElement>>;

  private readonly submitSubject$ = new Subject<
    React.FormEvent<HTMLFormElement>
  >();

  constructor(private readonly errorsService: ErrorsService) {
    this.submit$ = this.submitSubject$.asObservable();

    const {
      handler: handleIbanFormStatusChange,
      stream: ibanValid$,
    } = createEventHandler<boolean, Observable<boolean>>();

    this.handleIbanFormStatusChange = handleIbanFormStatusChange;
    this.ibanValid$ = ibanValid$.pipe(startWith(false));
  }

  handleIbanElementChange = ({ complete }: { complete: boolean }) => {
    this.handleIbanFormStatusChange(complete);
  };

  readonly handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.errorsService.removeCurrentError();
    this.couponCodeError$.next(undefined);
    this.buttonLoading$.next(true);
    this.submitSubject$.next(event);
  };
}
