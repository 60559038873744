export enum FacebookEvent {
  PageView = 'PageView',
  ViewContent = 'ViewContent',
  StartTrial = 'StartTrial',
}

export function trackFacebookEvent(event: FacebookEvent) {
  if (typeof window.fbq === 'function') {
    window.fbq('track', event);
  }
}
