import { container } from 'dependency-injection';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { componentFromStream } from '@blendle/recompose';
import { merge as observableMerge } from 'rxjs';
import { filter, map, mapTo, tap } from 'rxjs/operators';
import styled from 'styled-components/macro';
import {
  DefinedDisplayError,
  ErrorsService,
} from '../../services/ErrorsService';
import { ErrorIcon } from './styles';
import { color } from 'shared/styled-helpers';
import { SupportEmailLink } from '../SupportEmailLink';

const Container = styled.div`
  box-sizing: content-box;
  width: 380px;
  height: auto;
  background: #ffebec;
  border-radius: 3px;
  color: ${color('error')};
  max-width: calc(100% - 46px);
  margin: 0 auto;
  position: absolute;
  padding: 15px 10px 12px 36px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-align: center;
  animation: slideIn 0.2s both ease-out;
  a {
    color: inherit;
  }
`;
const StyledErrorIcon = styled(ErrorIcon)`
  position: absolute;
  left: 10px;
`;

const scrollToTop = () => {
  if (window.scrollTo) {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }
};

export const ErrorMessage = componentFromStream<{}>(() => {
  const errorsService = container.get(ErrorsService);

  const error$ = errorsService.error$.pipe(
    filter<DefinedDisplayError>(
      (error) => typeof error === 'object' && Boolean(error.errorId),
    ),
    tap(scrollToTop),
    map((error) => (
      <Container data-testid="global-error-message">
        <StyledErrorIcon />
        <span>
          <FormattedMessage
            id={error.errorId}
            values={{ email: SupportEmailLink }}
          />
        </span>
      </Container>
    )),
  );

  const noError$ = errorsService.error$.pipe(
    filter((error) => typeof error === 'boolean' && error === false),
    mapTo(null),
  );

  return observableMerge(error$, noError$);
});
