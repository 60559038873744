import React from 'react';
import {
  Notification,
  NotificationIcon,
  NotificationBody,
  Columns,
} from '@blendle/lego';
import { Title, StyledRows } from './styles';

interface Props {
  title: React.ReactNode;
  body: React.ReactNode;
  icon?: React.ReactNode;
}

export const BaseNotification = ({ title, body, icon }: Props) => (
  <Notification>
    <Columns>
      {icon && <NotificationIcon>{icon}</NotificationIcon>}
      <StyledRows>
        <Title>{title}</Title>
        <NotificationBody>{body}</NotificationBody>
      </StyledRows>
    </Columns>
  </Notification>
);
