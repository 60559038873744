import { css, Interpolation, StyledProps } from 'styled-components/macro';

import { theme as themeType } from '../theme';

const breakpoints = {
  tiny: 320,
  extraSmall: 600,
  small: 768,
  medium: 992,
  large: 1200,
};

function breakpointMinWidth(minWidth: number) {
  return function breakpoint<P>(
    strings: TemplateStringsArray,
    ...interpolations: Interpolation<StyledProps<P>>[]
  ) {
    return css`
      @media (min-width: ${minWidth}px) {
        ${css(strings, ...interpolations)};
      }
    `;
  };
}

// Tiny devices
export const tinyUp = breakpointMinWidth(breakpoints.tiny + 1);

// Small devices (landscape phones, more than 600px)
export const smallUp = breakpointMinWidth(breakpoints.extraSmall + 1);

// Medium devices (tablets, more than 768px)
export const mediumScreenUp = breakpointMinWidth(breakpoints.small + 1);

// Large devices (desktops, more than 992px)
export const largeScreenUp = breakpointMinWidth(breakpoints.medium + 1);

// Large devices (desktops, more than 1200px)
export const extraLargeScreenUp = breakpointMinWidth(breakpoints.large + 1);

type props = { theme?: typeof themeType };

export const size = (sizeName: string) => ({ theme }: props) =>
  theme!.size[sizeName];

export const fontSize = (sizeName: string) => ({ theme }: props) =>
  size('font')({ theme })[sizeName];

export const color = (colorName: string) => ({ theme }: props) => {
  if (colorName.includes('.')) {
    const [first, second] = colorName.split('.', 2);
    return theme!.color[first][second];
  }
  return theme!.color[colorName];
};

export const primaryFont = 'GT-Walsheim';
