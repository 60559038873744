import { uuid } from './uuid';

export function parseDocumentCookies(
  cookies: string = document.cookie,
): { [key: string]: string } {
  return cookies
    .split(';')
    .map((cookie) => cookie.trim())
    .reduce((acc, current) => {
      const [key, value] = current.split('=');

      // It is possible for the same cookie to be in document.cookie twice when the domains are different.
      if (acc.hasOwnProperty(key)) {
        return acc;
      }

      return { ...acc, [key]: value };
    }, {});
}

export const setNewSessionId = () => {
  const uuidToStore = uuid();
  const expireAt = new Date();
  expireAt.setDate(expireAt.getDate() + 365); // one year from now

  document.cookie = `uuid=${uuidToStore}; expires=${expireAt.toUTCString()}; domain=.blendle.com; path=/;`;

  return uuidToStore;
};

export const getSessionId = () =>
  parseDocumentCookies().uuid || setNewSessionId();

export const getRefreshToken = (cookies?: string) => {
  const parsedCookies = parseDocumentCookies(cookies);

  return parsedCookies.refreshToken || '';
};

export function saveRefreshToken(token: string) {
  const expireAt = new Date();
  expireAt.setDate(expireAt.getDate() + 365); // one year from now

  document.cookie = `refreshToken=${encodeURIComponent(
    token,
  )}; expires=${expireAt.toUTCString()}; path=/`;
}

export function deletePaymentProjectRefreshTokenCookie() {
  document.cookie =
    'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
}

export function saveSuccessPath(path: string) {
  localStorage.removeItem('successPath');
  localStorage.setItem('successPath', path);
}

export function saveInternalLocation(internalLocation: string) {
  localStorage.removeItem('internalLocation');
  localStorage.setItem('internalLocation', internalLocation);
}
