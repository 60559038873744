import { postConstruct, injectable } from 'inversify';
import { BehaviorSubject, merge as observableMerge, NEVER, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  mergeMapTo,
  share,
  switchMap,
  tap,
  catchError,
} from 'rxjs/operators';
import { joinRouteParts, rootNode } from 'utils/router';
import { ErrorsService } from '../ErrorsService';
import { Router } from '../Router';
import {
  SubscriptionsService,
  SubscriptionCreateStatus,
  CreateSubscriptionResponse,
  Intent,
} from '../SubscriptionsService';
import { StripeService } from '../StripeService';
import {
  handleIntent,
  subscriptionResponseHasErrors,
  subscriptionResponseHasNoErrors,
  IntentType,
} from './helpers';
import {
  SourceWithMetadata,
  PaymentMethodWithMetadata,
  ExistingSourceWithMetadata,
  FreeOptInSourceWithMetadata,
  ExistingPaymentMethodWithMetadata,
} from './types';
import { refreshSubscriptionToken } from '../SubscriptionToken/helpers';
import { AnalyticsService } from '../AnalyticsService';
import { subscriptionProductUid } from '../SubscriptionPlans';
import { getCouponCodeFromSubscriptionToken } from '../ReturnService/helper';
import { pathOr } from 'ramda';
import { PaymentMethodName } from '../PaymentMethodsService';
import { StartSubscriptionChildRouteName } from 'routes';

const subscriptionToken = pathOr('', ['metadata', 'subscription_token']);
const productId = pathOr('', ['metadata', 'product_id']);

@injectable()
export class SourceService {
  readonly source$ = new BehaviorSubject<SourceWithMetadata | null>(null);
  readonly paymentMethod$ = new BehaviorSubject<PaymentMethodWithMetadata | null>(
    null,
  );
  readonly existingSource$ = new BehaviorSubject<ExistingSourceWithMetadata | null>(
    null,
  );
  readonly existingPaymentMethod$ = new BehaviorSubject<ExistingPaymentMethodWithMetadata | null>(
    null,
  );
  readonly freeOptInSource$ = new BehaviorSubject<FreeOptInSourceWithMetadata | null>(
    null,
  );

  private readonly loadingSubject$ = new BehaviorSubject<boolean>(false);
  // tslint:disable-next-line:member-ordering
  readonly loading$ = this.loadingSubject$.asObservable();

  constructor(
    private readonly errorsService: ErrorsService,
    private readonly router: Router,
    private readonly subscriptionsService: SubscriptionsService,
    private readonly stripeService: StripeService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  nextSource = (source: SourceWithMetadata) => this.source$.next(source);

  nextPaymentMethod = (paymentMethod: PaymentMethodWithMetadata) =>
    this.paymentMethod$.next(paymentMethod);

  nextExistingSource = (existingSource: ExistingSourceWithMetadata) =>
    this.existingSource$.next(existingSource);

  nextExistingPaymentMethod = (
    existingPaymentMethod: ExistingPaymentMethodWithMetadata,
  ) => this.existingPaymentMethod$.next(existingPaymentMethod);

  nextFreeOptInSource = (freeOptInSource: FreeOptInSourceWithMetadata) =>
    this.freeOptInSource$.next(freeOptInSource);

  @postConstruct()
  redirects() {
    const source$ = this.source$.pipe(
      filter((source) => !!source),
      distinctUntilChanged(),
      share(),
    ) as BehaviorSubject<SourceWithMetadata>;

    const paymentMethod$ = this.paymentMethod$.pipe(
      filter((paymentMethod) => !!paymentMethod),
      distinctUntilChanged(),
      share(),
    ) as BehaviorSubject<PaymentMethodWithMetadata>;

    const existingSource$ = this.existingSource$.pipe(
      filter((existingSource) => !!existingSource),
      distinctUntilChanged(),
      share(),
    ) as BehaviorSubject<ExistingSourceWithMetadata>;

    const existingPaymentMethod$ = this.existingPaymentMethod$.pipe(
      filter((existingPaymentMethod) => !!existingPaymentMethod),
      distinctUntilChanged(),
      share(),
    ) as BehaviorSubject<ExistingPaymentMethodWithMetadata>;

    const freeOptInSource$ = this.freeOptInSource$.pipe(
      filter((freeOptInSource) => !!freeOptInSource),
      distinctUntilChanged(),
      share(),
    ) as BehaviorSubject<FreeOptInSourceWithMetadata>;

    // Start of the iDeal flow
    source$
      .pipe(
        filter(
          (source) =>
            source.flow === 'redirect' &&
            !!source.redirect &&
            source.redirect.status === 'pending',
        ),
      )
      .subscribe((source) => {
        window.location.assign(source.redirect!.url);
      });

    // Error handling of the iDeal flow
    source$
      .pipe(filter((source) => source.status === 'failed'))
      .subscribe((source) => {
        setTimeout(() => {
          // make sure redirect is always in the next tick to send failed payments events
          this.router.navigateToPaymentPage({
            productId: this.router.routeSnapshot.params.product_id,
            paymentMethod: source.type as PaymentMethodName,
            callback: () =>
              this.errorsService.addError(new Error(), 'error.message.payment'),
            removeStripeUrlParams: true,
          });
        });
      });

    // SepaDebit flow
    source$
      .pipe(
        filter(
          (source) => source.flow === 'none' && source.status === 'chargeable',
        ),
        switchMap((source) => {
          this.loadingSubject$.next(true);

          return of(source);
        }),
      )
      .subscribe(this.navigateToReturnPage);

    const startSubscriptionWithPaymentMethod = (
      paymentMethod:
        | PaymentMethodWithMetadata
        | ExistingPaymentMethodWithMetadata,
    ) => {
      this.loadingSubject$.next(true);

      const createSubscription$ = this.subscriptionsService
        .createSubscription({
          payment_method_id: paymentMethod.id,
          subscription_token: paymentMethod.metadata!.subscription_token,
        })
        .pipe(share());

      const createSubscriptionFailure$ = createSubscription$.pipe(
        filter(subscriptionResponseHasErrors),
        tap(this.processCreateSubscriptionFailed),
        mergeMapTo(NEVER),
      );

      const createSubscriptionSuccess$ = createSubscription$.pipe(
        filter(subscriptionResponseHasNoErrors),
        map((createSubscriptionResponse) => {
          const { status } = createSubscriptionResponse;

          this.validateSubscriptionCreateStatus(status);

          return createSubscriptionResponse;
        }),
      );

      const createSubscriptionSuccessWithoutIntent$ = createSubscriptionSuccess$.pipe(
        mergeMap(({ payment_intent, pending_setup_intent }) => {
          if (payment_intent || pending_setup_intent) {
            return NEVER;
          }

          return of({ paymentMethod });
        }),
      );

      const trackAuthorizationEvent = (
        eventType: string,
        intentType: IntentType,
        payload = {},
      ) => {
        const couponCode = getCouponCodeFromSubscriptionToken(
          subscriptionToken(paymentMethod),
        );
        const subscriptionProduct = subscriptionProductUid(
          productId(paymentMethod),
        );

        this.analyticsService.track(eventType, {
          provider_id: 'blendlepremium',
          subscription_product_uid: subscriptionProduct,
          payment_method: paymentMethod.type,
          payment_intent_type: intentType,
          ...(couponCode ? { coupon_code: couponCode } : null),
          ...payload,
        });
      };

      const handleIntentError = (error: Error) => {
        refreshSubscriptionToken();

        this.errorsService.addError(
          new Error(error.message),
          'error.subscription.strong_authentication_failed',
          Infinity,
        );

        this.loadingSubject$.next(false);

        return NEVER;
      };

      const successfulCreateSubscriptionWithPaymentIntent$ = createSubscriptionSuccess$.pipe(
        mergeMap(({ payment_intent }) => {
          if (!payment_intent) {
            return NEVER;
          }

          trackAuthorizationEvent(
            'Payment Method Authenticaton: Started',
            IntentType.PaymentIntent,
          );

          return handleIntent(
            payment_intent as Intent,
            paymentMethod,
            this.stripeService.handleCardPayment,
          );
        }),
        map((intentResponse) => {
          const { error } = intentResponse;

          if (error) {
            const { code } = error;

            trackAuthorizationEvent(
              'Payment Method Authenticaton: Failed',
              IntentType.PaymentIntent,
              { reason: code },
            );

            throw error;
          }

          trackAuthorizationEvent(
            'Payment Method Authenticaton: Success',
            IntentType.PaymentIntent,
          );

          return { paymentMethod };
        }),
        catchError(handleIntentError),
      );

      const successfulCreateSubscriptionWithPendingSetupIntent$ = createSubscriptionSuccess$.pipe(
        mergeMap(({ pending_setup_intent }) => {
          if (!pending_setup_intent) {
            return NEVER;
          }

          trackAuthorizationEvent(
            'Payment Method Authenticaton: Started',
            IntentType.PendingSetupIntent,
          );

          return handleIntent(
            pending_setup_intent as Intent,
            paymentMethod,
            this.stripeService.handleCardSetup,
          );
        }),
        map((intentResponse) => {
          const { error } = intentResponse;

          if (error) {
            const { code } = error;

            trackAuthorizationEvent(
              'Payment Method Authenticaton: Failed',
              IntentType.PendingSetupIntent,
              { reason: code },
            );

            return { paymentMethod, paymentAfterFreePeriodWillFail: true };
          }

          trackAuthorizationEvent(
            'Payment Method Authenticaton: Success',
            IntentType.PendingSetupIntent,
          );

          return { paymentMethod };
        }),
        catchError(handleIntentError),
      );

      return observableMerge<{
        paymentMethod:
          | PaymentMethodWithMetadata
          | ExistingPaymentMethodWithMetadata;
        paymentAfterFreePeriodWillFail?: boolean;
      }>(
        createSubscriptionFailure$,
        createSubscriptionSuccessWithoutIntent$,
        successfulCreateSubscriptionWithPaymentIntent$,
        successfulCreateSubscriptionWithPendingSetupIntent$,
      );
    };

    // Payment method flow, currently only used for creditcard payments
    paymentMethod$
      .pipe(switchMap(startSubscriptionWithPaymentMethod))
      .subscribe(({ paymentMethod, paymentAfterFreePeriodWillFail }) => {
        this.navigateToReturnPage(
          paymentMethod,
          paymentAfterFreePeriodWillFail,
        );
      });

    // Existing Payment method flow, currently only used for starting a subscription with existing creditcard
    existingPaymentMethod$
      .pipe(switchMap(startSubscriptionWithPaymentMethod))
      .subscribe(({ paymentMethod, paymentAfterFreePeriodWillFail }) => {
        this.navigateToReturnPage(
          paymentMethod,
          paymentAfterFreePeriodWillFail,
        );
      });

    // Existing source flow, used for starting a subscritpion with existing sepa debit source
    existingSource$
      .pipe(
        switchMap((existingSource) => {
          this.loadingSubject$.next(true);
          const createSubscription$ = this.subscriptionsService
            .createSubscription({
              source_id: existingSource.sourceId,
              subscription_token: existingSource.metadata!.subscription_token,
            })
            .pipe(share());

          const failedPayment$ = createSubscription$.pipe(
            filter(subscriptionResponseHasErrors),
            tap(this.processCreateSubscriptionFailed),
            mergeMapTo(NEVER),
          );

          const successfulCreateSubscription$ = createSubscription$.pipe(
            filter(subscriptionResponseHasNoErrors),
            tap(({ status }) => {
              this.validateSubscriptionCreateStatus(status);
            }),
            map(() => existingSource),
          );

          return observableMerge<ExistingSourceWithMetadata>(
            failedPayment$,
            successfulCreateSubscription$,
          );
        }),
      )
      .subscribe(this.navigateToReturnPage);

    freeOptInSource$
      .pipe(
        switchMap((freeOptInSource) => {
          this.loadingSubject$.next(true);
          const createSubscription$ = this.subscriptionsService
            .createSubscription({
              subscription_token: freeOptInSource.metadata!.subscription_token,
            })
            .pipe(share());

          const failedCreateSubscription$ = createSubscription$.pipe(
            filter(subscriptionResponseHasErrors),
            tap(this.processCreateSubscriptionFailed),
            mergeMapTo(NEVER),
          );

          const successfulCreateSubscription$ = createSubscription$.pipe(
            filter(subscriptionResponseHasNoErrors),
            tap(({ status }) => {
              this.validateSubscriptionCreateStatus(status);
            }),
            map(() => freeOptInSource),
          );

          return observableMerge<FreeOptInSourceWithMetadata>(
            failedCreateSubscription$,
            successfulCreateSubscription$,
          );
        }),
      )
      .subscribe(this.navigateToReturnPage);
  }

  private processCreateSubscriptionFailed = (
    data: CreateSubscriptionResponse,
  ) => {
    if (data._errors) {
      data._errors.forEach((error) => {
        this.errorsService.addError(
          new Error(error.message),
          `error.subscription.${error.code}`,
          Infinity,
        );
      });
      this.loadingSubject$.next(false);
    }
  };

  private validateSubscriptionCreateStatus = (
    status: void | SubscriptionCreateStatus,
  ) => {
    switch (status) {
      case SubscriptionCreateStatus.Unprocessable:
      case SubscriptionCreateStatus.Unknown:
        const error = new Error(`Subscription Status is '${status}'`);
        this.errorsService.addError(error, 'error.message.default', Infinity);
        throw error;
      default:
        return;
    }
  };

  private navigateToReturnPage = (
    data:
      | PaymentMethodWithMetadata
      | ExistingSourceWithMetadata
      | ExistingPaymentMethodWithMetadata
      | FreeOptInSourceWithMetadata,
    paymentAfterFreePeriodWillFail?: boolean,
  ) => {
    const { name } = this.router.routeSnapshot;
    const base = rootNode(name);
    const route = joinRouteParts(base, StartSubscriptionChildRouteName.Return);

    if (!this.router.router.isActive(route)) {
      this.router.router.navigate(route, {
        subscription_token: data.metadata!.subscription_token,
        ...(paymentAfterFreePeriodWillFail && {
          paymentAfterFreePeriodWillFail: true,
        }),
      });
    }
  };
}
