import { injectable } from 'inversify';
import { BehaviorSubject, of as observableOf } from 'rxjs';
import { tap } from 'rxjs/operators';
import { loadScript } from './loadScript';

@injectable()
export class ScriptService {
  static readonly scripts = Object.freeze({
    stripe: 'https://js.stripe.com/v3/',
  });

  readonly scripts$ = new BehaviorSubject(new Set<string>());
  private readonly loadedScripts = new Set<string>();

  requestScript(src: string) {
    if (this.loadedScripts.has(src)) {
      return observableOf(src);
    }

    return loadScript(src).pipe(
      tap((script: {}) => {
        this.loadedScripts.add(src);
        this.scripts$.next(this.loadedScripts);
      }),
    );
  }
}
