import { injectable } from 'inversify';
import { BehaviorSubject } from 'rxjs';

interface UrlCoupon {
  couponCode: string;
  autoApply: boolean;
}

@injectable()
export class CouponUrlService {
  // Observable to store the couponCode coming from the URL. This couponCode is not yet verfified,
  // The result of the verification is stored in the 'validatedCoupon$' Observable in the CouponService
  readonly couponCode$ = new BehaviorSubject<UrlCoupon | undefined>(undefined);

  setCouponCode = (urlCoupon: UrlCoupon) => {
    this.couponCode$.next(urlCoupon);
  };
}
