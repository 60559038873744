import { inject, injectable } from 'inversify';
import {
  pipe,
  prop,
  propOr,
  startsWith,
  ifElse,
  compose,
  identity,
} from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, share, switchMap, take } from 'rxjs/operators';
import config from 'environment-config';
import { filterEmpty } from 'utils/filter-empty';
import { LoginResponse, Preferences, User } from '../models/login';
import { EntryPoints } from './EntryPointsService';
import { Http } from './HttpService';

const CREDITS_CURRENCY = 'CREDIT';

type jwt = string;

function goToLoginPage() {
  window.location.replace(`${config.redirectDomain}/login`);
}

const getPreferences = prop<'preferences', Preferences>('preferences');

const getSignUpEntryPoint = pipe<User, Preferences, string>(
  getPreferences,
  propOr('unknown', 'signup_entry_point'),
);

const getSignUpDeeplinkItem = prop<'signup_deeplink_item', string>(
  'signup_deeplink_item',
);

const isPayWithBlendleItem = startsWith('pwb');

const hasSignUpDeeplinkItem = (itemId: string | null) => {
  if (!itemId) {
    return false;
  }

  return !isPayWithBlendleItem(itemId);
};

const filterPwbItem = ifElse(hasSignUpDeeplinkItem, identity, () => undefined);

const getDeeplinkItem = compose(filterPwbItem, getSignUpDeeplinkItem);

// TODO: Disable the time based check until we have an idea of how we can calculate a time delta
// function getPayloadFromJwt(jwt: jwt) {
//   const encodedPayload = jwt.split('.')[1];
//   return JSON.parse(window.atob(encodedPayload)) as { exp: number };
// }
// function isValidJwt(jwt: jwt) {
//   const payload = getPayloadFromJwt(jwt);
//   return payload.exp * 1000 > Date.now();
// }

@injectable()
export class UserService {
  set refreshToken(token: string) {
    if (token) {
      this._refreshToken = token;
      this.fetchUser(token);
    } else {
      goToLoginPage();
    }
  }
  readonly user$ = new BehaviorSubject<User | null>(null);
  readonly jwt$ = new BehaviorSubject<jwt | undefined>(undefined);
  readonly signupEntryPoint$ = this.user$.pipe(
    filterEmpty,
    first(),
    map(getSignUpEntryPoint),
  );
  readonly signupDeeplinkItemId$ = this.user$.pipe(
    filterEmpty,
    first(),
    map(getDeeplinkItem),
  );

  readonly creditsBalance$ = this.user$.pipe(
    filterEmpty,
    first(),
    map(({ balance, currency }) => {
      if (currency !== CREDITS_CURRENCY) {
        return undefined;
      }

      return parseInt(balance, 10);
    }),
  );

  private _refreshToken?: string;

  @inject(Http)
  private http: Http;
  @inject(EntryPoints)
  private entryPoints: EntryPoints;

  saveUser(user: User) {
    this.user$.next(user);
  }

  getValidJwt$(): Observable<jwt> {
    const filteredJwt$ = this.jwt$.pipe(filter((jwt) => !!jwt)) as Observable<
      jwt
    >;

    return filteredJwt$.pipe(
      // TODO: Disable the time based check until we have an idea of how we can calculate a time delta
      // .switchMap(jwt => {
      //   if (isValidJwt(jwt)) {
      //     return Observable.of(jwt);
      //   }

      //   // if the exp is reached, refresh token before request is done.
      //   return this.refreshJwt();
      // })
      take(1),
    );
  }

  refreshJwt() {
    if (!this._refreshToken) {
      throw new Error('RefreshToken is not set');
    }
    return this.fetchUser(this._refreshToken);
  }

  private fetchUser(token: string) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const user$ = this.entryPoints.getEntryByName('login').pipe(
      switchMap((uri) =>
        this.http.post<LoginResponse>(uri, {
          headers,
          body: JSON.stringify({
            refresh_token: token,
          }),
        }),
      ),
      map(({ data }) => data),
      share(),
    );

    user$.pipe(map((response) => response._embedded.user)).subscribe(
      (user) => this.saveUser(user),
      () => goToLoginPage(),
    );

    const jwt$ = user$.pipe(
      map((response) => response.jwt),
      share(),
    );

    jwt$.subscribe((jwt) => this.jwt$.next(jwt));

    return jwt$;
  }
}
