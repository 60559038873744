import { injectable } from 'inversify';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

@injectable()
export class URLSearchParamsService {
  readonly searchParams$ = new Observable(
    (observer: Observer<URLSearchParams>) => {
      // this will create new data
      const params = new URLSearchParams(window.location.search);
      observer.next(params);
      observer.complete();
    },
  ) as Observable<URLSearchParams>;

  getSearchParam(name: string): Observable<string | null> {
    return this.searchParams$.pipe(
      map((searchParams) => searchParams.get(name)),
    );
  }
}
