import { SubscriptionPlan } from '../SubscriptionPlans';
import { User } from 'shared/models';
import {
  ValidateCouponRequestPayload,
  ValidatedCoupon,
  CouponCodeStatus,
} from './types';
import { compose, propOr, equals, allPass, pathOr, pipe } from 'ramda';
import { idFromError, halError } from 'utils/errors';

export const hasValidCoupon = compose<
  ValidatedCoupon | undefined,
  string,
  boolean
>(equals(CouponCodeStatus.Valid), propOr(undefined, 'status'));

export const validatedCouponIsOptIn = pathOr(false, [
  'coupon',
  'metadata',
  'opt_in',
]);

export const validatedCouponIsPremiumPlusMicropaymentMigration = pathOr(false, [
  'coupon',
  'metadata',
  'premium_and_micropayment_migration',
]);

export const validatedCouponCanBeCancelledAtCurrentPeriodEnd = pathOr(false, [
  'coupon',
  'metadata',
  'can_be_cancelled_at_current_period_end',
]);

const percentOff = pathOr(undefined, ['coupon', 'discount', 'percent_off']);
const isHundredPercentOffCoupon = pipe(percentOff, equals(100));

export const hasValidFreeOptInCoupon = allPass([
  hasValidCoupon,
  validatedCouponIsOptIn,
  isHundredPercentOffCoupon,
]);

export const createPayload = (
  user: User,
  subscriptionPlan: SubscriptionPlan,
  couponCode: string,
): ValidateCouponRequestPayload => {
  const { full_name, country, email } = user;

  return {
    full_name,
    billing_country: country,
    email,
    subscription_plan: subscriptionPlan.id,
    coupon_code: couponCode,
  };
};

export enum CouponErrorId {
  CouponNotFound = 'CouponNotFound',
  CouponInvalid = 'CouponInvalid',
  CouponAlreadyRedeemed = 'CouponAlreadyRedeemed',
  IncompatiblePlan = 'IncompatiblePlan',
  CampaignInactive = 'CampaignInactive',
  InvalidStripeCoupon = 'InvalidStripeCoupon',
  Unauthorized = 'Unauthorized',
  UserNotFound = 'UserNotFound',
  InvalidDiscountApplied = 'InvalidDiscountApplied',
}

export const couponErrors = new Map<CouponErrorId, string>([
  [CouponErrorId.CouponNotFound, 'coupon.error.not-found'],
  [CouponErrorId.CouponInvalid, 'coupon.error.invalid'],
  [CouponErrorId.CouponAlreadyRedeemed, 'coupon.error.already-redeemed'],
  [CouponErrorId.IncompatiblePlan, 'coupon.error.invalid-plan'],
  [CouponErrorId.CampaignInactive, 'coupon.error.campaign-inactive'],
  [CouponErrorId.InvalidStripeCoupon, 'coupon.error.general'],
  [CouponErrorId.Unauthorized, 'coupon.error.general'],
  [CouponErrorId.UserNotFound, 'coupon.error.general'],
  [CouponErrorId.InvalidDiscountApplied, 'coupon.error.general'],
]);

export const getCouponErrorId = (errors: halError[] | Error) => {
  const error = Array.isArray(errors) ? errors[0] : errors;

  const errorId = idFromError(error);
  const couponErrorId = errorId as CouponErrorId;

  return couponErrors.has(couponErrorId) ? couponErrorId : undefined;
};
