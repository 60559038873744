import styled from 'styled-components/macro';
import { color } from '../../styled-helpers';
import { smallUp } from 'shared/styled-helpers';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 24px 19px;

  ${smallUp`
    margin: 0;
  `};
`;

export const Link = styled.a`
  color: ${color('gray.4')};
  text-decoration: none;
  transition: color 0.1s ease-out;
  font-size: 11px;
  line-height: 14px;

  ${smallUp`
    line-height: 18px;
  `};

  &:hover {
    color: ${color('boldBlakka')};
  }
`;

export const DotWrapper = styled.p`
  margin: 0 5px;
  color: ${color('gray.4')};
`;
