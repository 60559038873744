// http://json2ts.com/

// Models
export interface Self {
  href: string;
}

export interface Avatar {
  href: string;
}

export interface Posts {
  href: string;
}

export interface Reads {
  href: string;
}

export interface Followers {
  href: string;
}

export interface Follows {
  href: string;
}

export interface Private {
  href: string;
}

export interface PremiumSubscription {
  href: string;
}

export interface PaperSubscription {
  href: string;
}

export interface LargeAvatar {
  href: string;
}

export interface Links {
  self: Self;
  avatar: Avatar;
  posts: Posts;
  reads: Reads;
  followers: Followers;
  follows: Follows;
  private: Private;
  'premium-subscription': PremiumSubscription;
  'paper-subscription': PaperSubscription;
  large_avatar: LargeAvatar;
}

export interface Preferences {
  signup_entry_point?: string;
  last_bank?: string;
  did_onboarding?: string;
  last_payment_method?: string;
  use_vertical_reader?: string;
  did_premium_onboarding?: string;
  hide_tile_explain_kiosk?: string;
  use_premium_timeline_v2?: string;
  use_premium_timeline_v3?: string;
  allow_recurring_payments?: string;
  hide_acquire_issue_tooltip?: string;
  hide_tile_explain_following?: string;
  did_dismiss_social_card_dialog?: string;
  did_dismiss_premium_launch_dialog?: string;
  did_dismiss_premium_trial_ended_dialog?: string;
  did_dismiss_non_premium_timeline_dialog?: string;
  hide_tile_whotofollow?: string;
  hide_tile_like_us_on_facebook?: string;
  hide_tile_samsung_gift?: string;
  hide_tile_facebook_connect?: string;
  hide_tile_gmail_connect?: string;
  initial_purchase_message_faz_shown?: string;
  social_tile_views?: string;
  current_social_timeline_tile?: string;
  pwb_show_email_confirmation_dialog?: string;
  pwb_signup_item_uid?: string;
  hide_investment_dialog?: string;
  hide_channelbutton_new_label?: string;
  novamedia_marketing_opt_in?: string;
  did_brands_and_topics_walkthrough?: string;
  signup_coupon_code?: string;
  did_dismiss_migration_announcement_dialog_on_web?: string;
  did_dismiss_tiered_migration_announcement_dialog_on_web?: string;
  did_finish_premium_migration_tutorial?: string;
  did_dismiss_algorithmic_selection_opt_in_dialog?: string;
  did_dismiss_algorithmic_selection_started_dialog?: string;
  did_see_web_announcement_dialog_for_audio_on_web?: string;
}

export enum Capabilities {
  HasAlerts = 'has_alerts',
  HasSearch = 'has_search',
  HasNewsstand = 'has_newsstand',
  HasLegacyTimelines = 'has_legacy_timelines',
  HasNonStaffPicksSections = 'has_non_staff_picks_sections',
  HasPublisherSubscriptionConfiguration = 'has_publisher_subscription_configuration',
  HasContentPreferencesSelector = 'has_content_preferences_selector',
  HasMicropayments = 'has_micropayments',
  HasMicropaymentsTopUp = 'has_micropayments_top_up',
  HasMicropaymentAutoTopUp = 'has_micropayment_auto_top_up',
  HasAccessToBlendle = 'has_access_to_blendle',
  CanManagePremium = 'can_manage_premium',
  HasPremiumUpsellCallToAction = 'has_premium_upsell_call_to_action',
  HasBlendlePremium = 'has_blendle_premium',
  HasMicropaymentMigrationFeatures = 'has_micropayment_migration_features',
  HasExtraElementsExplainingPremiumMigration = 'has_extra_elements_explaining_premium_migration',
  HasPremiumCredits = 'has_premium_credits',
}

export interface Self2 {
  href: string;
}

export interface Avatar2 {
  href: string;
}

export interface Posts2 {
  href: string;
}

export interface Reads2 {
  href: string;
}

export interface Followers2 {
  href: string;
}

export interface Follows2 {
  href: string;
}

export interface Private2 {
  href: string;
}

export interface PremiumSubscription2 {
  href: string;
}

export interface PaperSubscription2 {
  href: string;
}

export interface LargeAvatar2 {
  href: string;
}

export interface Links2 {
  self: Self2;
  avatar: Avatar2;
  posts: Posts2;
  reads: Reads2;
  followers: Followers2;
  follows: Follows2;
  private: Private2;
  'premium-subscription': PremiumSubscription2;
  'paper-subscription': PaperSubscription2;
  large_avatar: LargeAvatar2;
}

export interface SharedUser {
  id: string;
  username: string;
  first_name: string;
  middle_name?: {};
  last_name?: {};
  full_name: string;
  short_name: string;
  _links: Links2;
  posts: number;
  followers: number;
  follows: number;
  text: string;
  shared_user_hidden: boolean;
  channel_color: string;
}

export interface Self3 {
  href: string;
}

export interface Links3 {
  self: Self3;
}

export interface AbTest {
  group: string;
  name: string;
  _links: Links3;
}

export interface Embedded2 {
  shared_users: SharedUser[];
  ab_tests: AbTest[];
}

export interface User {
  id: string;
  username: string;
  first_name: string;
  middle_name?: {};
  last_name: string;
  full_name: string;
  short_name: string;
  _links: Links;
  posts: number;
  followers: number;
  follows: number;
  trending_viewed_at: Date;
  facebook_id?: {};
  twitter_id?: {};
  twitter_username?: {};
  country: string;
  languages: string[];
  primary_language: string;
  time_zone: string;
  text: string;
  digest_opt_out: boolean;
  alerts_opt_out: boolean;
  followers_opt_out: boolean;
  new_edition_opt_out: boolean;
  read_later_opt_out: boolean;
  tips_opt_out: boolean;
  friend_signup_opt_out: boolean;
  weekly_digest_opt_out: boolean;
  magazine_digest_opt_out: boolean;
  marketing_opt_out: boolean;
  announcements_opt_out: boolean;
  survey_opt_out: boolean;
  trial_upsell_opt_out: boolean;
  ad_retargeting_opt_out: boolean;
  publisher_hashed_email_share_opt_out: boolean;
  mixpanel_opt_out: boolean;
  master_opt_out: boolean;
  preferences: Preferences;
  email: string;
  reads: number;
  has_password: boolean;
  pins: number;
  orders: number;
  freeloader: boolean;
  verified: boolean;
  email_confirmed: boolean;
  currency: string;
  balance: string;
  automatic_topup_on_low_balance: boolean;
  providers_opt_in: boolean;
  _embedded: Embedded2;
  tracking_uid: string;
  uuid: string;
  active_subscriptions: string[];
  signup_deeplink_item: string;
  capabilities: Capabilities[];
}

export interface Embedded {
  user: User;
}

export interface LoginResponse {
  _embedded: Embedded;
  jwt: string;
}
