import { lifecycle } from '@blendle/recompose';
import { loadFacebookPixel } from './loadFacebookPixel';
import { hasUserGivenCookieConsent } from 'utils/cookieConsent';

export const withTrackingPixels = () =>
  lifecycle({
    componentDidMount() {
      if (hasUserGivenCookieConsent()) {
        loadFacebookPixel();
      }
    },
  });
