import { container } from 'dependency-injection';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { componentFromStream } from '@blendle/recompose';
import {
  combineLatest as observableCombineLatest,
  timer as observableTimer,
} from 'rxjs';
import { map, mapTo, startWith, switchMap } from 'rxjs/operators';
import { TranslationService } from '../../services';

// The order import is important, first import the polyfill itself, then the locale data\
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/nl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/nl';

interface TranslationProviderProps {
  children: React.ReactNode;
  locale: 'nl-NL'; // when changing this, it's also needed to load locale-data dynamically
}

export const TranslationProvider = componentFromStream<
  TranslationProviderProps
>((props$) => {
  const translationService = container.get(TranslationService);

  const translations$ = props$.pipe(
    switchMap(({ locale }) => translationService.getTranslationFile(locale)),
    startWith(null),
  );

  const timeOut$ = observableTimer(15000).pipe(
    mapTo(
      <div>
        <p>
          This takes much longer than expected. Please check your network
          connection.
        </p>
        <p>
          We are not able to fetch the translations. This could have several
          reasons. Mostly it's a weak internet connection.
        </p>
      </div>,
    ),
    startWith(null),
  );

  const formats = {
    date: {
      medium_without_year: {
        day: 'numeric',
        month: 'long',
      },
    },
  };

  return observableCombineLatest([props$, translations$, timeOut$]).pipe(
    map(([{ children, locale }, translations, timeOut]) => {
      if (translations) {
        return (
          <IntlProvider
            locale={locale}
            messages={translations}
            formats={formats}
          >
            {children}
          </IntlProvider>
        );
      }

      if (timeOut) {
        return timeOut;
      }

      return null;
    }),
  );
});

TranslationProvider.displayName = 'TranslationProvider';
