import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BaseNotification } from '../index';
import { HighFiveIcon } from '../../Icons/HighFiveIcon';

export const ChangeSubscriptionPlanSuccessNotification = () => (
  <BaseNotification
    title={
      <FormattedMessage id="notification.change-subscription-plan.title" />
    }
    body={<FormattedMessage id="notification.change-subscription-plan.body" />}
    icon={<HighFiveIcon />}
  />
);
