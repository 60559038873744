export function sanitizeStripeCouponMetadata<T>(data: T): T {
  return Object.keys(data).reduce((result, key) => {
    if (data[key] === 'true') {
      result[key] = true;
    } else if (data[key] === 'false') {
      result[key] = false;
    } else {
      result[key] = data[key];
    }

    return result;
  }, {} as T);
}
