import { injectable } from 'inversify';
import {
  BehaviorSubject,
  merge as observableMerge,
  NEVER,
  Observable,
  of as observableOf,
  Subject,
  timer as observableTimer,
} from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';

export interface Notification {
  component: (props: { endDate?: Date }) => JSX.Element;
  props: {};
  timeout: number;
  id: string;
}

@injectable()
export class NotificationService {
  readonly notification$ = new BehaviorSubject<Notification | undefined>(
    undefined,
  );

  private readonly incomingNotification$ = new Subject<Notification>();

  constructor() {
    this.setupIncomingNotification();
  }

  addNotification = ({
    component,
    props = {},
    timeout = 5000,
    id,
  }: {
    component: Notification['component'];
    props?: Notification['props'];
    timeout?: Notification['timeout'];
    id: Notification['id'];
  }) => {
    this.incomingNotification$.next({
      component,
      timeout,
      props,
      id,
    });
  };

  setupIncomingNotification = () => {
    this.incomingNotification$
      .pipe(
        switchMap((notifcation) =>
          observableMerge(
            observableTimer(notifcation.timeout).pipe(
              mapTo(undefined),
            ) as Observable<undefined>,
            notifcation.timeout === 0 ? NEVER : observableOf(notifcation),
          ),
        ),
      )
      .subscribe(this.notification$);
  };
}
