import { SourceService } from 'shared/services/SourceService';
import { Metadata } from 'shared/services/SourceService/types';

export interface CreateExistingSourceResponse {
  source: {
    sourceId: string;
    type: stripe.SourceOptions['type'];
    metadata: Metadata;
  };
  error?: Error | undefined;
}

export function createExistingSource(
  sourceId: string,
  {
    type,
    metadata,
  }: {
    type: stripe.SourceOptions['type'];
    metadata: Metadata;
  },
  nextExistingSource: typeof SourceService.prototype.nextExistingSource,
): Promise<CreateExistingSourceResponse> {
  return new Promise((resolve) => {
    const source = { sourceId, type, metadata };

    nextExistingSource(source);
    resolve({ source });
  });
}
