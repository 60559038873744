import { Preferences } from 'shared/models/login';
import { prop, compose, equals, not } from 'ramda';

function stringToBool(str: string) {
  if (str === 'true') {
    return true;
  }
  if (str === 'false') {
    return false;
  }
  return str;
}

export function convertBooleanStringsToBooleans(preferences: Preferences) {
  for (const key in preferences) {
    if (preferences.hasOwnProperty(key)) {
      preferences[key] = stringToBool(preferences[key]);
    }
  }

  return preferences;
}

export const savingPreferencesFailed = compose(
  not,
  equals(200),
  prop('status'),
);
