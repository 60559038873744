import { injectable } from 'inversify';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ErrorsService } from '../ErrorsService';

@injectable()
export class BuyCreditsFormService {
  readonly buttonLoading$ = new BehaviorSubject(false);

  submit$: Observable<React.FormEvent<HTMLFormElement>>;

  private readonly submitSubject$ = new Subject<
    React.FormEvent<HTMLFormElement>
  >();

  constructor(private readonly errorsService: ErrorsService) {
    this.submit$ = this.submitSubject$.asObservable();
  }

  readonly handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.errorsService.removeCurrentError();
    this.submitSubject$.next(event);
  };
}
