type subscriptionToken = string;

export enum SubscriptionStreamResponse {
  Success = 'Success',
  CouponInValidError = 'CouponInValidError',
  GeneralError = 'GeneralError',
}

export enum DiscountDuration {
  Once = 'once',
  Repeating = 'repeating',
  Forever = 'forever',
}

export interface Discount {
  duration: DiscountDuration;
  duration_in_months?: number;
  percent_off?: number;
  amount_off?: number;
}

export interface Metadata {
  company: string;
  campaign: string;
  subscription_product_uid: string;
  opt_in?: boolean;
  can_be_cancelled_at_current_period_end?: boolean;
}

export interface Coupon {
  discount: Discount;
  metadata: Metadata;
}
export interface SubscriptionTokenResponse {
  subscription_token: subscriptionToken;
  coupon?: Coupon;
  _errors?: {}[];
}

export interface SubscriptionTokenRequestPayload {
  full_name: string;
  billing_country: string;
  email: string;
  subscription_plan: string;
  coupon_code?: string;
}
