import { injectable } from 'inversify';
import {
  adjust,
  fromPairs,
  join,
  map,
  pipe,
  replace,
  toPairs,
  pick,
  omit,
} from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';
import { map as observableMap } from 'rxjs/operators';
import { filterEmpty } from 'utils/filter-empty';

export interface UtmData {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  adid?: string;
}

export interface UtmDataAsAnalyticsPayload {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
  adid?: string;
}

export type UtmDataAsSegmentAnalyticsPayload = Omit<
  UtmDataAsAnalyticsPayload,
  'adid'
>;

export interface AdIdAnalyticsPayload {
  ad_id?: string;
}

const supportedTags = Object.freeze([
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'adid',
]);

const removeUtmPrefix = replace('utm_', '');
const renameCampaignKey = replace('campaign', 'name');
const renameAdIdKey = replace('adid', 'ad_id');

const transformUtmAsAnalyticsPayload = pipe<
  UtmData,
  string[][],
  string[][],
  UtmDataAsAnalyticsPayload
>(toPairs, map(adjust(0, removeUtmPrefix)), fromPairs);

const transformUtmAsSegmentAnalyticsPayload = pipe<
  UtmData,
  string[][],
  string[][],
  string[][],
  UtmDataAsAnalyticsPayload,
  UtmDataAsSegmentAnalyticsPayload
>(
  toPairs,
  map(adjust(0, removeUtmPrefix)),
  map(adjust(0, renameCampaignKey)),
  fromPairs,
  omit(['adid']),
);

const transformUtmAsSegmentAdId = pipe<
  UtmData,
  UtmData,
  string[][],
  string[][],
  AdIdAnalyticsPayload
>(pick(['adid']), toPairs, map(adjust(0, renameAdIdKey)), fromPairs);

const transformUtmAsUrlParamsString = pipe<
  UtmData,
  [string, string][],
  string[],
  string
>(toPairs, map(join('=')), join('&'));

@injectable()
export class UtmService {
  public utmDataSnapshot: UtmData = {};
  public utmData$: Observable<UtmData>;
  public utmAsAnalyticsPayload$: Observable<UtmDataAsAnalyticsPayload>;

  private utmDataSubject$ = new BehaviorSubject<UtmData | null>(null);

  constructor() {
    this.utmData$ = filterEmpty(this.utmDataSubject$);
    this.utmAsAnalyticsPayload$ = observableMap(transformUtmAsAnalyticsPayload)(
      this.utmData$,
    );
  }

  storeUtmData(paramsMap: URLSearchParams | Map<string, string>) {
    supportedTags.forEach((tag) => {
      if (paramsMap.has(tag)) {
        this.utmDataSnapshot[tag] = paramsMap.get(tag);
      }
    });
    this.utmDataSubject$.next(this.utmDataSnapshot);
  }

  readonly getUtmAsUrlParamsString = (): string =>
    transformUtmAsUrlParamsString(this.utmDataSnapshot);

  getUtmAsAnalyticsPayload(): UtmDataAsAnalyticsPayload {
    return transformUtmAsAnalyticsPayload(this.utmDataSnapshot);
  }

  getUtmAsSegmentAnalyticsPayload(): UtmDataAsSegmentAnalyticsPayload {
    return transformUtmAsSegmentAnalyticsPayload(this.utmDataSnapshot);
  }

  getUtmAsAdIdPayload(): AdIdAnalyticsPayload {
    return transformUtmAsSegmentAdId(this.utmDataSnapshot);
  }
}
