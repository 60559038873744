import { pathOr, prop, equals, compose } from 'ramda';

type SubscriptionPlan = import('./types').SubscriptionPlan;

export enum Interval {
  Month = 'month',
  Year = 'year',
  YearPaidPerMonth = 'year_paid_per_month',
}

export const displayId = prop('display_id');

export const currency: (plan: SubscriptionPlan) => string = prop('currency');

export const subscriptionProductUid = (productId: string) => {
  switch (productId) {
    case 'blendlepremium_monthly':
    case 'blendlepremium_monthly_reactivation':
      return 'blendlepremium_monthly';
    case 'blendlepremium_6weeks_trial':
      return 'blendlepremium_globe_six_week_trial';
    case 'blendlepremium_yearly':
      return 'blendlepremium_yearly';
    case 'blendlepremium_migration_yearly_paid_per_month':
      return 'blendlepremium_migration_yearly_paid_per_month';
    default:
      throw new Error(
        `Can't map id: ${productId} to subscription_product_uid.`,
      );
  }
};

type getPaymentAmountType = (
  plan: SubscriptionPlan,
  paymentMethod: import('../PaymentMethodsService').PaymentMethod,
) => number;

export const getPaymentAmount: getPaymentAmountType = (plan, paymentMethod) =>
  pathOr(plan.amount, ['authorization_payment', currency(plan)], paymentMethod);

export const metadata = (
  subscriptionToken: string,
  plan: SubscriptionPlan,
) => ({
  subscription_token: subscriptionToken,
  product_id: displayId(plan),
});

export const isYearPaidPerMonthPlan = compose(
  equals(Interval.YearPaidPerMonth),
  prop('interval'),
);
