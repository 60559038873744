import { compose, prop, curry, length, path, equals, has, propOr } from 'ramda';
import { Hal } from '../hal';
import {
  PaymentMethod,
  StripePaymentFlows,
  PaymentMethodExistingSepaDebitOrCard,
  ExistingSource,
  PaymentMethodResponse,
  PaymentMethodFreeOptIn,
  PaymentMethodName,
  PaymentMethodSepaDebit,
  ExistingPaymentMethod,
} from './types';

export const paymentFlowsByType = new Map<string, StripePaymentFlows>([
  [PaymentMethodName.Card, StripePaymentFlows.PaymentMethod],
  [PaymentMethodName.IDeal, StripePaymentFlows.Source],
  [PaymentMethodName.ExistingMethod, StripePaymentFlows.ExistingMethod],
  [PaymentMethodName.FreeOptIn, StripePaymentFlows.FreeOptIn],
  [PaymentMethodName.SepaDebit, StripePaymentFlows.SepaDebitSource],
]);

export const paymentType: (method: PaymentMethod) => string = prop('type');

type getPaymentFlowByMethodType = (
  method: PaymentMethod,
) => StripePaymentFlows | undefined;

export const getPaymentFlowByMethod: getPaymentFlowByMethodType = compose(
  getPaymentFlowByType,
  paymentType,
);

function getPaymentFlowByType(type: string) {
  return paymentFlowsByType.get(type);
}

const containsMethod = curry(
  (
    lookFor: string | undefined,
    methods: ReadonlyArray<PaymentMethod | undefined>,
  ): PaymentMethod | undefined =>
    methods.find((method) => method && method.type === lookFor),
);

export const getIdealMethod = containsMethod(PaymentMethodName.IDeal);
export const getCardMethod = containsMethod(PaymentMethodName.Card);
export const getSepaMethod = containsMethod(PaymentMethodName.ExistingMethod);
export const getActiveMethod = containsMethod;

export const isSepaMethodActive = compose(
  equals(PaymentMethodName.ExistingMethod),
  prop('type'),
);

const sourcesFromData = path<ReadonlyArray<ExistingSource>>([
  '_embedded',
  'sources',
]);

const existingPaymentMethodsFromData = path<
  ReadonlyArray<ExistingPaymentMethod>
>(['_embedded', 'existing_payment_methods']);

const paymentMethodsFromData = path<ReadonlyArray<PaymentMethod>>([
  '_embedded',
  'payment_methods',
]);

export const createPaymentMethodExistingSepaDebitOrCard = ({
  existingSources = [],
  existingPaymentMethods = [],
}: {
  existingSources?: ReadonlyArray<ExistingSource>;
  existingPaymentMethods?: ReadonlyArray<ExistingPaymentMethod>;
}): PaymentMethodExistingSepaDebitOrCard | undefined => {
  const existingMethods = [...existingSources, ...existingPaymentMethods];

  return length(existingMethods) > 0
    ? {
        name: 'Existing Method',
        type: PaymentMethodName.ExistingMethod,
        existingMethods,
      }
    : undefined;
};

const freeOptInMethod: PaymentMethodFreeOptIn = {
  name: 'Free Opt-in',
  type: PaymentMethodName.FreeOptIn,
};

const sepaDebit: PaymentMethodSepaDebit = {
  name: 'Sepa Debit',
  type: PaymentMethodName.SepaDebit,
};

export const createPaymentMethods = (
  data: Hal<PaymentMethodResponse>,
): ReadonlyArray<PaymentMethod> => {
  const paymentMethodExistingSepaDebitOrCard = createPaymentMethodExistingSepaDebitOrCard(
    {
      existingSources: sourcesFromData(data),
      existingPaymentMethods: existingPaymentMethodsFromData(data),
    },
  );
  const paymentMethods = paymentMethodsFromData(data)!;

  return paymentMethodExistingSepaDebitOrCard
    ? [
        ...paymentMethods,
        paymentMethodExistingSepaDebitOrCard,
        freeOptInMethod,
        sepaDebit,
      ]
    : [...paymentMethods, freeOptInMethod, sepaDebit];
};

const paymentMethodId = propOr(undefined, 'payment_method_id');
const sourceId = propOr(undefined, 'source_id');

const isPaymentMethod = has('payment_method_id');
const isSource = has('source_id');

export const getSourceIdOrPaymentMethodId = (
  existingMethod?: ExistingSource | ExistingPaymentMethod,
): string | undefined => {
  if (!existingMethod) {
    return;
  }

  if (isPaymentMethod(existingMethod)) {
    return paymentMethodId(existingMethod);
  }

  if (isSource(existingMethod)) {
    return sourceId(existingMethod);
  }
};

export const getIdValueForAnalyticsPayload = (
  existingMethod: ExistingSource | ExistingPaymentMethod,
): { payment_method_id: string } | { source_id: string } | undefined => {
  if (isPaymentMethod(existingMethod)) {
    return {
      payment_method_id: paymentMethodId(existingMethod),
    };
  }

  if (isSource(existingMethod)) {
    return {
      source_id: sourceId(existingMethod),
    };
  }

  throw new Error('Cannot find source or payment method id for event payload');
};
