export const theme = {
  size: {
    maxWidth: '1024px',
    font: {
      smaller: '11px',
      small: '12px',
      smallish: '13px',
      normal: '14px',
      large: '16px',
      big: '18px',
      bigger: '20px',
      hugeish: '24px',
      huge: '26px',
      quiteHuge: '44px',
      extraHuge: '60px',
    },
  },
  color: {
    razzmatazz: '#FF1060',
    hotCoral: '#FF514C',
    boldBlakka: '#333',
    cappuccino: '#948784',
    white: '#fff',
    green: '#3CCB71',
    darkGreen: '#00AD5A',
    error: '#ff2f41',
    gray: [
      '#f2f2f2',
      '#e5e5e5',
      '#ccc',
      '#b2b2b2',
      '#999',
      '#7f7f7f',
      '#70706f',
      '#5b5b5b',
      '#474746',
      '#3d3d3c',
      '#999999',
      '#707070',
      '#eaeaea',
      '#3d3d3d',
    ],
  },
};
