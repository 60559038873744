import { postConstruct, injectable } from 'inversify';
import ReactGA from 'react-ga';
import { map } from 'rxjs/operators';
import { UserService } from '../UserService';
import { UtmDataAsAnalyticsPayload, UtmService } from '../UtmService';
import config from 'environment-config';
import { isEmpty } from 'ramda';
import { hasUserGivenCookieConsent } from 'utils/cookieConsent';

const googleAnalyticsCode = config.gaTrackingId;

function mapToGoogleUtms(utmTags: UtmDataAsAnalyticsPayload) {
  const googleUtms = {
    ...(utmTags.campaign ? { campaignName: utmTags.campaign } : {}),
    ...(utmTags.source ? { campaignSource: utmTags.source } : {}),
    ...(utmTags.medium ? { campaignMedium: utmTags.medium } : {}),
    ...(utmTags.content ? { campaignContent: utmTags.content } : {}),
  };

  return isEmpty(googleUtms) ? undefined : googleUtms;
}

@injectable()
export class GoogleAnalytics {
  constructor(
    private readonly utm: UtmService,
    private readonly user: UserService,
  ) {}

  @postConstruct()
  __initializeGoogleAnalytics() {
    ReactGA.initialize(googleAnalyticsCode, {
      titleCase: false,
      gaOptions: {
        /* Cross domain setup */
        allowLinker: true,
      },
    });

    ReactGA.set({ anonymizeIp: true });

    if (hasUserGivenCookieConsent()) {
      ReactGA.set({ allowAdFeatures: true });
    }

    /* Cross domain setup */
    ReactGA.plugin.require('linker');
    ReactGA.ga('linker:autoLink', ['blendle.com', 'merol.bledle.com']);

    this.utm.utmAsAnalyticsPayload$
      .pipe(map(mapToGoogleUtms))
      .subscribe((utmData) => {
        if (utmData) {
          ReactGA.set(utmData);
        }
      });
  }

  trackPaymentRequest() {
    this.trackOnboardingEventWithSignupType('payment request');
  }

  trackWalletMigration() {
    ReactGA.event({
      category: 'migration',
      action: 'Migrated wallet to Premium',
    });
  }

  trackOnboardingCompleted() {
    this.trackOnboardingEventWithSignupType('onboarding completed');
  }

  private trackOnboardingEventWithSignupType(action: string) {
    this.user.signupEntryPoint$.subscribe((signUpType) => {
      ReactGA.event({
        category: 'onboarding',
        action,
        label: signUpType || 'unknown',
      });
    });
  }
}
