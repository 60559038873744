import { container } from 'dependency-injection';
import { path, prop, propOr } from 'ramda';
import { ReactStripeElements } from 'react-stripe-elements';
import { NEVER, Observable } from 'rxjs';
import {
  filter,
  map,
  startWith,
  switchMap,
  switchMapTo,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { createSource } from 'utils/stripe/create-source';
import { ErrorLoggerService } from '../ErrorLoggerService';
import { FormService } from '../FormService';
import {
  Bank,
  getPaymentFlowByMethod,
  PaymentMethod,
  paymentType,
  StripePaymentFlows,
  ExistingSource,
  ExistingPaymentMethod,
} from '../PaymentMethodsService';
import { Router } from '../Router';
import { SourceService } from '../SourceService';
import {
  currency,
  getPaymentAmount,
  metadata,
  SubscriptionPlan,
  displayId,
} from '../SubscriptionPlans';
import { SubscriptionTokenService } from '../SubscriptionToken/service';
import { UtmService } from '../UtmService';
import {
  createExistingSource,
  CreateExistingSourceResponse,
} from 'utils/stripe/create-existingSource';
import {
  createExistingPaymentMethod,
  CreateExistingPaymentMethodResponse,
} from 'utils/stripe/create-existingPaymentMethod';
import { CouponService, hasValidFreeOptInCoupon } from '../CouponService';
import { getReturnPath, getRedirectUrl } from 'utils/redirectUrl';
import {
  createFreeOptInSource,
  CreateFreeOptInSourceResponse,
} from 'utils/stripe/create-freeOptInSource';
import { createPaymentMethod } from 'utils/stripe/create-paymentMethod';
import { createSepaDebitSource } from 'utils/stripe/create-sepaDebitSource';
import { SepaDebitPaymentDetailsService } from '../SepaDebitPaymentDetailsService';
import { StartSubscriptionChildRouteName } from 'routes';

const stripeResponseErrorHandler = (
  setButtonLoading: typeof FormService.prototype.setButtonLoading,
  displayExceptionAndLogToSentry: typeof ErrorLoggerService.prototype.displayExceptionAndLogToSentry,
  data:
    | stripe.SourceResponse
    | stripe.TokenResponse
    | stripe.PaymentMethodResponse
    | CreateExistingSourceResponse
    | CreateExistingPaymentMethodResponse
    | CreateFreeOptInSourceResponse,
) => {
  if (data.error) {
    setButtonLoading(false);
    const error = new Error(`Stripe Error ${data.error.message}`);
    const source = propOr({}, 'source')(data);
    displayExceptionAndLogToSentry(error, {
      ...data.error,
      ...data,
      'ideal-bank': path(['ideal', 'bank'], source),
    });
  }
  return null;
};

interface ExpectedProps {
  plan: SubscriptionPlan;
  stripe?: ReactStripeElements.StripeProps;
}

export function formSubmitSink(
  props$: Observable<ExpectedProps>,
  selectedPaymentMethod$: Observable<PaymentMethod>,
  selectedBank$: Observable<Bank>,
  selectedExistingSepaDebitOrCard$: Observable<
    ExistingSource | ExistingPaymentMethod
  >,
) {
  const formService = container.get(FormService);
  const sourceService = container.get(SourceService);
  const { getUtmAsUrlParamsString } = container.get(UtmService);
  const { displayExceptionAndLogToSentry } = container.get(ErrorLoggerService);
  const { subscriptionToken$ } = container.get(SubscriptionTokenService);
  const { validatedCoupon$ } = container.get(CouponService);
  const routerService = container.get(Router);
  const { fullName$ } = container.get(SepaDebitPaymentDetailsService);

  return formService.submit$.pipe(
    throttleTime(150),
    tap(() => {
      formService.buttonLoadingSubject$.next(true);
      // start with new paymentMethod/sources to prevent existing state doing side requests
      sourceService.paymentMethod$.next(null);
      sourceService.source$.next(null);
      sourceService.existingSource$.next(null);
      sourceService.existingPaymentMethod$.next(null);
      sourceService.freeOptInSource$.next(null);
    }),
    withLatestFrom(
      props$,
      selectedBank$.pipe(startWith<string | undefined>(undefined)),
      selectedExistingSepaDebitOrCard$.pipe(
        startWith<ExistingSource | ExistingPaymentMethod | undefined>(
          undefined,
        ),
      ),
      subscriptionToken$,
      formService.buttonLoading$,
      selectedPaymentMethod$,
      validatedCoupon$,
      fullName$,
    ),
    map(
      ([
        _,
        props,
        selectedBank,
        existingSepaDebitOrCard,
        subscriptionToken,
        buttonLoading,
        selectedPaymentMethod,
        validatedCoupon,
        fullName,
      ]) => ({
        ...props,
        selectedBank,
        existingSepaDebitOrCard,
        subscriptionToken,
        buttonLoading,
        selectedPaymentMethod,
        validatedCoupon,
        fullName,
      }),
    ),
    filter(prop('buttonLoading')),
    switchMap(
      async ({
        stripe,
        plan,
        selectedBank,
        existingSepaDebitOrCard,
        subscriptionToken,
        selectedPaymentMethod,
        validatedCoupon,
        fullName,
      }) => {
        const stripeFlow = getPaymentFlowByMethod(selectedPaymentMethod);

        const returnPath = getReturnPath({
          currentRoute: routerService.routeSnapshot.name,
          returnChildRoute: StartSubscriptionChildRouteName.Return,
          buildPath: routerService.router.buildPath,
        });

        const data = {
          type: paymentType(selectedPaymentMethod),
          amount: getPaymentAmount(plan, selectedPaymentMethod),
          bank: selectedBank,
          currency: currency(plan),
          metadata: metadata(subscriptionToken, plan),
          redirectUrl: getRedirectUrl({
            utmAsUrlParams: getUtmAsUrlParamsString(),
            subscriptionToken,
            returnPath,
            validatedCoupon,
            productId: displayId(plan),
          }),
          fullName,
        };

        switch (stripeFlow) {
          case StripePaymentFlows.PaymentMethod:
            return createPaymentMethod(
              stripe!.createPaymentMethod,
              data.metadata,
              sourceService.nextPaymentMethod,
            );
          case StripePaymentFlows.Source: {
            if (!data.bank) {
              throw new Error('A bank is required in the source flow');
            }

            return createSource(
              stripe!.createSource,
              data,
              sourceService.nextSource,
            );
          }

          case StripePaymentFlows.SepaDebitSource: {
            if (!fullName) {
              throw new Error(
                'An account owners full name is required in the sepa debit source flow',
              );
            }

            return createSepaDebitSource(
              stripe!.createSource,
              data,
              sourceService.nextSource,
            );
          }

          case StripePaymentFlows.ExistingMethod: {
            if (existingSepaDebitOrCard.source_id) {
              return createExistingSource(
                existingSepaDebitOrCard.source_id,
                data,
                sourceService.nextExistingSource,
              );
            }

            if (existingSepaDebitOrCard.payment_method_id) {
              return createExistingPaymentMethod(
                existingSepaDebitOrCard.payment_method_id,
                data,
                sourceService.nextExistingPaymentMethod,
              );
            }

            throw new Error(
              'An existing sourceId or paymentMethodId is required in the Existing Method flow',
            );
          }

          case StripePaymentFlows.FreeOptIn: {
            if (!hasValidFreeOptInCoupon(validatedCoupon)) {
              throw new Error(
                'A valid free opt-in coupon is required in the opt-in flow',
              );
            }

            return createFreeOptInSource(
              data,
              sourceService.nextFreeOptInSource,
            );
          }

          default:
            throw new Error('Unsupported payment method');
        }
      },
    ),
    map((response) =>
      stripeResponseErrorHandler(
        formService.setButtonLoading,
        displayExceptionAndLogToSentry,
        response,
      ),
    ),
    switchMapTo(NEVER),
  );
}
