import React from 'react';

export const SupportEmailLink: React.FC<React.AnchorHTMLAttributes<
  HTMLAnchorElement
>> = props => {
  return (
    <a href="mailto:support@blendle.com" {...props}>
      support@blendle.com
    </a>
  );
};
