import { container } from 'dependency-injection';
import { componentFromStream } from '@blendle/recompose';
import { combineLatest as observableCombineLatest, NEVER } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorsService } from '../../services/ErrorsService/index';
import { ScriptService } from '../../services/ScriptService';

export const EnsureStripeJsLoaded = componentFromStream<{
  children?: React.ReactChildren;
}>((props$) => {
  const scriptService = container.get(ScriptService);
  const errorService = container.get(ErrorsService);

  const stripe$ = scriptService
    .requestScript(ScriptService.scripts.stripe)
    .pipe(
      catchError((e) => {
        errorService.addError(e, 'error.message.3rd-party-scripts', Infinity);
        return NEVER;
      }),
    );

  return observableCombineLatest([props$, stripe$]).pipe(
    map(([{ children }, stripeLoaded]) => {
      return stripeLoaded ? children : null;
    }),
  );
});
