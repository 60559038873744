import { injectable } from 'inversify';
import { BehaviorSubject, NEVER, combineLatest } from 'rxjs';
import {
  tap,
  map,
  mergeMap,
  catchError,
  take,
  filter,
  switchMap,
} from 'rxjs/operators';
import { SubscriptionOverviewService } from '../SubscriptionOverviewService';
import { UserService } from '../UserService';
import { RequestService } from '../RequestService';
import { ErrorsService } from '../ErrorsService';
import { AnalyticsService } from '../AnalyticsService';
import {
  determineWhenSavedeskIsOpen,
  WhenIsSavedeskOpen,
} from 'shared/scenes/SubscriptionOverviewScene/Cancellation/helpers';
import { isSavedeskEnabled } from 'features';
import { filterEmpty } from 'utils';
import {
  pathOr,
  isEmpty,
  pipe,
  propEq,
  not,
  propOr,
  filter as ramdaFilter,
} from 'ramda';
import { Subscription } from '../SubscriptionOverviewService/types';
import { Router } from '../Router';
import { RouteName } from 'routes';
import config from 'environment-config';
import { CANCELLATION_REASON } from './reasons';

const endDateWhenCancelled: (sub: Subscription) => Date | undefined = propOr(
  undefined,
  'end_date_when_cancelled',
);

export enum CancellationStep {
  Savedesk,
  CancellationReason,
  ArgumentToStayBasedOnCancellationReason,
  ArgumentToStayBasedOnUser,
  Confirmation,
}

export enum USER_BASED_ARGUMENT {
  CREDITS_LEFT = 'credits_left',
  PINNED_ARTICLES = 'pinned_articles',
  BOUGHT_ARTICLES = 'bought_articles',
  READ_ARTICLES = 'read_articles',
}

const thresholdForShowingPinsArgument = 2;

const transactions = pathOr([], ['_embedded', 'transactions']);
const isPurchase = propEq('type', 'purchase');
const hasPurchases = pipe(ramdaFilter(isPurchase), isEmpty, not);

@injectable()
export class CancelSubscriptionService {
  readonly cancellationStep$ = new BehaviorSubject<CancellationStep>(
    CancellationStep.Savedesk,
  );

  readonly whenIsSavedeskOpen = determineWhenSavedeskIsOpen(new Date());
  readonly isPhoneAvailable =
    this.whenIsSavedeskOpen === WhenIsSavedeskOpen.Now && isSavedeskEnabled();

  readonly isCancellationDialogOpen$ = new BehaviorSubject<boolean>(false);
  readonly isButtonLoading$ = new BehaviorSubject<boolean>(false);
  readonly cancellationReason$ = new BehaviorSubject<
    CANCELLATION_REASON | undefined
  >(undefined);
  readonly isButtonEnabled$ = this.cancellationReason$.pipe(
    map((reason) => Boolean(reason)),
  );

  readonly hasPurchasedArticles$ = this.fetchTransactions$().pipe(
    map((transactions) => hasPurchases(transactions)),
  );

  readonly argumentTooStayBasedOnUser$ = this.determineArgumentBasedOnUser$();

  constructor(
    private readonly subscriptionOverviewService: SubscriptionOverviewService,
    private readonly userService: UserService,
    private readonly requestService: RequestService,
    private readonly errorsService: ErrorsService,
    private readonly analyticsService: AnalyticsService,
    private readonly routerService: Router,
  ) {}

  trackEvent = (eventName: string, payload?: {}) => {
    combineLatest([
      this.subscriptionOverviewService.subscription$.pipe(filter(Boolean)),
      this.cancellationReason$,
      this.cancellationStep$,
    ])
      .pipe(
        take(1),
        tap(
          ([
            { subscription_product_uid },
            cancellationReason,
            cancellationStep,
          ]: [Subscription, CANCELLATION_REASON, CancellationStep]) => {
            this.analyticsService.track(eventName, {
              subscription_product_uid,
              reason: cancellationReason,
              cancellation_step: cancellationStep + 1,
              ...payload,
            });
          },
        ),
      )
      .subscribe();
  };

  openCancellationDialog = () => {
    this.trackEvent('Subscription Savedesk Dialog Shown', {
      phone_available: this.isPhoneAvailable,
    });

    this.reset();
    this.isCancellationDialogOpen$.next(true);
  };

  showCancellationReasons = () => {
    this.trackEvent('Subscription Savedesk Dialog: Cancel Online', {
      phone_available: this.isPhoneAvailable,
    });

    this.setNextCancellationStep(CancellationStep.CancellationReason);

    this.trackEvent('Subscription Select Cancellation Reason Dialog Shown');
  };

  showArgumentToStayBasedOnUser = () => {
    this.trackEvent(
      'Subscription Argument Based On Reason Dialog: Continue Cancellation',
    );

    this.setNextCancellationStep(CancellationStep.ArgumentToStayBasedOnUser);

    this.argumentTooStayBasedOnUser$
      .pipe(
        take(1),
        tap((argument) => {
          this.trackEvent('Subscription Argument Based On User Dialog Shown', {
            argument,
          });
        }),
      )
      .subscribe();
  };

  dismissArgumentToStayBasedOnUser = () => {
    this.argumentTooStayBasedOnUser$
      .pipe(
        take(1),
        tap((argument) => {
          this.trackEvent(
            'Subscription Argument Based On User Dialog: Continue Cancellation',
            {
              argument,
            },
          );
        }),
      )
      .subscribe();

    this.cancelSubscription();
  };

  dismissCancellationDialog = () => {
    combineLatest([this.cancellationStep$, this.argumentTooStayBasedOnUser$])
      .pipe(
        take(1),
        tap(([cancellationStep, argument]) => {
          switch (cancellationStep) {
            case CancellationStep.Savedesk:
              this.trackEvent('Subscription Savedesk Dialog: Do Not Cancel', {
                phone_available: this.isPhoneAvailable,
              });
              break;
            case CancellationStep.CancellationReason:
              this.trackEvent(
                'Subscription Select Cancellation Reason Dialog: Do Not Cancel',
              );
              break;
            case CancellationStep.ArgumentToStayBasedOnCancellationReason:
              this.trackEvent(
                'Subscription Argument Based On Reason Dialog: Do Not Cancel',
              );
              break;
            case CancellationStep.ArgumentToStayBasedOnUser:
              this.trackEvent(
                'Subscription Argument Based On User Dialog: Do Not Cancel',
                { argument },
              );
              break;
            case CancellationStep.Confirmation:
              this.trackEvent(
                'Subscription Cancelled Confirmation Dialog: Confirm',
              );
              break;
            default:
              break;
          }
        }),
      )
      .subscribe();

    this.isCancellationDialogOpen$.next(false);
  };

  closeCancellationDialog = () => {
    combineLatest([this.cancellationStep$, this.argumentTooStayBasedOnUser$])
      .pipe(
        take(1),
        tap(([cancellationStep, argument]) => {
          switch (cancellationStep) {
            case CancellationStep.Savedesk:
              this.trackEvent('Subscription Savedesk Dialog Closed', {
                phone_available: this.isPhoneAvailable,
              });
              break;
            case CancellationStep.CancellationReason:
              this.trackEvent(
                'Subscription Select Cancellation Reason Dialog Closed',
              );
              break;
            case CancellationStep.ArgumentToStayBasedOnCancellationReason:
              this.trackEvent(
                'Subscription Argument Based On Reason Dialog Closed',
              );
              break;
            case CancellationStep.ArgumentToStayBasedOnUser:
              this.trackEvent(
                'Subscription Argument Based On User Dialog Closed',
                { argument },
              );
              break;
            case CancellationStep.Confirmation:
              this.trackEvent(
                'Subscription Cancelled Confirmation Dialog Closed',
              );
              break;
            default:
              break;
          }
        }),
      )
      .subscribe();

    this.isCancellationDialogOpen$.next(false);
  };

  reset = () => {
    this.setNextCancellationStep(CancellationStep.Savedesk);
    this.cancellationReason$.next(undefined);
  };

  setReason = (reason: CANCELLATION_REASON) => {
    this.cancellationReason$.next(reason);
  };

  setNextCancellationStep = (nextStep: CancellationStep) => {
    this.cancellationStep$.next(nextStep);
  };

  submitCancellationReason = () => {
    this.cancellationReason$
      .pipe(
        take(1),
        tap((cancellationReason) => {
          this.trackEvent(
            'Subscription Select Cancellation Reason Dialog: Reason Submitted',
          );

          if (
            cancellationReason === CANCELLATION_REASON.UNAWARE_OF_SUBSCRIPTION
          ) {
            this.cancelSubscription();
          } else if (
            cancellationReason === CANCELLATION_REASON.MISS_DPG_TITLES
          ) {
            this.cancelSubscription();
          } else {
            this.setNextCancellationStep(
              CancellationStep.ArgumentToStayBasedOnCancellationReason,
            );

            this.trackEvent(
              'Subscription Argument Based On Reason Dialog Shown',
            );
          }
        }),
      )
      .subscribe();
  };

  closeDialogAfterFailedRequest = () => {
    this.isButtonLoading$.next(false);
    this.isCancellationDialogOpen$.next(false);
  };

  refetchSubscription = (callbackAfterReFetchingSubscription: Function) => {
    this.subscriptionOverviewService
      .fetchSubscription()
      .pipe(
        take(1),
        catchError((error) => {
          this.isButtonLoading$.next(false);

          callbackAfterReFetchingSubscription();

          throw error;
        }),
      )
      .subscribe(() => {
        this.isButtonLoading$.next(false);

        callbackAfterReFetchingSubscription();
      });
  };

  cancelSubscription = () => {
    combineLatest([
      this.subscriptionOverviewService.subscription$,
      this.cancellationReason$,
      this.userService.user$,
    ])
      .pipe(
        take(1),
        tap(() => {
          this.isButtonLoading$.next(true);
        }),
        mergeMap(([subscription, cancellationReason, user]) => {
          if (!subscription) {
            throw new Error('No subscription found');
          }

          const { subscription_product_uid } = subscription;

          return this.requestService
            .post(
              'user_subscription',
              { renew: false },
              {
                user_uid: user!.id,
                subscription_uid: subscription_product_uid,
              },
            )
            .pipe(
              tap(() => {
                const endDate = endDateWhenCancelled(subscription);

                this.analyticsService.track('Subscription Cancel', {
                  subscription_product_uid: subscription_product_uid,
                  reason: cancellationReason,
                  subscription_type: 'internal',
                  subscription_end_date: endDate?.toISOString(),
                });

                const callbackAfterReFetchingSubscription = () => {
                  this.setNextCancellationStep(CancellationStep.Confirmation);

                  this.trackEvent(
                    'Subscription Cancelled Confirmation Dialog Shown',
                  );
                };

                this.refetchSubscription(callbackAfterReFetchingSubscription);
              }),
              catchError((error) => {
                this.analyticsService.track('Subscription Cancel Failed', {
                  subscription_product_uid: subscription_product_uid,
                  reason: cancellationReason,
                  subscription_type: 'internal',
                });

                this.closeDialogAfterFailedRequest();

                this.errorsService.addError(error, 'error.message.default');

                return NEVER;
              }),
            );
        }),
      )
      .subscribe();
  };

  navigateToUpdateSubscriptionPage = () => {
    const { params } = this.routerService.routeSnapshot;

    this.routerService.router.navigate(
      RouteName.SubscriptionOverviewChangeSubscriptionPlan,
      { ...params, upgradeCouponCode: config.cancellationFlowUpgradeCoupon },
    );
  };

  private fetchTransactions$() {
    return this.userService.user$.pipe(
      filterEmpty,
      take(1),
      switchMap((user) =>
        this.requestService
          .get('transactions', { user_id: user.id })
          .pipe(map(({ data }) => transactions(data))),
      ),
    );
  }

  private determineArgumentBasedOnUser$() {
    return combineLatest([
      this.userService.user$.pipe(filterEmpty, take(1)),
      this.userService.creditsBalance$,
      this.hasPurchasedArticles$,
    ]).pipe(
      take(1),
      map(([user, creditsBalance, hasPurchasedArticles]) => {
        if (creditsBalance && creditsBalance > 0) {
          return USER_BASED_ARGUMENT.CREDITS_LEFT;
        }

        if (user.pins > thresholdForShowingPinsArgument) {
          return USER_BASED_ARGUMENT.PINNED_ARTICLES;
        }

        if (hasPurchasedArticles) {
          return USER_BASED_ARGUMENT.BOUGHT_ARTICLES;
        }

        return USER_BASED_ARGUMENT.READ_ARTICLES;
      }),
    );
  }
}
