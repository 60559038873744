import { injectable } from 'inversify';
import { createEventHandler } from '@blendle/recompose';
import { merge as observableMerge, Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ErrorsService } from '../ErrorsService';
import { SourceService } from '../SourceService';

@injectable()
export class FormService {
  submit$: Observable<React.FormEvent<HTMLFormElement>>;

  creditCardValid$: Observable<boolean>;
  handleCreditcardFormStatusChange: (value: boolean) => void;

  ibanValid$: Observable<boolean>;
  handleIbanFormStatusChange: (value: boolean) => void;

  readonly buttonLoadingSubject$ = new Subject<boolean>();
  readonly buttonLoading$ = observableMerge(
    this.buttonLoadingSubject$.asObservable(),
    this.sourceService.loading$,
  );

  private readonly submitSubject$ = new Subject<
    React.FormEvent<HTMLFormElement>
  >();

  constructor(
    private readonly sourceService: SourceService,
    private readonly errorsService: ErrorsService,
  ) {
    this.submit$ = this.submitSubject$.asObservable();

    const {
      handler: handleCreditcardFormStatusChange,
      stream: creditCardValid$,
    } = createEventHandler<boolean, Observable<boolean>>();

    this.handleCreditcardFormStatusChange = handleCreditcardFormStatusChange;
    this.creditCardValid$ = creditCardValid$.pipe(startWith(false));

    const {
      handler: handleIbanFormStatusChange,
      stream: ibanValid$,
    } = createEventHandler<boolean, Observable<boolean>>();

    this.handleIbanFormStatusChange = handleIbanFormStatusChange;
    this.ibanValid$ = ibanValid$.pipe(startWith(false));
  }

  readonly setButtonLoading = (newValue: boolean) => {
    this.buttonLoadingSubject$.next(newValue);
  };

  readonly handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.errorsService.removeCurrentError();
    this.submitSubject$.next(event);
  };

  handleCardElementChange = ({ complete }: { complete: boolean }) => {
    this.handleCreditcardFormStatusChange(complete);
  };

  handleIbanElementChange = ({ complete }: { complete: boolean }) => {
    this.handleIbanFormStatusChange(complete);
  };
}
