import { injectable } from 'inversify';
import {
  merge as observableMerge,
  Observable,
  of as observableOf,
  timer as observableTimer,
} from 'rxjs';
import { map, switchMap, switchMapTo, take } from 'rxjs/operators';
import { RequestService } from '../RequestService';
import { isFunctionalTestEnv } from 'environment-config';
import { includeCounterToRequestOptionsIfFunctionalTest } from 'utils/functionalTest';
import { SubscriptionTokenService } from '../SubscriptionToken/service';
import { Router } from '../Router';

const DEFAULT_RETRY_AFTER_SECONDS = 5;

type StatusResponse = {
  status: 'activated' | 'pending_activation' | 'not_found';
};

@injectable()
export class SubscriptionStatusService {
  public subscriptionStatus$ = this.subscriptionTokenService.subscriptionToken$.pipe(
    switchMap((subscriptionToken) => {
      const token =
        this.router.routeSnapshot.params.subscription_token ||
        subscriptionToken;

      if (!token) {
        throw new Error(
          'Cannot verify subscription data without subscriptionToken',
        );
      }

      const options = includeCounterToRequestOptionsIfFunctionalTest(
        isFunctionalTestEnv,
        this.functionalTestCounter,
        {},
      );

      return this.requestService.post<StatusResponse>(
        'subscription_status',
        token,
        {},
        options,
      );
    }),
    take(1),
  );

  public hasActiveSubscription$ = this.subscriptionStatus$.pipe(
    map(({ data: { status }, response }) => {
      let hasActiveSubscription: boolean;
      switch (status) {
        case 'activated':
          hasActiveSubscription = true;
          break;
        case 'not_found':
        case 'pending_activation':
          hasActiveSubscription = false;
          break;

        default:
          throw new Error(`Unknown Subscription Status: "${status}"`);
      }

      const retryAfterSeconds =
        Number(response.headers.get('retry-after')) ||
        DEFAULT_RETRY_AFTER_SECONDS;

      this.functionalTestCounter = this.functionalTestCounter + 1;

      return {
        hasActiveSubscription,
        retryAfter: retryAfterSeconds * 1000,
      };
    }),
  );

  public get hasActiveSubscriptionRetry$(): Observable<boolean> {
    return this.hasActiveSubscription$.pipe(
      switchMap(({ hasActiveSubscription, retryAfter }) => {
        if (hasActiveSubscription) {
          // everything is cool and we can stop retrying
          return observableOf(true);
        }
        return observableMerge(
          // submit it early
          observableOf(false),
          // but also retry again
          observableTimer(retryAfter).pipe(
            switchMapTo(this.hasActiveSubscriptionRetry$),
          ),
        );
      }),
    );
  }

  public functionalTestCounter: number;

  constructor(
    private readonly requestService: RequestService,
    private readonly subscriptionTokenService: SubscriptionTokenService,
    private readonly router: Router,
  ) {
    this.functionalTestCounter = 0;
  }
}
