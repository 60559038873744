import { HttpRequestOptions } from 'shared/services/HttpService';

export function includeCounterToRequestOptionsIfFunctionalTest(
  isFunctionalTestEnv: boolean,
  functionalTestCounter: number,
  defaultOptions: HttpRequestOptions,
) {
  return isFunctionalTestEnv
    ? {
        ...defaultOptions,
        headers: {
          'x-functional-test-counter': String(functionalTestCounter),
        },
      }
    : defaultOptions;
}
