import { SourceService } from 'shared/services/SourceService';
import { Metadata } from 'shared/services/SourceService/types';

export interface CreateExistingPaymentMethodResponse {
  paymentMethod: {
    id: string;
    type: string;
    metadata: Metadata;
  };
  error?: Error | undefined;
}

export function createExistingPaymentMethod(
  id: string,
  {
    type,
    metadata,
  }: {
    type: string;
    metadata: Metadata;
  },
  nextExistingPaymentMethod: typeof SourceService.prototype.nextExistingPaymentMethod,
): Promise<CreateExistingPaymentMethodResponse> {
  return new Promise((resolve) => {
    const paymentMethod = { id, type, metadata };

    nextExistingPaymentMethod(paymentMethod);
    resolve({ paymentMethod });
  });
}
