import { any, propEq } from 'ramda';
import { of as observableOf } from 'rxjs';
import { ErrorsService } from '../ErrorsService';
import { SubscriptionStreamResponse } from './types';
import { halError } from 'utils/errors';

export enum SubscriptionTokenErrorMessage {
  UserAlreadyHasSubscription = 'error.message.userAlreadyHasSubscription',
  BillingCountryNotSupported = 'error.message.billingCountryNotSupported',
  Default = 'error.message.default',
}

const userAlreadyHasSubscription = propEq('id', 'UserAlreadyHasSubscription');
const billingCountryNotSupported = propEq('id', 'BillingCountryNotSupported');

export const getErrorMessageId = (errors: halError[]) => {
  if (any(userAlreadyHasSubscription, errors)) {
    return SubscriptionTokenErrorMessage.UserAlreadyHasSubscription;
  }

  if (any(billingCountryNotSupported, errors)) {
    return SubscriptionTokenErrorMessage.BillingCountryNotSupported;
  }

  return SubscriptionTokenErrorMessage.Default;
};

export const catchSubscriptionError = (
  addError: typeof ErrorsService.prototype.addError,
) => (errors: halError[] | Error = []) => {
  if (Array.isArray(errors)) {
    const errorMessageId = getErrorMessageId(errors);

    errors.forEach(({ message }) =>
      addError(new Error(message), errorMessageId, Infinity),
    );

    return observableOf({
      response: SubscriptionStreamResponse.GeneralError,
      error: errors,
    });
  }

  throw errors;
};
