// This controls if the "I have a coupon" link is shown.
// Coupons passed in via the URL always work
export const areUserEnteredCouponsEnabled = () => false;

export const isSavedeskEnabled = () => false;

export const isUpgradePlanEnabled = () => true;

export const isSegmentEnabled = () => true;

export const isOptInSubscriptionUpgradeEnabled = () => true;
