import { equals, anyPass, lt, __ } from 'ramda';
import { getUtcDayJs } from 'utils/date';

export enum WhenIsSavedeskOpen {
  Now = 'now',
  LaterToday = 'laterToday',
  Tomorrow = 'tomorrow',
  Monday = 'monday',
}

// The date is in UTC format, our 9 to 5 opening hours are 2 hours ahead of UTC;
const UTC_OFFSET = 2;

const isFriday = equals(5);
const isSaturday = equals(6);
const isSunday = equals(0);
const isWeekend = anyPass([isSaturday, isSunday]);
const isBeforeNineInTheMorning = lt(__, 9 - UTC_OFFSET);
const isBeforeFiveInTheAfternoon = lt(__, 17 - UTC_OFFSET);

export const determineWhenSavedeskIsOpen = (date: Date) => {
  const now = getUtcDayJs(date);

  const dayOfTheWeek = now.day(); // 0 being Sunday and 6 being Saturday
  const hourOfTheDay = now.hour(); // number ranges from 0 to 23

  if (isWeekend(dayOfTheWeek)) {
    return WhenIsSavedeskOpen.Monday;
  }

  if (isBeforeNineInTheMorning(hourOfTheDay)) {
    return WhenIsSavedeskOpen.LaterToday;
  }

  if (isBeforeFiveInTheAfternoon(hourOfTheDay)) {
    return WhenIsSavedeskOpen.Now;
  }

  return isFriday(dayOfTheWeek)
    ? WhenIsSavedeskOpen.Monday
    : WhenIsSavedeskOpen.Tomorrow;
};
