import { injectable } from 'inversify';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import config from 'environment-config';
import { ScriptService } from '../ScriptService';

declare global {
  interface Window {
    Stripe: stripe.StripeStatic;
  }
}

@injectable()
export class StripeService {
  readonly apiKey = config.stripePublishableKey;

  private stripe$ = this.scriptService
    .requestScript(ScriptService.scripts.stripe)
    .pipe(
      map(() => window.Stripe),
      distinctUntilChanged(),
      map(() => window.Stripe(this.apiKey)),
    );

  constructor(private scriptService: ScriptService) {}

  retrieveSource(options: {
    /**
     * A secret available to the web client that created the Source,
     * for purposes of retrieving the Source later from that same client.
     */
    clientSecret: string;
    /**
     * Unique identifier of the source
     */
    id: string;
  }): Observable<stripe.SourceResponse> {
    return this.stripe$.pipe(
      switchMap((stripe: stripe.Stripe) =>
        stripe.retrieveSource({
          client_secret: options.clientSecret,
          id: options.id,
        }),
      ),
    );
  }

  handleCardSetup = (
    clientSecret: string,
    paymentMethodId: string,
  ): Observable<stripe.PaymentIntentResponse> =>
    this.stripe$.pipe(
      switchMap((stripe: stripe.Stripe) =>
        stripe.handleCardSetup(clientSecret, {
          payment_method: paymentMethodId,
        }),
      ),
    );

  handleCardPayment = (
    clientSecret: string,
    paymentMethodId: string,
  ): Observable<stripe.PaymentIntentResponse> =>
    this.stripe$.pipe(
      switchMap((stripe: stripe.Stripe) =>
        stripe.handleCardPayment(clientSecret, {
          payment_method: paymentMethodId,
        }),
      ),
    );

  retrievePaymentIntent = (
    paymentIntentClientSecret: string,
  ): Observable<stripe.PaymentIntentResponse> =>
    this.stripe$.pipe(
      switchMap((stripe: stripe.Stripe) =>
        stripe.retrievePaymentIntent(paymentIntentClientSecret),
      ),
    );
}
