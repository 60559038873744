import { Container } from 'dependency-injection';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsService } from 'shared/services/AnalyticsService';
import {
  displayId,
  SubscriptionPlans,
  SubscriptionPlansService,
  subscriptionProductUid,
} from 'shared/services/SubscriptionPlans';
import { filterEmpty } from 'utils/filter-empty';
import { Router } from '../Router';

export interface SubscriptionProductProps {
  products: SubscriptionPlans;
  selectedProductId?: string;
  selectProduct: (productId: string) => void;
}

export const SubscriptionProductTransformFactory = (
  container: Container,
) => (): Observable<SubscriptionProductProps> => {
  const subscriptionPlansService = container.get(SubscriptionPlansService);
  const analyticsService = container.get(AnalyticsService);
  const { navigateToPaymentPage } = container.get(Router);
  const subscriptionPlans$ = filterEmpty(
    subscriptionPlansService.subscriptionPlans$,
  );

  const selectProduct = (productId: string) => {
    analyticsService.track(
      'Provider Subscription/Select Subscription Product',
      {
        subscription_product_uid: subscriptionProductUid(productId),
        provider_id: 'blendlepremium',
      },
    );

    navigateToPaymentPage({ productId });
  };

  return observableCombineLatest([
    subscriptionPlans$,
    subscriptionPlansService.selectedPlanWithDefault$,
  ]).pipe(
    map(([products, selectedPlan]) => ({
      selectedProductId: displayId(selectedPlan),
      products,
      selectProduct,
    })),
  );
};
