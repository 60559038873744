import { container, lazyInject } from 'dependency-injection';
import React, { Suspense } from 'react';
import { includes } from 'ramda';
import { withRoute } from 'react-router5';
import { Router } from 'router5';
import { StripeProvider } from 'react-stripe-elements';
import { compose, nest, withProps } from '@blendle/recompose';
import {
  EnsureStripeJsLoaded,
  EntryPoints,
  ErrorLoggerService,
  pageLoadAnalyticsFactory,
  pageLoadUtmDataFactory,
  StripeService,
  TranslationProvider,
  UserService,
  SegmentAnalyticsService,
} from 'shared';
import { theme } from 'theme';
import config from 'environment-config';
import {
  getRefreshToken,
  saveRefreshToken,
  deletePaymentProjectRefreshTokenCookie,
  saveInternalLocation,
} from './utils/storage';
import { CouponUrlService } from 'shared/services/CouponUrlService/service';
import { withTrackingPixels } from 'shared/hocs/withTrackingPixels';
import { ThemeProvider } from '@blendle/lego';
import { RouteName } from './routes';
import ApplicationLoader from 'shared/components/ApplicationLoader';

const Onboarding = React.lazy(
  () => import(/* webpackChunkName: "onboarding" */ 'scenes/Onboarding'),
);
const Upsell = React.lazy(
  () => import(/* webpackChunkName: "upsell" */ 'scenes/Upsell'),
);
const SwitchToPremium = React.lazy(
  () =>
    import(/* webpackChunkName: "switchToPremium" */ 'scenes/SwitchToPremium'),
);
const Subscription = React.lazy(
  () => import(/* webpackChunkName: "subscription" */ 'scenes/Subscription'),
);
const BuyCredits = React.lazy(
  () => import(/* webpackChunkName: "buyCredits" */ 'scenes/BuyCredits'),
);

const getOrigin = () => window.location.origin || window.origin || '';

const shouldDeletePaymentProjectRefreshTokenCookie = includes(
  'payment.blendle.com',
  getOrigin(),
);

const StripeWithApiKey = (props: { children: React.ReactChildren }) => (
  <StripeProvider apiKey={container.get(StripeService).apiKey} {...props} />
);

const Providers = nest(
  withProps({ locale: 'nl-NL' })(TranslationProvider),
  EnsureStripeJsLoaded,
  StripeWithApiKey,
);

interface Props {
  router: Router;
}

class App extends React.Component<Props> {
  @lazyInject(UserService)
  private readonly userService: UserService;
  @lazyInject(EntryPoints)
  private readonly entryPoints: EntryPoints;
  @lazyInject(ErrorLoggerService)
  private readonly errorLoggerService: ErrorLoggerService;
  @lazyInject(CouponUrlService)
  private readonly couponUrlService: CouponUrlService;
  @lazyInject(SegmentAnalyticsService)
  private readonly segmentAnalyticsService: SegmentAnalyticsService;

  componentDidMount() {
    this.saveUrlParamData();

    this.entryPoints.addEntryPoints([config.apiJSON, config.paymentApiJSON]);

    if (shouldDeletePaymentProjectRefreshTokenCookie) {
      deletePaymentProjectRefreshTokenCookie();
    }

    this.userService.refreshToken = getRefreshToken();

    this.addErrorHandler();
  }

  addErrorHandler() {
    window.onerror = (
      message: string | Event,
      url: string | undefined,
      line: number | undefined,
      column: number | undefined,
      exception: Error | undefined,
    ) => {
      if (exception) {
        this.errorLoggerService.displayExceptionAndLogToSentry(exception);
      }
    };

    window.onunhandledrejection = (obj: { reason: Error }) => {
      if (
        window.console &&
        window.console.error &&
        !navigator.userAgent.match(/Chrome/)
      ) {
        window.console.error('Unhandled promise rejection ' + obj.reason);
      }

      this.errorLoggerService.displayExceptionAndLogToSentry(obj.reason);
    };
  }

  saveUrlParamData() {
    const paramsMap = new URLSearchParams(window.location.search);

    if (paramsMap.has('refreshToken')) {
      saveRefreshToken(paramsMap.get('refreshToken')!);
    }

    // Internal location needs to be stored as soon as possible, so its correctly included in the Session event
    if (paramsMap.has('internalLocation')) {
      saveInternalLocation(paramsMap.get('internalLocation')!);
    }
  }

  render() {
    const { router } = this.props;

    return (
      <React.StrictMode>
        <Providers>
          <ThemeProvider customTheme={theme}>
            <Suspense fallback={<ApplicationLoader />}>
              {router.isActive(RouteName.Onboarding) && <Onboarding />}
              {router.isActive(RouteName.Upsell) && <Upsell />}
              {router.isActive(RouteName.SwitchToPremium) && (
                <SwitchToPremium />
              )}
              {router.isActive(RouteName.SubscriptionOverview) && (
                <Subscription />
              )}
              {router.isActive(RouteName.BuyCredits) && <BuyCredits />}
            </Suspense>
          </ThemeProvider>
        </Providers>
      </React.StrictMode>
    );
  }
}

const enhance = compose(
  withRoute,
  pageLoadAnalyticsFactory(container),
  pageLoadUtmDataFactory(container),
  withTrackingPixels(),
);

export default enhance(App);
