import { format, parse } from 'url';

export function appendSearchString(href: string, searchString: string): string {
  if (!searchString) {
    return href;
  }

  const uri = parse(href);

  const searchWithUtm = uri.search
    ? `${uri.search}&${searchString}`
    : searchString;
  uri.search = searchWithUtm;

  return format(uri);
}
