import { Route } from 'router5';

export enum RouteName {
  Default = 'default',
  Upsell = 'upsell',
  Onboarding = 'onboarding',
  SwitchToPremium = 'switchToPremium',
  SubscriptionOverview = 'subscription',
  SubscriptionOverviewChangePaymentMethod = `subscription.changePaymentMethod`,
  SubscriptionOverviewChangeSubscriptionPlan = 'subscription.changeSubscriptionPlan',
  BuyCredits = 'buyCredits',
}

export enum StartSubscriptionChildRouteName {
  Plan = 'plan',
  Method = 'method',
  Return = 'return',
}

export enum BuyCreditsChildRouteName {
  Return = 'return',
}

const ChangePaymentMethodChildRoute = 'changePaymentMethod';
const ChangeSubscriptionPlanChildRoute = 'changeSubscriptionPlan';

const children: Route[] = [
  {
    name: StartSubscriptionChildRouteName.Plan,
    path: '/:productId',
    children: [
      {
        name: StartSubscriptionChildRouteName.Method,
        path: '/:paymentMethod',
      },
    ],
  },
  {
    name: StartSubscriptionChildRouteName.Return,
    path: '/return?:client_secret?:source',
  },
];

export const routes: Route[] = [
  {
    name: RouteName.Default,
    path: '/',
    forwardTo: 'upsell',
  },
  {
    name: RouteName.Upsell,
    path: '/try-now?:refreshToken?:successPath',
    children,
  },
  {
    name: RouteName.Onboarding,
    path: '/start-with?:refreshToken?:successPath',
    children,
  },
  {
    name: RouteName.SwitchToPremium,
    path: '/switch-to-premium?:refreshToken?:successPath',
    children,
  },
  {
    name: RouteName.SubscriptionOverview,
    path: '/subscription',
    children: [
      {
        name: ChangePaymentMethodChildRoute,
        path: '/change-payment-method',
      },
      {
        name: ChangeSubscriptionPlanChildRoute,
        path: '/change-subscription-plan',
      },
    ],
  },
  {
    name: RouteName.BuyCredits,
    path: '/buy-credits',
    children: [
      {
        name: BuyCreditsChildRouteName.Return,
        path: '/return',
      },
    ],
  },
];
