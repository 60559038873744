import { container } from 'dependency-injection';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  withLatestFrom,
  distinctUntilChanged,
  take,
} from 'rxjs/operators';
import { User } from 'shared/models';
import { ErrorsService } from '../ErrorsService';
import { SubscriptionTokenService } from './service';
import { ValidatedCoupon } from '../CouponService/types';
import { hasValidCoupon } from '../CouponService/helpers';
import { SubscriptionTokenRequestPayload } from './types';
import { catchSubscriptionError } from './errorHandler';
import { UserService } from '../UserService';
import { SubscriptionPlansService } from '../SubscriptionPlans';
import { CouponService } from '../CouponService';
import { filterEmpty } from 'utils';

export const mapUserAndPayload: (
  source$: Observable<[string, User, ValidatedCoupon | undefined]>,
) => Observable<SubscriptionTokenRequestPayload> = map(
  ([subscriptionPlan, { full_name, country, email }, validatedCoupon]) => {
    const couponCode =
      validatedCoupon && hasValidCoupon(validatedCoupon)
        ? { coupon_code: validatedCoupon.couponCode }
        : {};

    return {
      full_name,
      billing_country: country,
      email,
      subscription_plan: subscriptionPlan,
      ...couponCode,
    };
  },
);

const createPayload$ = (): Observable<SubscriptionTokenRequestPayload> => {
  const { user$ } = container.get(UserService);
  const { selectedPlanWithDefault$ } = container.get(SubscriptionPlansService);
  const { validatedCoupon$ } = container.get(CouponService);

  const planId$ = selectedPlanWithDefault$.pipe(
    map((plan) => plan.id),
    distinctUntilChanged(),
  );

  return planId$.pipe(
    withLatestFrom(filterEmpty(user$), validatedCoupon$),
    mapUserAndPayload,
  );
};

/*
 * Function to fetch a subscriptionToken.
 * When called without an argument it creates a payload stream.
 * Whenever there is a new payload it fetches a new subscription token
 * A new payload is created for instance when the user switches subscription plans
 * This makes sure we always use an up-to-date valid subscription token
 */
export function fetchSubscriptionToken(
  payload$: Observable<SubscriptionTokenRequestPayload> = createPayload$(),
) {
  const subscriptionTokenService = container.get(SubscriptionTokenService);
  const errorsService = container.get(ErrorsService);

  subscriptionTokenService
    .fetchSubscriptionToken(payload$)
    .pipe(catchError(catchSubscriptionError(errorsService.addError)))
    .subscribe();
}

export function refreshSubscriptionToken(): void {
  const { removeSubscriptionToken } = container.get(SubscriptionTokenService);

  removeSubscriptionToken();

  const payload$ = createPayload$().pipe(take(1));

  fetchSubscriptionToken(payload$);
}
