import { injectable, postConstruct } from 'inversify';
import { RequestService } from '../RequestService';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { CreditBundles, CreditBundle } from './types';
import { map, startWith } from 'rxjs/operators';
import { filterEmpty } from 'utils/filter-empty';
import { head } from 'ramda';
import { AnalyticsService } from '../AnalyticsService';

interface CreditBundlesResponse {
  credit_bundles: CreditBundles;
}

@injectable()
export class CreditBundleService {
  readonly creditBundles$ = new BehaviorSubject<CreditBundles | null>(null);

  readonly selectedCreditBundle$: Observable<CreditBundle>;

  readonly selectedCreditBundleWithDefault$: Observable<CreditBundle>;

  private readonly bundleSelection$ = new Subject<CreditBundle>();

  constructor(
    private readonly request: RequestService,
    private readonly analyticsService: AnalyticsService,
  ) {
    this.selectedCreditBundle$ = this.bundleSelection$.asObservable();

    this.selectedCreditBundleWithDefault$ = combineLatest([
      this.selectedCreditBundle$.pipe(startWith(undefined)),
      this.creditBundles$.pipe(filterEmpty),
    ]).pipe(
      map(
        ([selectedCreditBundle, creditBundles]) =>
          selectedCreditBundle || head(creditBundles),
      ),
      filterEmpty,
    );
  }

  @postConstruct()
  fetchPlans() {
    this.request
      .get<CreditBundlesResponse>('credit_bundles')
      .pipe(map(({ data }) => data.credit_bundles))
      .subscribe((creditBundles) => this.creditBundles$.next(creditBundles));
  }

  selectCreditBundle = (bundle: CreditBundle) => {
    this.analyticsService.track('Credits Topup/Select Amount', {
      credits_amount: bundle.quantity,
    });

    this.bundleSelection$.next(bundle);
  };
}
