import { injectable } from 'inversify';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  ExistingSource,
  PaymentMethodsService,
  ExistingPaymentMethod,
} from '../PaymentMethodsService';
import { head } from 'ramda';

@injectable()
export class SelectedExistingMethodService {
  readonly selectedExistingSepaDebitOrCard$: Observable<
    ExistingSource | ExistingPaymentMethod
  >;
  readonly selectedExistingSepaDebitOrCardWithDefault$: Observable<
    ExistingSource | ExistingPaymentMethod
  >;
  readonly hasSelectedExistingSepaDebitOrCard$: Observable<boolean>;

  private readonly existingSepaDebitOrCardSelection$ = new Subject<
    ExistingSource | ExistingPaymentMethod
  >();

  constructor(private readonly paymentMethodsService: PaymentMethodsService) {
    this.selectedExistingSepaDebitOrCard$ = this.existingSepaDebitOrCardSelection$.asObservable();

    this.selectedExistingSepaDebitOrCardWithDefault$ = combineLatest([
      this.selectedExistingSepaDebitOrCard$.pipe(startWith(undefined)),
      this.paymentMethodsService.selectedExistingMethodWithDefault$,
    ]).pipe(
      map(
        ([selectedExistingSepaDebitOrCard, existingMethod]) =>
          selectedExistingSepaDebitOrCard ||
          head(
            existingMethod.existingMethods as Array<
              ExistingSource | ExistingPaymentMethod
            >,
          )!,
      ),
    );

    this.hasSelectedExistingSepaDebitOrCard$ = this.selectedExistingSepaDebitOrCardWithDefault$.pipe(
      map(Boolean),
      startWith(false),
    );
  }

  selectExistingSepaDebitOrCard = (
    value: ExistingSource | ExistingPaymentMethod,
  ) => {
    this.existingSepaDebitOrCardSelection$.next(value);
  };
}
