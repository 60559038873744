import { injectable, postConstruct } from 'inversify';
import { BehaviorSubject, NEVER } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom } from 'rxjs/operators';
import { ChangePaymentDetailsFormService } from './formService';
import { Router } from '../Router';
import { ErrorLoggerService } from '../ErrorLoggerService';
import { SubscriptionOverviewService } from '../SubscriptionOverviewService';
import { isSupportedSubscription } from './helpers';
import { RequestService } from '../RequestService';
import { prop } from 'ramda';
import { AnalyticsService } from '../AnalyticsService';
import { filterEmpty } from 'utils';
import { Subscription } from '../SubscriptionOverviewService/types';
import { NotificationService } from '../NotificationService';
import { ChangePaymentDetailsSuccessNotification } from 'shared/components/Notifications/ChangePaymentDetailsSuccessNotification';
import { RouteName } from 'routes';

const getStripeSubscriptionId = prop('stripe_subscription_id');

@injectable()
export class ChangePaymentDetailsSubmitService {
  readonly source$ = new BehaviorSubject<stripe.Source | null>(null);

  constructor(
    private readonly changePaymentDetailsFormService: ChangePaymentDetailsFormService,
    private readonly subscriptionOverviewService: SubscriptionOverviewService,
    private readonly router: Router,
    private readonly errorLoggerService: ErrorLoggerService,
    private readonly requestService: RequestService,
    private readonly analyticsService: AnalyticsService,
    private readonly notificationService: NotificationService,
  ) {}

  newSource = (source: stripe.Source) => this.source$.next(source);

  @postConstruct()
  submitNewPaymentDetails() {
    const source$ = this.source$.pipe(filterEmpty);
    const subscription$ = this.subscriptionOverviewService.subscription$.pipe(
      filterEmpty,
    );

    source$
      .pipe(
        withLatestFrom(subscription$),
        switchMap(([source, subscription]) => {
          if (!isSupportedSubscription(subscription)) {
            const error = new Error(
              'Subscription does not support updating payment details',
            );

            return this.handleError(error, source, subscription);
          }

          return this.submitDetails({
            source,
            // tsc isn't smart enough to understand the stripe_subscription_id is
            // present after the isSupportedSubscription check
            // @ts-ignore
            stripeSubscriptionId: getStripeSubscriptionId(subscription),
          }).pipe(
            catchError((error: Error) =>
              this.handleError(error, source, subscription),
            ),
            map((result) => ({ result, subscription, source })),
          );
        }),
      )
      .subscribe(({ subscription, source }) => {
        this.analyticsService.track('Update Payment Details: Success', {
          payment_method: source.type,
          subscription_product_uid: subscription.subscription_product_uid,
        });

        this.subscriptionOverviewService.clearSubscription();
        this.changePaymentDetailsFormService.buttonLoading$.next(false);

        const { router, routeSnapshot } = this.router;
        const { params } = routeSnapshot;

        router.navigate(
          RouteName.SubscriptionOverview,
          {
            ...params,
          },
          () => {
            this.notificationService.addNotification({
              component: ChangePaymentDetailsSuccessNotification,
              id: `change-payment-details-success-${Date.now()}`,
            });
          },
        );
      });
  }

  submitDetails = ({
    source,
    stripeSubscriptionId,
  }: {
    source: stripe.Source;
    stripeSubscriptionId: string;
  }) =>
    this.requestService.post(
      'update_subscription',
      { payment: source.id },
      {
        subscription_id: stripeSubscriptionId,
      },
    );

  handleError = (
    error: Error,
    source: stripe.Source,
    subscription: Subscription,
  ) => {
    this.analyticsService.track('Update Payment Details: Failed', {
      payment_method: source.type,
      subscription_product_uid: subscription.subscription_product_uid,
    });

    this.changePaymentDetailsFormService.buttonLoading$.next(false);
    this.errorLoggerService.displayExceptionAndLogToSentry(error);

    return NEVER;
  };
}
