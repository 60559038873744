import { injectable, postConstruct } from 'inversify';
import { BehaviorSubject, NEVER, EMPTY } from 'rxjs';
import { RequestService } from '../RequestService';
import { SubscriptionOverviewService } from '../SubscriptionOverviewService';
import { filterEmpty } from 'utils';
import { propOr, omit, path } from 'ramda';
import {
  take,
  switchMap,
  catchError,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { Router } from '../Router';
import { ErrorLoggerService } from '../ErrorLoggerService';
import { AnalyticsService } from '../AnalyticsService';
import { getCouponErrorId } from '../CouponService';
import {
  Subscription,
  UpcomingPeriod,
  Interval,
} from '../SubscriptionOverviewService/types';
import { NotificationService } from '../NotificationService';
import { ChangeSubscriptionPlanSuccessNotification } from 'shared/components/Notifications/ChangeSubscriptionPlanSuccessNotification';
import { halError } from 'utils/errors';
import { Coupon, Metadata } from '../SubscriptionToken/types';
import { RouteName } from 'routes';
import { ChangeSubscriptionFormService } from './formService';
import { hasPaymentMethod } from './helpers';
import { sanitizeStripeCouponMetadata } from 'utils/stripe';

const getDurationInMonths = path<number | undefined>([
  'coupon',
  'discount',
  'duration_in_months',
]);

const getMetadata = (data: UpgradedSubscriptionPreviewResponse) => {
  const metadata = path<Metadata | undefined>(['coupon', 'metadata'], data);

  return metadata ? sanitizeStripeCouponMetadata(metadata) : undefined;
};

export const removeUpgradeCouponCodeParam = omit(['upgradeCouponCode']);

export interface UpgradeCouponCodeDetails {
  upgradeCouponCode: string;
  price: number;
  paymentInterval: Interval.Month | Interval.Year;
  durationInMonths: number;
  upcoming: UpcomingPeriod;
  metadata?: Metadata;
}

const getStripeSubscriptionId = propOr(undefined, 'stripe_subscription_id');

interface ChangeSubscriptionPlanResponse {
  _errors?: Array<halError>;
}

interface UpgradedSubscriptionPreviewResponse {
  _errors?: Array<halError>;
  interval: Interval;
  price_per_interval: number;
  upcoming_period: UpcomingPeriod;
  coupon: Coupon;
}

@injectable()
export class ChangeSubscriptionPlanService {
  readonly readyToSubmitUpgrade$ = new BehaviorSubject<boolean | null>(null);

  readonly upgradeCouponCodeDetails$ = new BehaviorSubject<
    UpgradeCouponCodeDetails | undefined
  >(undefined);

  readonly source$ = new BehaviorSubject<stripe.Source | null>(null);

  constructor(
    private readonly changeSubscriptionFormService: ChangeSubscriptionFormService,
    private readonly requestService: RequestService,
    private readonly errorLoggerService: ErrorLoggerService,
    private readonly subscriptionOverviewService: SubscriptionOverviewService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
    private readonly notificationService: NotificationService,
  ) {}

  readonly newSource = (source: stripe.Source) => this.source$.next(source);

  readonly markAsReadyToSubmitUpgrade = () =>
    this.readyToSubmitUpgrade$.next(true);

  readonly getUpgradedSubscriptionPreview = (
    subscriptionId: string,
    upgradeCouponCode: string,
  ) => {
    return this.requestService
      .get<UpgradedSubscriptionPreviewResponse>(
        'subscription_discount_preview',
        {
          subscription_id: subscriptionId,
          coupon_code: upgradeCouponCode,
        },
        {
          whitelistStatusCodes: [422],
        },
      )
      .pipe(
        map(({ data }) => {
          if (data && data._errors) {
            throw data._errors;
          }

          const durationInMonths = getDurationInMonths(data);

          // We currently do not support upgrading with a one-off coupon
          if (!durationInMonths) {
            throw Error(
              `Cannot upgrade subscription without a duration in month: ${upgradeCouponCode}`,
            );
          }

          this.upgradeCouponCodeDetails$.next({
            upgradeCouponCode,
            price: data.price_per_interval,
            paymentInterval: data.interval,
            durationInMonths,
            upcoming: data.upcoming_period,
            metadata: getMetadata(data),
          });

          return EMPTY;
        }),
      );
  };

  @postConstruct()
  handleSubmit() {
    const upgradeCouponCodeDetails$ = this.upgradeCouponCodeDetails$.pipe(
      filterEmpty,
    );

    this.readyToSubmitUpgrade$
      .pipe(filterEmpty)
      .pipe(
        withLatestFrom(
          this.subscriptionOverviewService.subscription$.pipe(
            filterEmpty,
            take(1),
          ),
          upgradeCouponCodeDetails$,
          this.source$,
        ),
        switchMap(
          ([
            readyToSubmitUpgrade,
            subscription,
            { upgradeCouponCode },
            source,
          ]) => {
            const errorProps = {
              subscription,
              upgradeCouponCode,
              source,
            };

            if (!readyToSubmitUpgrade) {
              const error = new Error('Not yet ready to submit upgrade');
              return this.handleError({
                errors: error,
                ...errorProps,
              });
            }

            const stripeSubscriptionId = getStripeSubscriptionId(subscription);

            if (!stripeSubscriptionId) {
              const error = new Error(
                'Subscription does not support updating plan',
              );
              return this.handleError({
                errors: error,
                ...errorProps,
              });
            }

            if (!hasPaymentMethod(subscription) && !source) {
              const error = new Error(
                'Source is required when upgrading subscription without payment details',
              );
              return this.handleError({
                errors: error,
                ...errorProps,
              });
            }

            return this.submitDetails({
              upgradeCouponCode,
              stripeSubscriptionId: stripeSubscriptionId as string,
              source,
            }).pipe(
              catchError((error: Error | halError[]) =>
                this.handleError({
                  errors: error,
                  ...errorProps,
                }),
              ),
              map((result) => ({
                result,
                subscription,
                upgradeCouponCode,
                source,
              })),
            );
          },
        ),
      )
      .subscribe(({ subscription, upgradeCouponCode, source }) => {
        this.analyticsService.track('Update Subscription Plan: Success', {
          subscription_product_uid: subscription.subscription_product_uid,
          coupon_code: upgradeCouponCode,
          payment_method: source?.type,
        });

        this.subscriptionOverviewService.clearSubscription();
        this.changeSubscriptionFormService.buttonLoading$.next(false);

        const { router, routeSnapshot } = this.router;
        const { params } = routeSnapshot;

        router.navigate(
          RouteName.SubscriptionOverview,
          {
            ...removeUpgradeCouponCodeParam(params),
          },
          () => {
            this.notificationService.addNotification({
              component: ChangeSubscriptionPlanSuccessNotification,
              id: `change-subscription-plan-success-${Date.now()}`,
            });
          },
        );
      });
  }

  private submitDetails = ({
    upgradeCouponCode,
    stripeSubscriptionId,
    source,
  }: {
    upgradeCouponCode: string;
    stripeSubscriptionId: string;
    source: stripe.Source | null;
  }) =>
    this.requestService
      .post<ChangeSubscriptionPlanResponse>(
        'update_subscription',
        { coupon_code: upgradeCouponCode, payment: source?.id },
        {
          subscription_id: stripeSubscriptionId,
        },
        {
          whitelistStatusCodes: [422],
        },
      )
      .pipe(
        map(({ data, response }) => {
          if (data && data._errors) {
            throw data._errors;
          }

          return response;
        }),
      );

  private trackError = ({
    subscription,
    upgradeCouponCode,
    reason,
    source,
  }: {
    subscription: Subscription;
    upgradeCouponCode: string;
    reason?: string;
    source: stripe.Source | null;
  }) => {
    this.analyticsService.track('Update Subscription Plan: Failed', {
      subscription_product_uid: subscription.subscription_product_uid,
      coupon_code: upgradeCouponCode,
      payment_method: source?.type,
      reason,
    });
  };

  private handleError = ({
    errors,
    subscription,
    upgradeCouponCode,
    source,
  }: {
    errors: halError[] | Error;
    subscription: Subscription;
    upgradeCouponCode: string;
    source: stripe.Source | null;
  }) => {
    this.changeSubscriptionFormService.buttonLoading$.next(false);

    const couponErrorId = getCouponErrorId(errors);

    // Handle couponCode related halError
    if (couponErrorId) {
      this.changeSubscriptionFormService.couponCodeError$.next(couponErrorId);

      this.trackError({
        subscription,
        upgradeCouponCode,
        reason: couponErrorId,
        source,
      });
      return NEVER;
    }

    // Handle remaining halErrors
    if (Array.isArray(errors)) {
      const errorMessage = errors[0].id;

      this.errorLoggerService.displayExceptionAndLogToSentry(
        new Error(errorMessage),
      );
      this.trackError({
        subscription,
        upgradeCouponCode,
        reason: errorMessage,
        source,
      });

      return NEVER;
    }

    // Handle regular error
    this.errorLoggerService.displayExceptionAndLogToSentry(errors);
    this.trackError({
      subscription,
      upgradeCouponCode,
      reason: errors.message,
      source,
    });

    return NEVER;
  };
}
