import { Container } from 'inversify';
import { Router } from 'shared/services/Router';
import { TranslationService } from 'shared/services/TranslationsService';
import { ErrorLoggerService } from 'shared/services/ErrorLoggerService';
import { Http } from 'shared/services/HttpService';
import { UtmService } from 'shared/services/UtmService';
import {
  AnalyticsService,
  SegmentAnalyticsService,
} from 'shared/services/AnalyticsService';
import { EntryPoints } from 'shared/services/EntryPointsService';
import { UserService } from 'shared/services/UserService';
import { ErrorsService } from 'shared/services/ErrorsService';
import { ScriptService } from 'shared/services/ScriptService';
import { StripeService } from 'shared/services/StripeService';
import { SubscriptionPlansService } from 'shared/services/SubscriptionPlans';
import { RequestService } from 'shared/services/RequestService';
import { FormService } from 'shared/services/FormService';
import { SourceService } from 'shared/services/SourceService';
import { SubscriptionsService } from 'shared/services/SubscriptionsService';
import { PaymentMethodsService } from 'shared/services/PaymentMethodsService';
import { CouponService } from 'shared/services/CouponService';
import { SubscriptionTokenService } from 'shared/services/SubscriptionToken/service';
import { CouponUrlService } from 'shared/services/CouponUrlService/service';
import { CouponFormService } from 'shared/services/CouponFormService';
import { SelectedExistingMethodService } from 'shared/services/SelectedExistingMethodService';
import { SelectedBankService } from 'shared/services/SelectedBankService';
import { SepaDebitPaymentDetailsService } from 'shared/services/SepaDebitPaymentDetailsService';
import { CancelSubscriptionService } from 'shared/services/CancelSubscriptionService/service';
import { GoogleAnalytics } from 'shared/services/GoogleAnalyticsService';
import { PreferencesService } from 'shared/services/PreferencesService';
import { SubscriptionOverviewService } from 'shared/services/SubscriptionOverviewService';
import { SubscriptionStatusService } from 'shared/services/SubscriptionStatusService';
import { URLSearchParamsService } from 'shared/services/URLSearchParamsService';
import {
  ChangePaymentDetailsFormService,
  ChangePaymentDetailsSubmitService,
} from 'shared/services/ChangePaymentDetailsService';
import { NotificationService } from 'shared/services/NotificationService';
import {
  ChangeSubscriptionFormService,
  ChangeSubscriptionPlanService,
} from 'shared/services/ChangeSubscriptionPlanService';
import { CreditBundleService } from 'shared/services/CreditBundleService';
import {
  BuyCreditsFormService,
  BuyCreditsSubmitService,
} from 'shared/services/BuyCreditsService';
import { InvoiceStatusService } from 'shared/services/InvoiceStatusService';

export const bindModules = (container: Container) => {
  container.bind(Router).to(Router);
  container.bind(TranslationService).to(TranslationService);
  container.bind(ErrorLoggerService).to(ErrorLoggerService);
  container.bind(Http).to(Http);
  container.bind(UtmService).to(UtmService);
  container.bind(SegmentAnalyticsService).to(SegmentAnalyticsService);
  container.bind(AnalyticsService).to(AnalyticsService);
  container.bind(EntryPoints).to(EntryPoints);
  container.bind(UserService).to(UserService);
  container.bind(ErrorsService).to(ErrorsService);
  container.bind(ScriptService).to(ScriptService);
  container.bind(StripeService).to(StripeService);
  container.bind(SubscriptionPlansService).to(SubscriptionPlansService);
  container.bind(RequestService).to(RequestService);
  container.bind(FormService).to(FormService);
  container.bind(SourceService).to(SourceService);
  container.bind(SubscriptionsService).to(SubscriptionsService);
  container.bind(PaymentMethodsService).to(PaymentMethodsService);
  container.bind(CouponService).to(CouponService);
  container.bind(SubscriptionTokenService).to(SubscriptionTokenService);
  container.bind(CouponUrlService).to(CouponUrlService);
  container.bind(CouponFormService).to(CouponFormService);
  container
    .bind(SelectedExistingMethodService)
    .to(SelectedExistingMethodService);
  container.bind(SelectedBankService).to(SelectedBankService);
  container
    .bind(SepaDebitPaymentDetailsService)
    .to(SepaDebitPaymentDetailsService);
  container.bind(CancelSubscriptionService).to(CancelSubscriptionService);
  container.bind(GoogleAnalytics).to(GoogleAnalytics);
  container.bind(PreferencesService).to(PreferencesService);
  container.bind(SubscriptionOverviewService).to(SubscriptionOverviewService);
  container.bind(SubscriptionStatusService).to(SubscriptionStatusService);
  container.bind(URLSearchParamsService).to(URLSearchParamsService);
  container
    .bind(ChangePaymentDetailsFormService)
    .to(ChangePaymentDetailsFormService);
  container
    .bind(ChangePaymentDetailsSubmitService)
    .to(ChangePaymentDetailsSubmitService);
  container.bind(NotificationService).to(NotificationService);
  container
    .bind(ChangeSubscriptionFormService)
    .to(ChangeSubscriptionFormService);
  container
    .bind(ChangeSubscriptionPlanService)
    .to(ChangeSubscriptionPlanService);
  container.bind(CreditBundleService).to(CreditBundleService);
  container.bind(BuyCreditsFormService).to(BuyCreditsFormService);
  container.bind(BuyCreditsSubmitService).to(BuyCreditsSubmitService);
  container.bind(InvoiceStatusService).to(InvoiceStatusService);
};
