import { RouteName } from 'routes';
import { getInternalLocation } from 'utils/internalLocation';
import { compose, path, startsWith, prop } from 'ramda';
import { Router } from '../Router';
import { getEnvVariable } from 'utils';

const isOnboarding = startsWith(RouteName.Onboarding);
const isSubscriptionOverview = startsWith(RouteName.SubscriptionOverview);
const isBuyCredits = startsWith(RouteName.BuyCredits);
const routeName = path(['routeSnapshot', 'name']);
const isOnboardingRoute = compose(isOnboarding, routeName);
const isSubscriptionOverviewRoute = compose(isSubscriptionOverview, routeName);
const isBuyCreditsRoute = compose(isBuyCredits, routeName);

enum InternalLocation {
  Default = 'premium-payment',
  SubscriptionOverview = 'subscription-overview',
  BuyCreditsSettings = 'settings',
}

export const internalLocationForRouteAndEntryPoint = (
  router: Router,
  signupEntryPoint: string,
) => {
  if (isOnboardingRoute(router)) {
    return signupEntryPoint;
  }

  if (isSubscriptionOverviewRoute(router)) {
    return InternalLocation.SubscriptionOverview;
  }

  if (isBuyCreditsRoute(router)) {
    return getInternalLocation() || InternalLocation.BuyCreditsSettings;
  }

  return InternalLocation.Default;
};

export const version = getEnvVariable('REACT_APP_GIT_COMMIT');

export const trackingUid = prop('tracking_uid');
