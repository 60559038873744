import { SourceService } from 'shared/services/SourceService';
import { Metadata } from 'shared/services/SourceService/types';

export interface CreateFreeOptInSourceResponse {
  source: {
    metadata: Metadata;
    type: string;
  };
  error?: Error | undefined;
}

export function createFreeOptInSource(
  {
    type,
    metadata,
  }: {
    type: string;
    metadata: Metadata;
  },
  nextFreeOptInSource: typeof SourceService.prototype.nextFreeOptInSource,
): Promise<CreateFreeOptInSourceResponse> {
  return new Promise((resolve) => {
    const source = { metadata, type };

    nextFreeOptInSource(source);
    resolve({ source });
  });
}
