import { Observable, NEVER } from 'rxjs';
import { PaymentMethodName } from '../PaymentMethodsService';
import {
  withLatestFrom,
  switchMapTo,
  map,
  tap,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { ReactStripeElements } from 'react-stripe-elements';
import { container } from 'dependency-injection';
import { SepaDebitPaymentDetailsService } from '../SepaDebitPaymentDetailsService';
import { createSepaDebitSource } from 'utils/stripe/create-sepaDebitSource';
import { ErrorLoggerService } from '../ErrorLoggerService';
import { ChangePaymentDetailsFormService } from './formService';
import { ChangePaymentDetailsSubmitService } from './submitService';

interface Props extends ReactStripeElements.InjectedStripeProps {
  locale: string;
}

export const changePaymentSubmitSink = (props$: Observable<Props>) => {
  const { submit$, newPaymentMethod$, buttonLoading$ } = container.get(
    ChangePaymentDetailsFormService,
  );
  const { source$, newSource } = container.get(
    ChangePaymentDetailsSubmitService,
  );
  const { fullName$ } = container.get(SepaDebitPaymentDetailsService);
  const { displayExceptionAndLogToSentry } = container.get(ErrorLoggerService);

  return submit$.pipe(
    tap(() => {
      buttonLoading$.next(true);
      source$.next(null);
    }),
    withLatestFrom(props$, newPaymentMethod$, fullName$),
    switchMap(([_, { stripe }, newPaymentMethod, fullName]) => {
      if (!stripe) {
        throw new Error('Stripe object is not available');
      }

      switch (newPaymentMethod) {
        case PaymentMethodName.SepaDebit:
          if (!fullName) {
            throw new Error(
              'An account owners full name is required in the sepa debit source flow',
            );
          }

          const data = {
            type: newPaymentMethod,
            currency: 'EUR',
            fullName: fullName,
          };

          return createSepaDebitSource(stripe.createSource, data, newSource);
        default:
          throw new Error(
            `Trying to add unsupported new payment method: ${newPaymentMethod}`,
          );
      }
    }),
    map((data) => {
      if (data.error) {
        buttonLoading$.next(false);

        const error = new Error(`Stripe Error ${data.error.message}`);
        displayExceptionAndLogToSentry(error, {
          ...data.error,
          ...data,
        });
      }
    }),
    catchError((error: Error) => {
      buttonLoading$.next(false);

      displayExceptionAndLogToSentry(error);

      return NEVER;
    }),
    switchMapTo(NEVER),
  );
};
