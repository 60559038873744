import {
  toPairs,
  map,
  head,
  compose,
  fromPairs,
  filter,
  props,
  endsWith,
  anyPass,
} from 'ramda';
import { configValues } from './configValues';

type EnvironmentConfigValue = {
  production: string;
  development?: string;
  test?: string;
  local?: string;
};
type Environment = keyof EnvironmentConfigValue;

export interface ConfigValues {
  [key: string]: EnvironmentConfigValue;
}

export type OutgoingConfigValues = Record<keyof typeof configValues, string>;

export const setupConfig = (values: ConfigValues = configValues) => (
  environmentPriority: Environment[],
) => {
  const getEnvironment = compose(
    head,
    filter(Boolean),
    props(environmentPriority),
  );

  return compose(
    fromPairs,
    map(([key, value]) => [key, getEnvironment(value)]),
    toPairs,
  )(values) as OutgoingConfigValues;
};

const isNetlify = anyPass([endsWith('netlify.com'), endsWith('netlify.app')]);
const isNgrok = endsWith('ngrok.io');
export const isTestEnv = process.env.NODE_ENV === 'test';
export const isFunctionalTestEnv =
  process.env.REACT_APP_ENVIRONMENT === 'functional-test';

export function getEnvironmentPriorityFromOrigin(
  origin: string = window.location.origin,
): Environment[] {
  if (isNetlify(origin)) {
    return ['development', 'production'];
  }

  if (isNgrok(origin)) {
    return ['local', 'development', 'production'];
  }

  if (isTestEnv || isFunctionalTestEnv) {
    return ['test', 'development', 'production'];
  }

  switch (origin) {
    case 'http://localhost:3100':
    case 'https://localhost:3100':
    case 'https://127.0.0.1:3100':
    case 'https://10.0.2.2:3100':
      return ['local', 'development', 'production'];
    case 'https://payment-development.blendle.io':
    case 'https://payment.blendle-test.com':
      return ['development', 'production'];
    case 'https://payment.blendle.com':
    case 'https://payment-t1v2.blendle.com':
      return ['production'];
    default:
      throw new Error(`Unknwon origin: ${origin}`);
  }
}

export default setupConfig()(getEnvironmentPriorityFromOrigin());
