import { Container } from 'dependency-injection';
import { Observable, of as observableOf } from 'rxjs';
import { startWith, mergeMap } from 'rxjs/operators';
import { SubscriptionStatusService } from '../SubscriptionStatusService';
import { UtmService } from '../UtmService';
import { getSuccessPath } from 'utils/successPath';
import { PreferencesService } from '../PreferencesService';
import { GoogleAnalytics } from '../GoogleAnalyticsService';
import { FacebookEvent, trackFacebookEvent } from '../FacebookAnalyticsService';
import { AnalyticsService } from '../AnalyticsService';
import { ReturnTargetComponentProps } from 'shared/components/ReturnTarget/component';

export const postProcessingFactory = (
  container: Container,
  paymentAfterFreePeriodWillFail: boolean,
  isMigrationFlow: boolean,
): Observable<ReturnTargetComponentProps> => {
  const utmService = container.get(UtmService);
  const { hasActiveSubscriptionRetry$ } = container.get(
    SubscriptionStatusService,
  );
  const { removeSignupCouponCode$ } = container.get(PreferencesService);

  const subscriptionProcessed$ = hasActiveSubscriptionRetry$.pipe(
    startWith(false),
  );
  const successPath = getSuccessPath(utmService.getUtmAsUrlParamsString());

  const googleAnalytics = container.get(GoogleAnalytics);

  const whileWaitingForSubscription$ = observableOf({
    postProcessingDone: false,
    successPath,
    paymentAfterFreePeriodWillFail,
    isMigrationFlow,
  });

  const analyticsService = container.get(AnalyticsService);

  const whenSubscriptionIsProcessed$ = removeSignupCouponCode$.pipe(
    mergeMap((signupCouponCodeRemoved) => {
      trackFacebookEvent(FacebookEvent.StartTrial);
      googleAnalytics.trackOnboardingCompleted();

      if (isMigrationFlow) {
        googleAnalytics.trackWalletMigration();
        analyticsService.track('Migration Offer Redeem Success');
      }

      return observableOf({
        postProcessingDone: signupCouponCodeRemoved,
        successPath,
        paymentAfterFreePeriodWillFail,
        isMigrationFlow,
      });
    }),
  );

  return subscriptionProcessed$.pipe(
    mergeMap((subscriptionProcessed) =>
      subscriptionProcessed
        ? whenSubscriptionIsProcessed$
        : whileWaitingForSubscription$,
    ),
  );
};
