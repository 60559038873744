import { inject, injectable } from 'inversify';
import { Observable, BehaviorSubject } from 'rxjs';
import { switchMap, map, startWith } from 'rxjs/operators';
import { RequestService } from '../RequestService';
import {
  SubscriptionTokenRequestPayload,
  SubscriptionTokenResponse,
} from './types';

@injectable()
export class SubscriptionTokenService {
  readonly subscriptionToken$ = new BehaviorSubject<string | undefined>(
    undefined,
  );

  readonly hasSubscriptionToken$: Observable<boolean>;

  @inject(RequestService)
  private request: RequestService;

  constructor() {
    this.hasSubscriptionToken$ = this.subscriptionToken$.pipe(
      map(Boolean),
      startWith(false),
    );
  }

  removeSubscriptionToken = () => {
    this.subscriptionToken$.next(undefined);
  };

  fetch(payload: SubscriptionTokenRequestPayload) {
    return this.request.post<SubscriptionTokenResponse>(
      'subscription_token',
      payload,
      {},
      {
        whitelistStatusCodes: [422],
      },
    );
  }

  fetchSubscriptionToken(
    payload$: Observable<SubscriptionTokenRequestPayload>,
  ) {
    return payload$.pipe(
      switchMap((payload) =>
        this.fetch(payload).pipe(
          map(({ data }) => {
            if (data._errors) {
              throw data._errors;
            }

            this.subscriptionToken$.next(data.subscription_token);
          }),
        ),
      ),
    );
  }
}
