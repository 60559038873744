import { Observable, NEVER } from 'rxjs';
import { Bank, PaymentMethodName } from '../PaymentMethodsService';
import { ReactStripeElements } from 'react-stripe-elements';
import { BuyCreditsFormService } from './formService';
import { Container } from 'dependency-injection';
import {
  tap,
  withLatestFrom,
  switchMap,
  switchMapTo,
  catchError,
  map,
} from 'rxjs/operators';
import { ErrorLoggerService } from '../ErrorLoggerService';
import { CreditBundleService } from '../CreditBundleService';
import { UserService } from '../UserService';
import { filterEmpty } from 'utils';
import { BuyCreditsSubmitService } from './submitService';
import { getReturnPath, getRedirectUrl } from 'utils/redirectUrl';
import { BuyCreditsChildRouteName } from 'routes';
import { Router } from '../Router';
import { UtmService } from '../UtmService';

interface ExpectedProps {
  stripe: ReactStripeElements.StripeProps | null;
}

export const buyCreditsFormSubmitSink = (
  props$: Observable<ExpectedProps>,
  selectedBank$: Observable<Bank>,
  container: Container,
) => {
  const { submit$, buttonLoading$ } = container.get(BuyCreditsFormService);
  const { displayExceptionAndLogToSentry } = container.get(ErrorLoggerService);
  const { selectedCreditBundleWithDefault$ } = container.get(
    CreditBundleService,
  );
  const { user$ } = container.get(UserService);
  const { submitBuyCreditsRequest } = container.get(BuyCreditsSubmitService);
  const routerService = container.get(Router);
  const utmService = container.get(UtmService);

  return submit$.pipe(
    tap(() => {
      buttonLoading$.next(true);
    }),
    withLatestFrom(
      props$,
      selectedBank$,
      selectedCreditBundleWithDefault$,
      user$.pipe(filterEmpty),
    ),
    map(([_, props, selectedBank, selectedCreditBundleWithDefault, user]) => ({
      ...props,
      selectedBank,
      creditBundle: selectedCreditBundleWithDefault,
      user,
    })),
    switchMap(({ stripe, selectedBank, creditBundle, user }) => {
      if (!stripe) {
        throw new Error('Stripe object is not available');
      }

      const { full_name, country, email } = user;

      return submitBuyCreditsRequest({
        creditBundleId: creditBundle.id,
        fullName: full_name,
        country,
        email,
      }).pipe(
        map(({ payment_intent_client_secret, invoice_id }) => ({
          paymentIntentClientSecret: payment_intent_client_secret,
          invoiceId: invoice_id,
          stripe,
          selectedBank,
          fullName: full_name,
          creditBundle,
        })),
      );
    }),
    map(
      ({
        paymentIntentClientSecret,
        invoiceId,
        stripe,
        selectedBank,
        fullName,
        creditBundle,
      }) => {
        const returnPath = getReturnPath({
          currentRoute: routerService.routeSnapshot.name,
          returnChildRoute: BuyCreditsChildRouteName.Return,
          buildPath: routerService.router.buildPath,
        });

        // numberOfCredits, invoiceId, bank, paymentMethod and creditBundleId are included so
        // that they can be used in analytics events on the return page and the successPath
        const redirectUrl = getRedirectUrl({
          utmAsUrlParams: utmService.getUtmAsUrlParamsString(),
          returnPath,
          invoiceId,
          bank: selectedBank,
          paymentMethod: PaymentMethodName.IDeal,
          numberOfCredits: creditBundle.quantity,
        });

        // TODO fix Stripe Types
        // tslint:disable-next-line: no-any
        return (stripe as any).confirmIdealPayment(paymentIntentClientSecret, {
          payment_method: {
            ideal: {
              bank: selectedBank,
            },
            billing_details: {
              name: fullName,
            },
          },
          return_url: redirectUrl,
        });
      },
    ),
    catchError((error: Error) => {
      buttonLoading$.next(false);

      displayExceptionAndLogToSentry(error);

      return NEVER;
    }),
    switchMapTo(NEVER),
  );
};
