import React from 'react';
import styled from 'styled-components/macro';
import { ResizeSpinLoader } from '../Loader';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ApplicationLoader = () => (
  <Wrapper>
    <ResizeSpinLoader />
  </Wrapper>
);

export default ApplicationLoader;
