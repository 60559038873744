export const configValues = {
  analyticsUri: {
    development: 'https://events-staging.blendle.io/v1/events',
    production: 'https://events.blendle.com/v1/events',
    test: 'https://localhost:5001/v1/events',
  },
  apiJSON: {
    production: 'https://static.blendle.com/api.json',
    development: 'https://static.blendle.com/api_development.json',
    test: `https://localhost:3029/api.json`,
  },
  helpDeskUrl: {
    production: 'https://www.blendle.support/',
  },
  paymentApiJSON: {
    production: 'https://stripe.blendle.com/',
    development: 'https://stripe-development.blendle.io/',
    test: 'https://localhost:3029/stripe.json',
  },
  redirectDomain: {
    production: 'https://blendle.com',
    development: 'https://development.blendle.com',
    local: 'http://localhost:9000',
    test: 'https://localhost:5001',
  },
  redirectSuccessPath: {
    production: '/home/success',
  },
  buyCreditsRedirectSuccessPath: {
    production: '/kiosk/issue?didCreditsTopup=true',
  },
  sentryProjectUri: {
    production: 'https://d60f374c306640669b484ab994e3f049@sentry.io/221734',
    development: 'https://96e19ee27dff4629b7db8a31727291b2@sentry.io/221582',
    test: 'https://localhost:5001/sentry',
  },
  stripePublishableKey: {
    production: 'pk_live_8xinl1jmv6GZF0F680jDA2nA',
    development: 'pk_test_GOYpn3v29byxr0tYdBBGmery',
    test: 'pk_test_GOYpn3v29byxr0tYdBBGmery',
  },
  gaTrackingId: {
    production: 'UA-90032766-6',
    development: 'UA-90032766-4',
  },
  providerStylesUri: {
    production: 'https://provider-assets.blendlecdn.com/styles/providers.css',
  },
  facebookTrackingId: {
    production: '742491399133724',
  },
  environmentName: {
    production: 'production',
    development: 'development',
    local: 'local',
    test: 'test',
  },
  segmentEnvironmentName: {
    production: 'production',
    development: 'development',
    local: 'development',
    test: 'test',
  },
  cancellationFlowUpgradeCoupon: {
    production: 'EB28A4CBD2C6',
    development: '6E7AC9A6CF4A',
  },
};
