import { inject, injectable } from 'inversify';
import { BehaviorSubject } from 'rxjs';
import { Http } from '../HttpService';
import { map } from 'rxjs/operators';

const analyticsMap = new Map<string, string>([['nl-NL', 'nl_NL']]);

type Translations = Record<string, string>;

@injectable()
export class TranslationService {
  readonly locale$ = new BehaviorSubject('nl-NL');

  analyticsLocationName$ = this.locale$.pipe(
    map((locale) => analyticsMap.get(locale)),
  );

  @inject(Http)
  private readonly http: Http;

  getTranslationFile(language: string) {
    this.locale$.next(language);
    return this.http
      .get<Translations>(`/translations/${language}.json`)
      .pipe(map((res) => res.data));
  }
}
