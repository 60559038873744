import { container } from 'dependency-injection';
import { postConstruct, injectable } from 'inversify';
import {
  defer as observableDefer,
  Observable,
  of as observableOf,
  timer as observableTimer,
} from 'rxjs';
import {
  delayWhen,
  mergeMap,
  retryWhen,
  scan,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';

export interface HttpRequestOptions extends RequestInit {
  retries?: number;
  timeFactor?: number;
  // only 2XX status codes will emit a next value. Number defined here will also emit a next value
  whitelistStatusCodes?: number[];
}

type fetchResponse<T> = Observable<{ response: Response; data: T }>;

@injectable()
export class Http {
  // tslint:disable-next-line:no-any
  private errorLoggerService: any;

  post<T>(
    uri: string,
    httpRequestOptions: HttpRequestOptions = {},
  ): fetchResponse<T> {
    return this.fetch<T>('post', uri, httpRequestOptions);
  }

  get<T>(
    uri: string,
    httpRequestOptions: HttpRequestOptions = {},
  ): fetchResponse<T> {
    return this.fetch<T>('get', uri, httpRequestOptions);
  }

  put<T>(
    uri: string,
    httpRequestOptions: HttpRequestOptions = {},
  ): fetchResponse<T> {
    return this.fetch<T>('put', uri, httpRequestOptions);
  }

  @postConstruct()
  async postConstruct() {
    const { ErrorLoggerService } = await import('../ErrorLoggerService');
    this.errorLoggerService = container.get(ErrorLoggerService);
  }

  private fetch<T>(
    method: string,
    uri: string,
    httpRequestOptions: HttpRequestOptions = {},
  ): fetchResponse<T> {
    const {
      retries = 10,
      timeFactor = 250,
      whitelistStatusCodes = [],
      ...fetchOptions
    } = httpRequestOptions;

    return observableOf(uri).pipe(
      mergeMap((url) =>
        observableDefer(() =>
          fetch(url, {
            method,
            ...fetchOptions,
          }),
        ),
      ),
      retryWhen((errors$) =>
        errors$.pipe(
          tap((e) => {
            this.errorLoggerService.sendExceptionToSentry(
              new Error(`Retrying, because request to ${uri} failed`),
              {
                tags: {
                  endpoint: uri,
                  message: e.message,
                  method: method,
                },
              },
            );
          }),
          scan((errorCount, err) => errorCount + 1, 0),
          takeWhile((errorCount) => errorCount < retries),
          delayWhen((errorCount) =>
            observableTimer(errorCount ** 2 * timeFactor),
          ),
        ),
      ),
      switchMap(async (response) => {
        const isWhitelistStatusCode = whitelistStatusCodes.some(
          (validStatusCode) => response.status === validStatusCode,
        );
        if (
          (response.status >= 200 && response.status < 400) ||
          isWhitelistStatusCode
        ) {
          const data: T = await (async () => {
            const contentTypeComplete = response.headers.get('content-type');
            // Content Type may contain 'application/json; charset=UTF-8'
            const contentType =
              contentTypeComplete && contentTypeComplete.split(';')[0];

            switch (contentType) {
              case 'application/hal+json':
              case 'application/json':
                return await response.json();
              case 'text/html':
                // The subscription service can't alwasy control the response type
                // In some cases the body contains json but the type is `text/html`
                try {
                  return await response.json();
                } catch (e) {
                  return await response.text();
                }
              case 'plain/text':
                return await response.text();
              case null:
                return null;

              default:
                throw new Error(
                  `Unsupported Response content-type ${response.headers.get(
                    'content-type',
                  )}`,
                );
            }
          })();

          return { response, data };
        }

        if (response.status >= 500) {
          this.logRequestError(uri, response.status);
        }

        const error = new Error(
          `Request to ${uri} errored after retry with status ${response.status}`,
        );

        Object.assign(error, response); // merge response data to error object to have access to error details

        throw error;
      }),
    );
  }

  private logRequestError(uri: string, status: number) {
    if (this.errorLoggerService && this.errorLoggerService.captureMessage) {
      this.errorLoggerService.sendExceptionToSentry(
        new Error(`${status} Error, request to ${uri} failed`),
        {
          tags: {
            endpoint: uri,
            httpStatus: status,
          },
        },
      );
    }
  }
}
