import { NotificationTitle, Rows } from '@blendle/lego';
import styled from 'styled-components/macro';
import { fontSize } from 'shared/styled-helpers';

export const StyledRows = styled(Rows)`
  align-self: center;
`;

export const Title = styled(NotificationTitle)`
  font-size: ${fontSize('large')};
  margin-top: 5px;
`;
