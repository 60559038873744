import { injectable } from 'inversify';
import { IncomingSubscription, Subscription } from './types';
import { RequestService } from '../RequestService';
import { map, first, mergeMap } from 'rxjs/operators';
import { filterEmpty } from 'utils';
import { UserService } from '../UserService';
import {
  parseIncomingSubscriptionDates,
  SubscriptionOverviewError,
  isNotFoundError,
  canPlanBeUpgraded,
} from './helpers';
import { has } from 'ramda';
import { BehaviorSubject } from 'rxjs';
import { isFunctionalTestEnv } from 'environment-config';
import { includeCounterToRequestOptionsIfFunctionalTest } from 'utils/functionalTest';

const hasErrors = has('_errors');

@injectable()
export class SubscriptionOverviewService {
  readonly user$ = this.userService.user$.pipe(filterEmpty, first());

  readonly subscription$ = new BehaviorSubject<Subscription | undefined>(
    undefined,
  );

  public functionalTestCounter: number;

  setSubscription = (subscription: Subscription) => {
    this.subscription$.next(subscription);
  };

  clearSubscription = () => {
    this.subscription$.next(undefined);
  };

  fetchSubscription = () => {
    const options = includeCounterToRequestOptionsIfFunctionalTest(
      isFunctionalTestEnv,
      this.functionalTestCounter,
      {
        whitelistStatusCodes: [404],
      },
    );

    return this.user$.pipe(
      mergeMap((user) =>
        this.request
          .get<IncomingSubscription>(
            'user_subscription_overview',
            {
              user_uid: user.id,
            },
            options,
          )
          .pipe(
            map(({ data }) => {
              this.functionalTestCounter = this.functionalTestCounter + 1;

              if (hasErrors(data)) {
                if (isNotFoundError(data._errors!)) {
                  throw new Error(
                    SubscriptionOverviewError.UserHasNoSubscription,
                  );
                }

                throw new Error(`Unknown error, ${JSON.stringify(data)}`);
              }

              const subscription = parseIncomingSubscriptionDates(data);

              subscription.plan_can_be_upgraded = canPlanBeUpgraded(
                subscription,
              );

              this.setSubscription(subscription);

              return subscription;
            }),
          ),
      ),
    );
  };

  constructor(
    private readonly request: RequestService,
    private userService: UserService,
  ) {
    this.functionalTestCounter = 0;
  }
}
