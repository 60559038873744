import React from 'react';

interface Props {
  className?: string;
}

export const HighFiveIcon: React.FunctionComponent<Props> = ({ className }) => (
  <svg
    width="1500px"
    height="1401px"
    viewBox="0 0 1500 1401"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group">
        <path
          d="M1331.9,277.2 C1333.4,272.2 1335.9,272.2 1337.5,277.2 L1340.3,286.2 C1341.8,291.2 1347.2,296.5 1352.1,298 L1361.1,300.8 C1366.1,302.3 1366.1,304.8 1361.1,306.4 L1352.1,309.2 C1347.1,310.7 1341.8,316.1 1340.3,321 L1337.5,330 C1336,335 1333.5,335 1331.9,330 L1329.1,321 C1327.6,316 1322.2,310.7 1317.3,309.2 L1308.3,306.4 C1303.3,304.9 1303.3,302.4 1308.3,300.8 L1317.3,298 C1322.3,296.5 1327.6,291.1 1329.1,286.2 L1331.9,277.2 Z"
          id="Shape"
          fill="#43D6E0"
        />
        <path
          d="M173.6,299.6 C178.2,297.2 180,298.9 177.5,303.5 L173.1,311.9 C170.7,316.5 170.7,324 173.1,328.6 L177.5,337 C179.9,341.6 178.2,343.4 173.6,340.9 L165.2,336.5 C160.6,334.1 153.1,334.1 148.5,336.5 L140.1,340.9 C135.5,343.3 133.7,341.6 136.2,337 L140.6,328.6 C143,324 143,316.5 140.6,311.9 L136.2,303.5 C133.8,298.9 135.5,297.1 140.1,299.6 L148.5,304 C153.1,306.4 160.6,306.4 165.2,304 L173.6,299.6 Z"
          id="Shape"
          fill="#4A5170"
        />
        <path
          d="M978.3,1281.9 C980.9,1273.3 985.3,1273.3 987.9,1281.9 L992.7,1297.5 C995.4,1306.1 1004.6,1315.3 1013.2,1318 L1028.8,1322.8 C1037.4,1325.5 1037.4,1329.8 1028.8,1332.4 L1013.2,1337.2 C1004.6,1339.8 995.4,1349.1 992.7,1357.7 L987.9,1373.3 C985.2,1381.9 980.9,1381.9 978.3,1373.3 L973.5,1357.7 C970.8,1349.1 961.6,1339.9 953,1337.2 L937.4,1332.4 C928.8,1329.7 928.8,1325.4 937.4,1322.8 L953,1318 C961.6,1315.3 970.8,1306.1 973.5,1297.5 L978.3,1281.9 Z"
          id="Shape"
          fill="#43D6E0"
        />
        <path
          d="M732,66.4 C741.9,48.8 758.1,48.8 768,66.4 L870.8,249.1 C880.7,266.7 903.8,274.2 922.1,265.8 L1112.7,178.4 C1131,170 1144.1,179.5 1141.8,199.6 L1117.6,407.8 C1115.3,427.8 1129.6,447.5 1149.3,451.5 L1354.8,492.9 C1374.6,496.9 1379.6,512.3 1365.9,527.1 L1223.9,681.4 C1210.2,696.2 1210.2,720.5 1223.9,735.4 L1365.9,889.7 C1379.6,904.5 1374.6,919.9 1354.8,923.9 L1149.3,965.3 C1129.5,969.3 1115.2,988.9 1117.6,1009 L1141.8,1217.2 C1144.1,1237.2 1131,1246.8 1112.7,1238.4 L922.1,1151 C903.8,1142.6 880.6,1150.1 870.8,1167.7 L768,1350.3 C758.1,1367.9 741.9,1367.9 732,1350.3 L629.2,1167.6 C619.3,1150 596.2,1142.5 577.9,1150.9 L387.3,1238.3 C369,1246.7 355.9,1237.2 358.2,1217.1 L382.4,1008.9 C384.7,988.9 370.4,969.2 350.7,965.2 L145,923.8 C125.2,919.8 120.2,904.4 133.9,889.6 L275.9,735.3 C289.6,720.4 289.6,696.2 275.9,681.3 L133.9,527 C120.2,512.2 125.2,496.8 145,492.8 L350.5,451.4 C370.3,447.4 384.6,427.8 382.2,407.7 L358,199.5 C355.7,179.5 368.8,169.9 387.1,178.3 L577.7,265.7 C596,274.1 619.1,266.6 629,249 L732,66.4 Z"
          id="Shape"
          fill="#43D6E0"
        />
        <path
          d="M691.3,941.4 C685.4,930.9 671.5,926.3 660.5,931.4 L571.7,972.1 C560.7,977.1 552.8,971.4 554.2,959.4 L565.5,862.4 C566.9,850.4 558.3,838.6 546.5,836.2 L450.7,816.9 C438.8,814.5 435.8,805.3 444,796.4 L510.1,724.5 C518.3,715.6 518.3,701 510.1,692.1 L444,620.3 C435.8,611.4 438.8,602.1 450.7,599.8 L546.5,580.5 C558.4,578.1 566.9,566.3 565.5,554.3 L554.2,457.3 C552.8,445.3 560.7,439.6 571.7,444.6 L660.5,485.3 C671.5,490.3 685.4,485.8 691.3,475.3 L739.2,390.2 C745.1,379.7 754.9,379.7 760.8,390.2 L808.7,475.3 C814.6,485.8 828.5,490.4 839.5,485.3 L928.3,444.6 C939.3,439.6 947.2,445.3 945.8,457.3 L934.5,554.3 C933.1,566.3 941.7,578.1 953.5,580.5 L1049.2,599.8 C1061.1,602.2 1064.1,611.4 1055.9,620.3 L989.8,692.2 C981.6,701.1 981.6,715.7 989.8,724.6 L1055.9,796.5 C1064.1,805.4 1061.1,814.6 1049.2,817 L953.5,836.3 C941.6,838.7 933.1,850.5 934.5,862.5 L945.8,959.5 C947.2,971.5 939.3,977.2 928.3,972.2 L839.5,931.5 C828.5,926.5 814.6,931 808.7,941.5 L760.8,1026.6 C754.9,1037.2 745.1,1037.2 739.2,1026.6 L691.3,941.4 Z"
          id="Shape"
          fill="#4A5170"
        />
        <circle id="Oval" fill="#43D6E0" cx="25.9" cy="838.9" r="25.2" />
        <circle id="Oval" fill="#4A5170" cx="1474.1" cy="838.9" r="25.2" />
        <circle id="Oval" fill="#4A5170" cx="539.3" cy="1327.6" r="25.1" />
        <circle id="Oval" fill="#4A5170" cx="750" cy="1128.6" r="25.1" />
        <path
          d="M534.4,7.4 C537.1,-1.2 541.4,-1.2 544,7.4 L548.8,23 C551.5,31.6 560.7,40.8 569.3,43.5 L584.9,48.3 C593.5,51 593.5,55.3 584.9,57.9 L569.3,62.7 C560.7,65.4 551.5,74.6 548.8,83.2 L544,98.8 C541.3,107.4 537,107.4 534.4,98.8 L529.6,83.2 C526.9,74.6 517.7,65.4 509.1,62.7 L493.5,57.9 C484.9,55.2 484.9,50.9 493.5,48.3 L509.1,43.5 C517.7,40.8 526.9,31.6 529.6,23 L534.4,7.4 Z"
          id="Shape"
          fill="#43D6E0"
        />
        <path
          d="M985.7,81.9 C984.3,83.3 981.9,83.3 980.5,81.9 L954.4,55.8 C953,54.4 953,52 954.4,50.6 L980.5,24.5 C981.9,23.1 984.3,23.1 985.7,24.5 L1011.8,50.6 C1013.2,52 1013.2,54.4 1011.8,55.8 L985.7,81.9 Z"
          id="Shape"
          fill="#4A5170"
        />
        <path
          d="M766.7,207.8 C771.3,205.4 773.1,207.1 770.6,211.7 L766.2,220.1 C763.8,224.7 763.8,232.2 766.2,236.8 L770.6,245.2 C773,249.8 771.3,251.6 766.7,249.1 L758.3,244.7 C753.7,242.3 746.2,242.3 741.6,244.7 L733.2,249.1 C728.6,251.5 726.8,249.8 729.3,245.2 L733.7,236.8 C736.1,232.2 736.1,224.7 733.7,220.1 L729.3,211.7 C726.9,207.1 728.6,205.3 733.2,207.8 L741.6,212.2 C746.2,214.6 753.7,214.6 758.3,212.2 L766.7,207.8 Z"
          id="Shape"
          fill="#4A5170"
        />
        <g transform="translate(77.000000, 349.000000)" id="Shape">
          <path
            d="M572.2,401.5 L554.5,335.3 L540.9,284.5 L477.1,46.5 C469,16.2 437.8,-1.8 407.5,6.3 C377.2,14.4 359.2,45.6 367.3,75.9 L358.3,42.2 C350.2,11.9 319,-6.1 288.7,2 C258.4,10.1 240.4,41.3 248.5,71.6 L257.5,105.3 C249.4,75 218.2,57 187.9,65.1 C157.6,73.2 139.6,104.4 147.7,134.7 L213.9,381.7 C220.3,405.6 200.8,401.4 200.8,401.4 L200.8,401.4 L153.1,393.4 C117.8,387.2 84.2,410.7 78,446 C71.8,481.2 95.3,514.9 130.6,521.1 L238,539.7 C238,539.7 243.6,540.3 239.7,546 L239.7,546 L22.6,856 C-16.8,912.3 -3.1,989.9 53.2,1029.3 C109.5,1068.7 187.1,1055 226.5,998.7 L531.5,563.1 C600.9,476.4 572.2,401.5 572.2,401.5 Z"
            fill="#FFD338"
          />
          <path
            d="M296.8,295.3 C302.7,293.7 306.2,287.7 304.6,281.8 L257.4,105.5 C252.7,87.9 240.1,74.4 224.5,67.9 L283.4,287.6 C284.9,293.3 291,296.8 296.8,295.3 Z"
            fill="#FF991F"
          />
          <path
            d="M367,75 L414.5,252.3 C416.1,258.2 422.1,261.7 428,260.1 C433.9,258.5 437.4,252.5 435.8,246.6 L376.9,26.9 C366.9,40.2 362.7,57.7 367,75 Z"
            fill="#FF991F"
          />
        </g>
        <g transform="translate(844.000000, 345.000000)" id="Shape">
          <path
            d="M6.8,401.7 L24.5,335.5 L38.1,284.7 L101.9,46.7 C110,16.4 141.2,-1.6 171.5,6.5 C201.8,14.6 219.8,45.8 211.7,76.1 L220.7,42.4 C228.8,12.1 260,-5.9 290.3,2.2 C320.6,10.3 338.6,41.5 330.5,71.8 L321.5,105.5 C329.6,75.2 360.8,57.2 391.1,65.3 C421.4,73.4 439.4,104.6 431.3,134.9 L365.1,381.9 C358.7,405.8 378.2,401.6 378.2,401.6 L378.2,401.6 L425.9,393.6 C461.2,387.4 494.8,410.9 501,446.2 C507.2,481.4 483.7,515.1 448.4,521.3 L341,539.9 C341,539.9 335.4,540.5 339.3,546.2 L339.3,546.2 L556.4,856.3 C595.8,912.6 582.1,990.2 525.8,1029.6 C469.5,1069 391.9,1055.3 352.5,999 L47.5,563.4 C-21.9,476.5 6.8,401.7 6.8,401.7 Z"
            fill="#FFD338"
          />
          <path
            d="M318.3,308.9 L112.8,253.8 C106.9,252.2 103.4,246.2 105,240.3 C106.6,234.4 112.6,230.9 118.5,232.5 L324,287.6 C329.9,289.2 333.4,295.2 331.8,301.1 C330.2,307 324.2,310.5 318.3,308.9 Z"
            fill="#FF991F"
          />
          <path
            d="M146.5,480.2 C140.6,478.6 137.1,472.6 138.7,466.7 C179.9,313.1 310.4,327 311.7,327.1 C317.7,327.8 322,333.3 321.3,339.3 C320.6,345.3 315.1,349.6 309.1,348.9 C304.4,348.4 196,337.6 159.9,472.3 C158.4,478.3 152.4,481.8 146.5,480.2 Z"
            fill="#FF991F"
          />
          <path
            d="M321.9,104.6 L274.4,281.9 C272.8,287.8 276.3,293.8 282.2,295.4 C288.1,297 294.1,293.5 295.7,287.6 L354.6,67.9 C339.1,74.4 326.8,87.5 321.9,104.6 Z"
            fill="#FF991F"
          />
          <path
            d="M150.4,260.1 C156.3,261.7 162.3,258.2 163.9,252.3 L211.2,76 C215.9,58.4 211.8,40.5 201.5,27 L142.6,246.7 C141.1,252.5 144.6,258.5 150.4,260.1 Z"
            fill="#FF991F"
          />
        </g>
      </g>
    </g>
  </svg>
);
