import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BaseNotification } from '../index';
import { HighFiveIcon } from '../../Icons/HighFiveIcon';

export const ChangePaymentDetailsSuccessNotification = () => (
  <BaseNotification
    title={<FormattedMessage id="notification.change-payment-details.title" />}
    body={<FormattedMessage id="notification.change-payment-details.body" />}
    icon={<HighFiveIcon />}
  />
);
