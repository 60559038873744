import { injectable } from 'inversify';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Bank } from '../PaymentMethodsService';

@injectable()
export class SelectedBankService {
  readonly selectedBank$: Observable<Bank>;
  readonly hasSelectedBank$: Observable<boolean>;

  private readonly bankSelection$ = new Subject<Bank>();

  constructor() {
    this.selectedBank$ = this.bankSelection$.asObservable();
    this.hasSelectedBank$ = this.selectedBank$.pipe(
      map(Boolean),
      startWith(false),
    );
  }

  selectBank = (value: Bank) => {
    this.bankSelection$.next(value);
  };
}
