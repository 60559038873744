import { Container } from 'dependency-injection';
import {
  merge as observableMerge,
  NEVER,
  Observable,
  combineLatest,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMapTo,
  tap,
} from 'rxjs/operators';
import { AnalyticsService } from '../AnalyticsService';
import { SourceService } from '../SourceService';
import {
  subscriptionProductUid,
  SubscriptionPlansService,
  displayId,
} from '../SubscriptionPlans';
import { getCouponCodeFromSubscriptionToken } from './helper';
import { anyPass, equals } from 'ramda';
import { SubscriptionTokenService } from '../SubscriptionToken/service';
import { Router } from '../Router';

enum Status {
  Chargeable = 'chargeable',
  Consumed = 'consumed',
  Canceled = 'canceled',
  Failed = 'failed',
}

const isSuccessful = anyPass([
  equals(Status.Consumed),
  equals(Status.Chargeable),
]);

const isUnsuccessful = anyPass([
  equals(Status.Canceled),
  equals(Status.Failed),
]);

const PROVIDER_ID = 'blendlepremium';

export const analyticsFactory = (container: Container): Observable<null> => {
  const analyticsService = container.get(AnalyticsService);
  const sourceService = container.get(SourceService);
  const subscriptionTokenService = container.get(SubscriptionTokenService);
  const router = container.get(Router);
  const subscriptionPlansService = container.get(SubscriptionPlansService);

  const sourcePayload$ = sourceService.source$.pipe(
    filter((source) => !!source),
    distinctUntilChanged((x, y) => {
      return x!.id === y!.id && x!.status === y!.status;
    }),
    map((source) => ({
      status: source!.status as Status,
      payload: {
        payment_method: source?.type,
        bank: source?.ideal?.bank,
      },
    })),
  );

  const paymentMethod$ = sourceService.paymentMethod$.pipe(
    filter((paymentMethod) => !!paymentMethod),
    map((paymentMethod) => ({
      status: Status.Chargeable,
      payload: {
        payment_method: paymentMethod!.type,
      },
    })),
  );

  const existingSourcePayload$ = sourceService.existingSource$.pipe(
    filter((existingSource) => !!existingSource),
    map((existingSource) => ({
      status: Status.Chargeable,
      payload: {
        payment_method: existingSource!.type,
        source_id: existingSource!.sourceId,
      },
    })),
  );

  const existingPaymentMethodPayload$ = sourceService.existingPaymentMethod$.pipe(
    filter((existingPaymentMethod) => !!existingPaymentMethod),
    map((existingPaymentMethod) => ({
      status: Status.Chargeable,
      payload: {
        payment_method: existingPaymentMethod!.type,
        payment_method_id: existingPaymentMethod!.id,
      },
    })),
  );

  const freeOptInSourcePayload$ = sourceService.freeOptInSource$.pipe(
    filter((freeOptInSource) => !!freeOptInSource),
    map((freeOptInSource) => ({
      status: Status.Chargeable,
      payload: {
        payment_method: freeOptInSource!.type,
      },
    })),
  );

  const sharedPayload$ = combineLatest([
    subscriptionTokenService.subscriptionToken$,
    subscriptionPlansService.selectedPlanWithDefault$,
  ]).pipe(
    map(([subscriptionToken, subscriptionPlan]) => {
      const token =
        router.routeSnapshot.params.subscription_token || subscriptionToken;

      if (!token) {
        throw new Error('SubscriptionToken is required in AnalyticsFactory');
      }

      const productId =
        router.routeSnapshot.params.product_id || displayId(subscriptionPlan);

      if (!productId) {
        throw new Error('productId is required in AnalyticsFactory');
      }

      const couponCode = getCouponCodeFromSubscriptionToken(token);

      return {
        sharedPayload: {
          provider_id: PROVIDER_ID,
          subscription_product_uid: subscriptionProductUid(productId),
          ...(couponCode ? { coupon_code: couponCode } : null),
        },
      };
    }),
  );

  const sourceOrPaymentMethodPayload$ = observableMerge(
    sourcePayload$,
    paymentMethod$,
    existingSourcePayload$,
    existingPaymentMethodPayload$,
    freeOptInSourcePayload$,
  );

  return combineLatest([sourceOrPaymentMethodPayload$, sharedPayload$]).pipe(
    tap(([{ status, payload }, { sharedPayload }]) => {
      const combinedPayload = {
        ...payload,
        ...sharedPayload,
      };
      if (isSuccessful(status)) {
        analyticsService.track('Payment Result:success', combinedPayload);
        analyticsService.track('Subscription Started', combinedPayload);
      } else if (isUnsuccessful(status)) {
        analyticsService.track(`Payment Result:${status}`, combinedPayload);
      }
    }),
    mergeMapTo(NEVER),
  );
};
