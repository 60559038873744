import {
  ExistingSource,
  ExistingPaymentMethod,
} from '../PaymentMethodsService/types';
import { halError } from 'utils/errors';

export enum SubscriptionType {
  External = 'external',
  Trial = 'trial',
  Regular = 'regular',
}

export enum Discount {
  None = 'none',
  Partial = 'partial',
  Full = 'full',
}

export enum Issuer {
  Apple = 'apple',
  Vodafone = 'vodafone',
  Blendle = 'blendle',
  Stripe = 'stripe',
}

export enum Interval {
  Month = 'month',
  Year = 'year',
}

export interface IncomingSubscription {
  issuer?: Issuer;
  subscription_product_uid?: string;
  can_be_cancelled_within_blendle?: boolean;
  type?: SubscriptionType;
  discount?: Discount;
  current_period_end_date?: string;
  end_date_when_cancelled?: string;
  cancel_at_period_end?: boolean;
  interval?: Interval;
  price_per_interval?: number;
  upcoming_period?: UpcomingPeriod;
  payment_method?: ExistingSource | ExistingPaymentMethod;
  stripe_subscription_id?: string;
  _errors?: halError[] | Error;
}

export interface Base {
  issuer: Issuer;
  subscription_product_uid: string;
  can_be_cancelled_within_blendle: boolean;
  plan_can_be_upgraded?: boolean;
}

export interface ExternalSubscription extends Base {
  type: SubscriptionType.External;
}

export interface FreeloaderSubscription extends Base {
  type: SubscriptionType.Regular;
  discount: Discount.Full;
  current_period_end_date: null | undefined;
  cancel_at_period_end: boolean;
  end_date_when_cancelled: Date;
  stripe_subscription_id?: string;
  interval?: Interval.Month;
  price_per_interval?: 0;
}

export interface UpcomingPeriod {
  interval: Interval;
  price_per_interval: number;
}

export interface TrialSubscription extends Base {
  type: SubscriptionType.Trial;
  current_period_end_date: Date;
  end_date_when_cancelled: Date;
  cancel_at_period_end: boolean;
  upcoming_period?: UpcomingPeriod;
  payment_method?: ExistingSource;
  stripe_subscription_id?: string;
}

export interface FullDiscountCouponSubscription extends Base {
  type: SubscriptionType.Regular;
  discount: Discount.Full;
  current_period_end_date: Date;
  end_date_when_cancelled: Date;
  cancel_at_period_end: boolean;
  interval?: Interval.Month;
  price_per_interval?: 0;
  upcoming_period?: UpcomingPeriod;
  payment_method?: ExistingSource;
  stripe_subscription_id?: string;
}

export interface PartialDiscountCouponSubscription extends Base {
  type: SubscriptionType.Regular;
  discount: Discount.Partial;
  current_period_end_date: Date;
  end_date_when_cancelled: Date;
  cancel_at_period_end: boolean;
  interval: Interval;
  price_per_interval: number;
  upcoming_period: UpcomingPeriod;
  payment_method: ExistingSource;
  stripe_subscription_id?: string;
}

export interface RegularPaidSubscription extends Base {
  type: SubscriptionType.Regular;
  discount: Discount.None;
  current_period_end_date: Date;
  end_date_when_cancelled: Date;
  cancel_at_period_end: boolean;
  interval?: Interval;
  price_per_interval?: number;
  upcoming_period?: UpcomingPeriod;
  payment_method?: ExistingSource;
  stripe_subscription_id?: string;
}

export type Subscription =
  | ExternalSubscription
  | TrialSubscription
  | RegularPaidSubscription
  | FullDiscountCouponSubscription
  | PartialDiscountCouponSubscription
  | FreeloaderSubscription;
