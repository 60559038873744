import { Observable, NEVER, of } from 'rxjs';
import { PaymentMethodName } from '../PaymentMethodsService';
import {
  withLatestFrom,
  switchMapTo,
  map,
  tap,
  switchMap,
  take,
  catchError,
} from 'rxjs/operators';
import { ReactStripeElements } from 'react-stripe-elements';
import { container, Container } from 'dependency-injection';
import { SepaDebitPaymentDetailsService } from '../SepaDebitPaymentDetailsService';
import { createSepaDebitSource } from 'utils/stripe/create-sepaDebitSource';
import { ErrorLoggerService } from '../ErrorLoggerService';
import { ChangeSubscriptionFormService } from './formService';
import { ChangeSubscriptionPlanService } from './submitService';
import { SubscriptionOverviewService } from '../SubscriptionOverviewService';
import { filterEmpty } from 'utils';
import { hasPaymentMethod } from './helpers';

interface Props {
  stripe: ReactStripeElements.StripeProps | null;
}

export const submitSinkFactory = (container: Container) => (
  props$: Observable<Props>,
) => {
  const { submit$, buttonLoading$ } = container.get(
    ChangeSubscriptionFormService,
  );
  const { subscription$ } = container.get(SubscriptionOverviewService);
  const {
    source$,
    newSource,
    readyToSubmitUpgrade$,
    markAsReadyToSubmitUpgrade,
  } = container.get(ChangeSubscriptionPlanService);
  const { fullName$ } = container.get(SepaDebitPaymentDetailsService);
  const { displayExceptionAndLogToSentry } = container.get(ErrorLoggerService);

  return submit$.pipe(
    tap(() => {
      source$.next(null);
      readyToSubmitUpgrade$.next(null);
    }),
    withLatestFrom(
      props$,
      fullName$,
      subscription$.pipe(filterEmpty).pipe(take(1)),
    ),
    switchMap(([_, { stripe }, fullName, subscription]) => {
      if (hasPaymentMethod(subscription)) {
        return of({
          error: undefined,
        });
      }

      if (!stripe) {
        throw new Error('Stripe object is not available');
      }

      if (!fullName) {
        throw new Error(
          'An account owners full name is required in the sepa debit source flow',
        );
      }

      const data = {
        type: PaymentMethodName.SepaDebit,
        currency: 'EUR',
        fullName: fullName,
      };

      return createSepaDebitSource(stripe.createSource, data, newSource);
    }),
    map((data) => {
      if (data.error) {
        buttonLoading$.next(false);

        const error = new Error(`Stripe Error ${data.error.message}`);
        displayExceptionAndLogToSentry(error, {
          ...data.error,
          ...data,
        });
      } else {
        markAsReadyToSubmitUpgrade();
      }
    }),
    catchError((error: Error) => {
      buttonLoading$.next(false);

      displayExceptionAndLogToSentry(error);

      return NEVER;
    }),
    switchMapTo(NEVER),
  );
};

export const changeSubscriptionPlanSubmitSink = submitSinkFactory(container);
