import { Container } from 'dependency-injection';
import platform from 'platform';
import { lifecycle } from '@blendle/recompose';
import { AnalyticsService } from 'shared/services';

export const pageLoadAnalyticsFactory = (container: Container) =>
  lifecycle({
    componentDidMount() {
      const analyticsService = container.get(AnalyticsService);

      analyticsService.track('Session', {
        browser: platform.name || 'unknown',
        device: platform.product || 'other',
        os: (platform.os && platform.os.family) || 'other',
        version: platform.version || 'other',
        orientation:
          window.innerWidth > window.innerHeight ? 'landscape' : 'portrait',
        appVersion: 'Unknown',
        standalone: 'unknown',
      });
    },
  });
