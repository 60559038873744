import { injectable } from 'inversify';
import {
  compose,
  filter as ramdaFilter,
  pipe,
  equals,
  find,
  head,
  map as mapList,
} from 'ramda';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { map, shareReplay, startWith, filter } from 'rxjs/operators';
import { filterEmpty } from 'utils/filter-empty';
import { Hal } from '../hal';
import { RequestService } from '../RequestService';
import { Router } from '../Router';
import { paymentType, createPaymentMethods } from './helpers';
import {
  PaymentMethod,
  supportedPaymentTypes,
  PaymentMethodResponse,
  PaymentMethodExistingSepaDebitOrCard,
  PaymentMethodFreeOptIn,
  PaymentMethodName,
  PaymentMethodSepaDebit,
} from './types';
export * from './helpers';
export * from './types';

const paymentMethodOrder = [
  PaymentMethodName.ExistingMethod,
  PaymentMethodName.IDeal,
  PaymentMethodName.Card,
  PaymentMethodName.FreeOptIn,
  PaymentMethodName.SepaDebit,
];

type isPaymentTypeType = (
  type: supportedPaymentTypes,
) => (method: PaymentMethod) => boolean;

export const isPaymentType: isPaymentTypeType = (
  paymentTypeToFind: supportedPaymentTypes,
) => compose(equals(paymentTypeToFind), paymentType);

export const findPaymentType = compose<
  supportedPaymentTypes,
  (method: PaymentMethod) => boolean,
  (list: ReadonlyArray<PaymentMethod>) => PaymentMethod | undefined
>(find, isPaymentType);

export const sortPaymentTypes = (
  list: PaymentMethodName[],
  paymentMethods: ReadonlyArray<PaymentMethod>,
) =>
  pipe(
    mapList((typeToFind: supportedPaymentTypes) =>
      findPaymentType(typeToFind)(paymentMethods),
    ),
    ramdaFilter(Boolean),
  )(list) as PaymentMethod[];

@injectable()
export class PaymentMethodsService {
  readonly paymentMethods$ = this.request
    .get<Hal<PaymentMethodResponse>>('payment_methods')
    .pipe(
      map(({ data }) => createPaymentMethods(data)),
      map((paymentMethods) =>
        sortPaymentTypes(paymentMethodOrder, paymentMethods),
      ),
      shareReplay(1),
    );

  readonly selectedMethod$: Observable<PaymentMethod | null>;
  readonly selectedMethodWithDefault$: Observable<PaymentMethod>;
  readonly selectedExistingMethodWithDefault$: Observable<PaymentMethodExistingSepaDebitOrCard>;
  readonly selectedFreeOptInMethodWithDefault$: Observable<PaymentMethodFreeOptIn>;
  readonly selectedSepaDebitMethodWithDefault$: Observable<PaymentMethodSepaDebit>;

  constructor(
    private readonly request: RequestService,
    readonly router: Router,
  ) {
    this.selectedMethod$ = observableCombineLatest([
      router.route$,
      this.paymentMethods$,
    ]).pipe(
      map(([route, method]) => {
        return (
          find(
            compose<PaymentMethod, string, boolean>(
              equals(route.params.paymentMethod),
              paymentType,
            ),
            method,
          ) || null
        );
      }),
    );

    this.selectedMethodWithDefault$ = observableCombineLatest([
      this.selectedMethod$.pipe(startWith(null)),
      filterEmpty(this.paymentMethods$),
    ]).pipe(
      map(
        ([selectedMethodFromRoute, methods]) =>
          selectedMethodFromRoute || head(methods),
      ),
      filterEmpty,
    );

    this.selectedExistingMethodWithDefault$ = this.selectedMethodWithDefault$.pipe(
      filter<PaymentMethodExistingSepaDebitOrCard>((method) =>
        isPaymentType(PaymentMethodName.ExistingMethod)(method),
      ),
    );

    this.selectedFreeOptInMethodWithDefault$ = this.selectedMethodWithDefault$.pipe(
      filter<PaymentMethodFreeOptIn>((method) =>
        isPaymentType(PaymentMethodName.FreeOptIn)(method),
      ),
    );

    this.selectedSepaDebitMethodWithDefault$ = this.selectedMethodWithDefault$.pipe(
      filter<PaymentMethodSepaDebit>((method) =>
        isPaymentType(PaymentMethodName.SepaDebit)(method),
      ),
    );
  }
}
