import { inject, injectable } from 'inversify';
import { BehaviorSubject, merge as observableMerge } from 'rxjs';
import {
  map,
  scan,
  skipWhile,
  startWith,
  take,
  tap,
  filter,
} from 'rxjs/operators';
import { fillLink, Link as HalLink } from './hal';
import { Http } from './HttpService';
import { has, path } from 'ramda';

interface EntryPointsStructure {
  [name: string]: HalLink | HalLink[] | undefined;
}

interface LinkResponse {
  [endpointName: string]: HalLink | HalLink[];
}

const hasMicroserviceSubscriptions = has('microservice_subscriptions');
const getMicroserviceSubscriptionsLink = path<string>([
  'microservice_subscriptions',
  'href',
]);

@injectable()
export class EntryPoints {
  readonly entryPoints$ = new BehaviorSubject<EntryPointsStructure>({});

  @inject(Http)
  private http: Http;

  addEntryPoints(
    uris: string[],
    existingEntryPoints: EntryPointsStructure = {},
  ) {
    observableMerge(
      ...uris.map((uri) =>
        this.http
          .get<{ _links: LinkResponse }>(uri)
          .pipe(map((res) => res.data._links)),
      ),
    )
      .pipe(
        startWith(existingEntryPoints),
        scan((entryPoints: LinkResponse, value: LinkResponse) =>
          Object.assign(entryPoints, value),
        ),
      )
      .subscribe((entryPoints) => this.entryPoints$.next(entryPoints));
  }

  addSubscriptionEntryPoints = () => {
    this.entryPoints$
      .pipe(
        filter(hasMicroserviceSubscriptions),
        take(1),
        tap((entryPoints) => {
          this.addEntryPoints(
            [getMicroserviceSubscriptionsLink(entryPoints)!],
            entryPoints,
          );
        }),
      )
      .subscribe();
  };

  getEntryByName(name: string, data?: {}) {
    return this.entryPoints$.pipe(
      map((entryPoints) => entryPoints[name]),
      skipWhile((uri) => !uri),
      map((uri) => {
        if (Array.isArray(uri)) {
          throw new Error('Entry invalid');
        }
        if (uri!.templated) {
          return fillLink(uri!, data);
        }
        return uri!.href;
      }),
      take(1),
    );
  }
}
