// tslint:disable:no-bitwise
export function uuid() {
  let uuid = '';

  for (let counter = 0; counter < 32; counter++) {
    const random = (Math.random() * 16) | 0;

    if (counter === 8 || counter === 12 || counter === 16 || counter === 20) {
      uuid += '-';
    }
    uuid += (counter === 12
      ? 4
      : counter === 16
      ? (random & 3) | 8
      : random
    ).toString(16);
  }

  return uuid;
}
