import { injectable } from 'inversify';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UserService } from '../UserService';
import { filterEmpty } from 'utils';
import { tap, map } from 'rxjs/operators';
import { join } from 'ramda';

@injectable()
export class SepaDebitPaymentDetailsService {
  readonly firstName$ = new BehaviorSubject<string>('');
  readonly lastName$ = new BehaviorSubject<string>('');

  readonly fullName$ = combineLatest([this.firstName$, this.lastName$]).pipe(
    map(([firstName, lastName]) => {
      if (!firstName || !lastName) {
        return undefined;
      }

      return join(' ', [firstName, lastName]);
    }),
  );

  readonly sepaDebitPaymentDetailsValid$ = this.fullName$.pipe(
    map((fullName) => Boolean(fullName)),
  );

  constructor(private readonly userService: UserService) {
    this.takeFirstNameAndLastNameFromUser().subscribe();
  }

  takeFirstNameAndLastNameFromUser = () =>
    this.userService.user$.pipe(
      filterEmpty,
      tap(({ first_name, last_name }) => {
        if (first_name) {
          this.setFirstName(first_name);
        }

        if (last_name) {
          this.setLastName(last_name);
        }
      }),
    );

  setFirstName = (firstName: string) => {
    this.firstName$.next(firstName);
  };

  setLastName = (lastName: string) => {
    this.lastName$.next(lastName);
  };
}
