import { injectable } from 'inversify';
import createRouter, {
  PluginFactory,
  Router as RouterInstance,
  State,
} from 'router5';
import browserPlugin from 'router5-plugin-browser';
import loggerPlugin from 'router5-plugin-logger';
import { routes, RouteName, StartSubscriptionChildRouteName } from 'routes';
import { BehaviorSubject } from 'rxjs';
import { filterEmpty } from 'utils/filter-empty';
import { equals, omit } from 'ramda';
import { PaymentMethodName } from '../PaymentMethodsService/types';
import { rootNode, joinRouteParts } from 'utils';
import { DoneFn } from 'router5/dist/types/base';

const isDevelopment = equals<string | undefined>('development');

@injectable()
export class Router {
  readonly router: RouterInstance;
  public routeSnapshot: State;
  private readonly routeSubject$ = new BehaviorSubject<State | null>(null);
  // tslint:disable-next-line:member-ordering
  readonly route$ = this.routeSubject$.pipe(filterEmpty);

  constructor() {
    const connectStream: PluginFactory = Object.assign(
      () => {
        return {
          onTransitionSuccess: (toState: State /*, fromState: State*/) => {
            this.routeSubject$.next(toState);
            this.routeSnapshot = toState;
          },
        };
      },
      { pluginName: 'CONNECT_STREAM' },
    );

    this.router = createRouter(routes, {
      defaultRoute: RouteName.Upsell,
      queryParamsMode: 'loose',
    });

    this.router.usePlugin(browserPlugin(), connectStream);

    if (isDevelopment(process.env.NODE_ENV)) {
      this.router.usePlugin(loggerPlugin);
    }
  }

  navigateToPaymentPage = ({
    productId,
    paymentMethod,
    callback,
    removeStripeUrlParams = false,
  }: {
    productId: string;
    paymentMethod?: PaymentMethodName;
    callback?: DoneFn;
    removeStripeUrlParams?: boolean;
  }) => {
    const stripeUrlParams = [
      'client_secret',
      'source',
      'livemode',
      'subscription_token',
    ];

    const { name, params } = this.routeSnapshot;

    const nextParams = removeStripeUrlParams
      ? omit(stripeUrlParams, params)
      : params;

    this.router.navigate(
      joinRouteParts(
        rootNode(name),
        StartSubscriptionChildRouteName.Plan,
        StartSubscriptionChildRouteName.Method,
      ),
      {
        ...nextParams,
        productId,
        ...(paymentMethod && { paymentMethod }),
      },
      {},
      callback,
    );
  };

  navigateBackToBuyCreditsPaymentPage = ({
    callback,
  }: {
    callback?: DoneFn;
  }) => {
    const stripeUrlParams = [
      'invoice_id',
      'bank',
      'payment_method',
      'number_of_credits',
      'payment_intent',
      'payment_intent_client_secret',
      'source_type',
    ];

    const { name, params } = this.routeSnapshot;

    const nextParams = omit(stripeUrlParams, params);

    this.router.navigate(rootNode(name), nextParams, {}, callback);
  };
}
