import { parseDocumentCookies } from 'utils/storage';
import * as Sentry from '@sentry/browser';

const CONSENT_COOKIE_NAME = 'cookieConsent';

export const hasUserGivenCookieConsent = (
  parsedDocumentCookies: { [key: string]: string } = parseDocumentCookies(),
) => {
  const consentCookie = parsedDocumentCookies[CONSENT_COOKIE_NAME];

  if (!consentCookie) {
    return false;
  }

  try {
    const parsed = JSON.parse(decodeURIComponent(consentCookie));

    return parsed && parsed.hasGivenConsent ? parsed.hasGivenConsent : false;
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setExtra('cookie', consentCookie);
      Sentry.captureMessage(
        `Could not parse consentCookie, ${JSON.stringify(error)}`,
      );
    });

    return false;
  }
};
