import { injectable } from 'inversify';
import { prop } from 'ramda';
import {
  combineLatest as observableCombineLatest,
  merge as observableMerge,
  throwError as observableThrowError,
} from 'rxjs';
import { filter, map, mergeMap, retryWhen, switchMap } from 'rxjs/operators';
import { EntryPoints } from '../EntryPointsService';
import { Http, HttpRequestOptions } from '../HttpService';
import { UserService } from '../UserService';

export const data = prop('data');

@injectable()
export class RequestService {
  constructor(
    private http: Http,
    private entryPoints: EntryPoints,
    private user: UserService,
  ) {}

  get<T>(
    endpointName: string,
    templateData?: {},
    requestOptions?: HttpRequestOptions,
  ) {
    return this.fetch<T>('get', endpointName, requestOptions, templateData);
  }

  put<T>(
    endpointName: string,
    data: {} | string,
    templateData?: {},
    requestOptions?: HttpRequestOptions,
  ) {
    return this.fetch<T>(
      'put',
      endpointName,
      { data, ...requestOptions },
      templateData,
    );
  }

  post<T>(
    endpointName: string,
    data?: {} | string,
    templateData?: {},
    requestOptions?: HttpRequestOptions,
  ) {
    return this.fetch<T>(
      'post',
      endpointName,
      { data, ...requestOptions },
      templateData,
    );
  }

  private fetch<T>(
    method: 'get' | 'post' | 'put',
    endpointName: string,
    {
      data,
      whitelistStatusCodes = [],
      headers: incomingHeaders,
      ...requestOptions
    }: { data?: {} } & HttpRequestOptions = {},
    templateData: {} = {},
  ) {
    // MS Edge can't hendle new Headers(undefined)
    const headers = new Headers(incomingHeaders || {});
    let body: string;
    if (typeof data === 'string') {
      headers.append('Content-Type', 'plain/text');
      body = data;
    } else {
      headers.append('Content-Type', 'application/json');
      body = JSON.stringify(data || '');
    }

    return observableCombineLatest([
      this.entryPoints.getEntryByName(endpointName, templateData),
      this.user.getValidJwt$(),
    ]).pipe(
      switchMap(([uri, jwt]) => {
        headers.delete('Authorization');
        headers.append('Authorization', `Bearer ${jwt}`);

        return this.http[method]<T>(uri, {
          headers,
          body: method !== 'get' ? body : undefined,
          whitelistStatusCodes: [401, ...whitelistStatusCodes],
          ...requestOptions,
        }).pipe(
          map((data) => {
            if (data.response.status === 401) {
              throw data.response;
            }
            return data;
          }),
        );
      }),
      retryWhen((errors$) => {
        return observableMerge(
          errors$.pipe(
            filter((res: Response) => res.status === 401),
            switchMap(() => this.user.refreshJwt()),
          ),
          errors$.pipe(
            filter((res: Response) => res.status !== 401),
            mergeMap((e) => observableThrowError(e)),
          ),
        );
      }),
    );
  }
}
