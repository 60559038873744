const payloadFromJWT = (
  jwt: string,
): { [key: string]: string | number | boolean } | undefined => {
  try {
    const encodedPayload = jwt.split('.')[1];
    const decodedPayload = window.atob(encodedPayload);

    return JSON.parse(decodedPayload);
  } catch (e) {
    return undefined;
  }
};

export const getCouponCodeFromSubscriptionToken = (
  subscriptionToken: string,
): string | undefined => {
  const payload = payloadFromJWT(subscriptionToken);

  return payload && payload.coupon_code
    ? (payload.coupon_code as string)
    : undefined;
};
