import React from 'react';

export const ErrorIcon = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Canvas" transform="translate(-12591 193)">
      <g id="Vector">
        <use
          xlinkHref="#path0_fill"
          transform="translate(12591 -193)"
          fill="#FF2F41"
        />
      </g>
    </g>
    <defs>
      <path
        id="path0_fill"
        // tslint:disable-next-line:max-line-length
        d="M 15.2262 11.5178L 9.16617 0.841262C 8.87096 0.320823 8.31928 0 7.72137 0C 7.12283 0 6.57116 0.320823 6.27595 0.841262L 0.21655 11.5178C -0.0755329 12.0316 -0.0717842 12.663 0.22561 13.1744C 0.523003 13.6851 1.07031 14 1.66166 14L 13.7808 14C 14.3724 14 14.9191 13.6851 15.2168 13.1744C 15.5145 12.663 15.5177 12.0313 15.2262 11.5178ZM 8.5492 12.0382C 8.34615 12.2188 8.10498 12.3103 7.83321 12.3103C 7.55799 12.3103 7.31464 12.22 7.10909 12.0419C 6.89916 11.8595 6.79233 11.604 6.79233 11.2822C 6.79233 10.9998 6.89292 10.7562 7.09128 10.5581C 7.28934 10.3604 7.53488 10.2598 7.82102 10.2598C 8.10655 10.2598 8.35396 10.3597 8.55639 10.5575C 8.75913 10.7558 8.86221 10.9998 8.86221 11.2822C 8.8619 11.5993 8.75663 11.8533 8.5492 12.0382ZM 8.81817 5.16347L 8.5542 8.18676C 8.52452 8.56319 8.46079 8.84684 8.35958 9.05302C 8.24806 9.28106 8.05344 9.40195 7.79634 9.40195C 7.53425 9.40195 7.34119 9.28356 7.23779 9.05958C 7.14595 8.86059 7.08285 8.57163 7.04443 8.17489L 6.84762 5.23282C 6.81045 4.65146 6.79202 4.24567 6.79202 3.99232C 6.79202 3.6262 6.89167 3.33412 7.08816 3.1242C 7.28653 2.9124 7.54987 2.80462 7.86976 2.80462C 8.26462 2.80462 8.53452 2.94801 8.67259 3.23041C 8.79942 3.49094 8.86128 3.85081 8.86128 4.33033C 8.8619 4.60211 8.84722 4.88232 8.81817 5.16347Z"
      />
    </defs>
  </svg>
);
