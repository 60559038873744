import { Observable, Observer } from 'rxjs';

export function loadScript(src: string): Observable<{}> {
  return new Observable((observable: Observer<{}>) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => {
      observable.next(true);
      observable.complete();
    };

    script.onerror = (e) => {
      observable.error(e);
      observable.complete();
    };

    document.head!.appendChild(script);
  });
}
