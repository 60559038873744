import config from 'environment-config';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DotWrapper, Link, Wrapper } from './styles';

const Divider = () => <DotWrapper>·</DotWrapper>;

export const Footer = () => {
  const domain = config.redirectDomain;

  return (
    <Wrapper>
      <Link href={`${domain}/about/privacy`}>
        <FormattedMessage id="footer.privacy" />
      </Link>
      <Divider />
      <Link href={`${domain}/about/termsandconditions`}>
        <FormattedMessage id="footer.terms-and-conditions" />
      </Link>
      <Divider />
      <Link href={`${domain}/logout`}>
        <FormattedMessage id="footer.logout" />
      </Link>
    </Wrapper>
  );
};
