import { injectable, postConstruct } from 'inversify';
import { equals, anyPass } from 'ramda';
import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  Observable,
  Subject,
  SchedulerLike,
} from 'rxjs';
import {
  bufferTime,
  filter,
  first,
  map,
  mergeMap,
  share,
  tap,
} from 'rxjs/operators';
import { filterEmpty } from 'utils/filter-empty';
import { getSessionId } from 'utils/storage';
import { User } from '../../models';
import { Router } from '../Router';
import { UserService } from '../UserService';
import { UtmService } from '../UtmService';
import config from 'environment-config';
import { ErrorLoggerService } from '../ErrorLoggerService';
import { Http } from '../HttpService';
import {
  internalLocationForRouteAndEntryPoint,
  version,
  trackingUid,
} from './helpers';
import {
  OutgoingAnalyticsEvent,
  AnalyticsEventPayload,
  AnalyticsEvent,
  AbTestPayload,
} from './types';
import { SegmentAnalyticsService } from './segmentService';
import { isSegmentEnabled } from 'features';

const isTestOrProd = anyPass<string | undefined>([
  equals('test'),
  equals('production'),
]);

const EVENTS_BUFFER_TIME_IN_MS = 250;

function outputToConsole(properties: OutgoingAnalyticsEvent) {
  if (isTestOrProd(process.env.NODE_ENV)) {
    return;
  }

  // tslint:disable-next-line:no-console
  console.log(
    '%cAnalytics',
    'color: #0a0; padding: 3px; display: block; background: #beb; font-size: 90%;',
    properties.type,
    JSON.stringify(properties),
    properties,
  );
}

@injectable()
export class AnalyticsService {
  private readonly sessionId = getSessionId();
  private readonly isSegmentEnabled = isSegmentEnabled();

  private readonly event$ = new Subject<AnalyticsEvent>();
  private readonly eventsBuffered$ = this.setupEventsBuffered(this.event$);

  constructor(
    private readonly userService: UserService,
    private readonly utmService: UtmService,
    private readonly routerService: Router,
    private readonly errorLoggerService: ErrorLoggerService,
    private readonly http: Http,
    private readonly segmentAnalyticsService: SegmentAnalyticsService,
  ) {}

  @postConstruct()
  __subscribeToEventsStream() {
    this.eventsBuffered$.subscribe((events) => {
      if (isTestOrProd(process.env.NODE_ENV)) {
        this.sendEventsSideEffect(events);
      }
    });
  }

  track(type: string, payload: AnalyticsEventPayload = {}) {
    // This constructs the actual event that will be send
    this.event$.next({
      type,
      session_id: this.sessionId,
      origin: 'web-payment',
      client_version: version,
      payload: {
        ...payload,
        ...this.utmService.getUtmAsAnalyticsPayload(),
        session: this.sessionId,
        current_uri: document.location!.pathname,
        client_timestamp: new Date().toISOString(),
        sent_to_segment: this.isSegmentEnabled,
      },
    });

    if (this.isSegmentEnabled) {
      this.segmentAnalyticsService.track(type, payload);
    }
  }

  private setupEventsBuffered(
    event$: Observable<AnalyticsEvent>,
    scheduler?: SchedulerLike,
  ): Observable<OutgoingAnalyticsEvent[]> {
    const user$ = this.userService.user$.pipe(
      filterEmpty,
      share(),
    ) as Observable<User>;

    const trackingUid$ = user$.pipe(map((user) => trackingUid(user)));

    const abTest$ = user$.pipe(
      map((user) => {
        const abTests = (user._embedded && user._embedded.ab_tests) || [];

        const abTestData = abTests.reduce((reducedValue, current) => {
          reducedValue[current.name] = current.group;
          return reducedValue;
        }, {} as AbTestPayload);

        if (abTests.length > 0) {
          abTestData.ab_tests_ids = abTests.map(({ name }) => name).join(',');
        }

        return abTestData;
      }),
    );

    const internalLocation$ = this.userService.signupEntryPoint$.pipe(
      map((signupEntryPoint) =>
        internalLocationForRouteAndEntryPoint(
          this.routerService,
          signupEntryPoint,
        ),
      ),
    );

    // make metadata
    {
      type metadata = {
        trackingUid: string;
        abTest: AbTestPayload;
        internalLocation: string;
      };
      const metadataSubject$ = new BehaviorSubject<metadata | null>(null);

      observableCombineLatest([trackingUid$, abTest$, internalLocation$])
        .pipe(
          map(([trackingUid, abTest, internalLocation]) => ({
            trackingUid,
            abTest,
            internalLocation,
          })),
        )
        .subscribe(metadataSubject$);

      var metadata$ = metadataSubject$.pipe(
        filter((metadata) => !!metadata),
      ) as Observable<metadata>;
    }

    return event$.pipe(
      mergeMap((event) =>
        metadata$.pipe(
          first(),
          map(
            ({ trackingUid, abTest, internalLocation }) =>
              ({
                ...event,
                payload: {
                  ...event.payload,
                  ...abTest,
                  tracking_uid: trackingUid,
                  internal_location: internalLocation,
                },
              } as OutgoingAnalyticsEvent),
          ),
        ),
      ),
      tap(outputToConsole),
      bufferTime(EVENTS_BUFFER_TIME_IN_MS, scheduler), // buffer events every second
      filter((events) => events.length > 0), // filter out empty events
    );
  }

  private sendEventsSideEffect(events: OutgoingAnalyticsEvent[]) {
    try {
      const body = JSON.stringify(events);
      const eventSend = navigator.sendBeacon(config.analyticsUri, body);

      if (!eventSend) {
        // Fall back to fetch api if sendBeacon fails
        this.http.post(config.analyticsUri, { body }).subscribe();
      }
    } catch (e) {
      this.errorLoggerService.captureMessage(e);
    }
  }
}
