import { injectable } from 'inversify';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PaymentMethodName } from '../PaymentMethodsService';
import { createEventHandler } from '@blendle/recompose';
import { startWith } from 'rxjs/operators';
import { ErrorsService } from '../ErrorsService';

@injectable()
export class ChangePaymentDetailsFormService {
  readonly newPaymentMethod$ = new BehaviorSubject<
    PaymentMethodName.Card | PaymentMethodName.SepaDebit
  >(PaymentMethodName.SepaDebit);

  readonly buttonLoading$ = new BehaviorSubject(false);

  ibanValid$: Observable<boolean>;
  handleIbanFormStatusChange: (value: boolean) => void;

  submit$: Observable<React.FormEvent<HTMLFormElement>>;

  private readonly submitSubject$ = new Subject<
    React.FormEvent<HTMLFormElement>
  >();

  constructor(private readonly errorsService: ErrorsService) {
    this.submit$ = this.submitSubject$.asObservable();

    const {
      handler: handleIbanFormStatusChange,
      stream: ibanValid$,
    } = createEventHandler<boolean, Observable<boolean>>();

    this.handleIbanFormStatusChange = handleIbanFormStatusChange;
    this.ibanValid$ = ibanValid$.pipe(startWith(false));
  }

  setNewPaymentMethod = (
    paymentMethodName: PaymentMethodName.Card | PaymentMethodName.SepaDebit,
  ) => {
    this.newPaymentMethod$.next(paymentMethodName);
    this.resetFormValidState();
  };

  handleIbanElementChange = ({ complete }: { complete: boolean }) => {
    this.handleIbanFormStatusChange(complete);
  };

  readonly handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.errorsService.removeCurrentError();
    this.submitSubject$.next(event);
  };

  private resetFormValidState = () => {
    this.handleIbanFormStatusChange(false);
  };
}
