import config from 'environment-config';
import { appendSearchString } from 'utils/url';

export function getSuccessPath(utmAsUrlParamString: string): string {
  const { redirectDomain, redirectSuccessPath } = config;
  const successPath =
    window.localStorage.getItem('successPath') || redirectSuccessPath;

  return appendSearchString(
    `${redirectDomain}${successPath}`,
    utmAsUrlParamString,
  );
}

export function getBuyCreditsSuccessPath(
  utmAsUrlParamString: string,
  numberOfCredits: string,
): string {
  const { redirectDomain, buyCreditsRedirectSuccessPath } = config;

  const successPath = appendSearchString(
    window.localStorage.getItem('successPath') || buyCreditsRedirectSuccessPath,
    `numberOfCredits=${numberOfCredits}`,
  );

  return appendSearchString(
    `${redirectDomain}${successPath}`,
    utmAsUrlParamString,
  );
}

export function getCancellationCtaPath({
  path,
  utmAsUrlParamString,
}: {
  utmAsUrlParamString: string;
  path: string;
}): string {
  const { redirectDomain } = config;

  return appendSearchString(`${redirectDomain}${path}`, utmAsUrlParamString);
}
