import uriTemplates from 'uri-templates';

type uri = string;

export interface HalLink {
  href: uri;
  templated?: boolean;
}

export interface HalLinks {
  self: HalLink;
  [name: string]: HalLink | HalLink[];
}

export interface Hal<T> {
  _links: HalLinks;
  _embedded: T;
}

export interface Link {
  templated?: boolean;
  href: uri;
  name: string;
}

/**
 * Fills a given RFC6570 link with data
 *
 * Keys will be rewritten from camelCase to snake_case
 */
export function fillLink(link: Link, data: {} = {}) {
  if (!link.templated) {
    throw new TypeError('Link is not templated');
  }

  const template = uriTemplates(link.href);
  return template.fillFromObject(data);
}
