import { defineMessage } from 'react-intl';

export enum CANCELLATION_REASON {
  LOOKING_FOR_SPECIFIC_ARTICLE = 'looking_for_specific_article',
  ARTICLE_QUALITY_DISSAPOINTS = 'article_quality_dissapoints',
  TOO_LITTLE_INFLUENCE_ON_SELECTION = 'too_little_influence_on_selection',
  PRICE_HIGH = 'price_high',
  NO_TIME = 'no_time',
  TECHNICAL_ISSUES = 'technical_issues',
  UNHAPPY_WITH_DAILY_SELECTION = 'unhappy_with_daily_selection',
  UNAWARE_OF_SUBSCRIPTION = 'unaware_of_subscription',
  PREFER_OLD_BLENDLE = 'prefer_old_blendle',
  MISS_DPG_TITLES = 'miss_dpg_titles',
}

export type Reason = {
  value: CANCELLATION_REASON;
  label: {
    id: string;
  };
};

export const REASONS: Reason[] = Object.keys(CANCELLATION_REASON).map(
  (reason) => ({
    value: CANCELLATION_REASON[reason],
    label: defineMessage({
      id: `subscription-overview.cancellation.reasons.${CANCELLATION_REASON[reason]}`,
    }),
  }),
);
