import {
  IntentStatus,
  Intent,
  CreateSubscriptionResponse,
} from '../SubscriptionsService';
import { Observable, of } from 'rxjs';
import {
  PaymentMethodWithMetadata,
  ExistingPaymentMethodWithMetadata,
} from './types';

export enum IntentType {
  PaymentIntent = 'payment_intent',
  PendingSetupIntent = 'pending_setup_intent',
}

export const subscriptionResponseHasNoErrors = (
  data: CreateSubscriptionResponse,
) => !data._errors || data._errors.length === 0;

export const subscriptionResponseHasErrors = (
  data: CreateSubscriptionResponse,
) => !subscriptionResponseHasNoErrors(data);

export const handleIntent = (
  intent: Intent,
  paymentMethod: PaymentMethodWithMetadata | ExistingPaymentMethodWithMetadata,
  intentHandler: (
    clientSecret: string,
    paymentMethodId: string,
  ) => Observable<stripe.PaymentIntentResponse>,
) => {
  const { client_secret, status } = intent;

  const defaultIntentResponse = {} as stripe.PaymentIntentResponse;

  switch (status) {
    case IntentStatus.RequiresAction:
      return intentHandler(client_secret, paymentMethod.id);
    case IntentStatus.Canceled:
    case IntentStatus.RequiresPaymentMethod:
      // eslint-disable-next-line no-throw-literal
      throw { message: `intent status is ${status}` };
    case IntentStatus.RequiresConfirmation:
    case IntentStatus.Processing:
    case IntentStatus.Succeeded:
      // In these cases the Intent doesn't require a specific action anymore
      // and the user should be directed to the success page where we poll
      // till the subscription is created
      return of(defaultIntentResponse);
    default:
      return of(defaultIntentResponse);
  }
};
