import { SourceService } from 'shared/services/SourceService';
import { Bank, PaymentMethodName } from 'shared/services/PaymentMethodsService';
import { ReactStripeElements } from 'react-stripe-elements';
import { SourceWithMetadata } from 'shared/services/SourceService/types';

export async function createSource(
  stripeCreateSource: ReactStripeElements.StripeProps['createSource'],
  {
    type,
    redirectUrl,
    amount,
    currency,
    metadata,
    bank,
  }: {
    type: stripe.SourceOptions['type'];
    redirectUrl: string;
    amount: stripe.SourceOptions['amount'];
    currency: stripe.SourceOptions['currency'];
    metadata: stripe.SourceOptions['metadata'];
    bank: Bank | undefined;
  },
  nextSource: typeof SourceService.prototype.nextSource,
) {
  const ideal =
    type === PaymentMethodName.IDeal && bank ? { ideal: { bank } } : {};

  const { source, error } = await stripeCreateSource({
    type,
    redirect: { return_url: redirectUrl },
    amount,
    currency,
    metadata,
    ...ideal,
  });

  if (source) {
    nextSource(source as SourceWithMetadata);
  }

  return { source, error };
}
