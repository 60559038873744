import {
  IncomingSubscription,
  Subscription,
  Issuer,
  Interval,
  SubscriptionType,
} from './types';
import { idFromError, halError } from 'utils/errors';
import { equals, propOr, pipe, prop, has } from 'ramda';
import {
  isUpgradePlanEnabled,
  isOptInSubscriptionUpgradeEnabled,
} from 'features';

export function parseIncomingSubscriptionDates(
  incomingSubscription: IncomingSubscription,
): Subscription {
  const {
    current_period_end_date,
    end_date_when_cancelled,
  } = incomingSubscription;

  return {
    ...incomingSubscription,
    ...(current_period_end_date && {
      current_period_end_date: new Date(current_period_end_date),
    }),
    ...(end_date_when_cancelled && {
      end_date_when_cancelled: new Date(end_date_when_cancelled),
    }),
  } as Subscription;
}

export enum SubscriptionOverviewError {
  UserHasNoSubscription = 'USER_HAS_NO_SUBSCRIPTION',
}

export const isNotFoundError = (errors: halError[] | Error) => {
  const error = Array.isArray(errors) ? errors[0] : errors;
  const errorId = idFromError(error);

  return errorId === 'NotFound';
};

export const isNoSubscriptionError = pipe(
  propOr(undefined, 'message'),
  equals(SubscriptionOverviewError.UserHasNoSubscription),
);

const isStripeSubscription = pipe(prop('issuer'), equals(Issuer.Stripe));

const isRegularSubscription = pipe(
  prop('type'),
  equals(SubscriptionType.Regular),
);

const hasPaymentMethod = has('payment_method');

const isYearlyPlan = pipe(propOr(undefined, 'interval'), equals(Interval.Year));

// TODO: Move this logic to the backend. It should be included in the response of the /subscription-overview page
export const canPlanBeUpgraded = (subscription: Subscription) => {
  if (!isUpgradePlanEnabled()) {
    return false;
  }

  if (!isStripeSubscription(subscription)) {
    return false;
  }

  // Trials or External subscription can't be update at the moment
  if (!isRegularSubscription(subscription)) {
    return false;
  }

  if (isYearlyPlan(subscription)) {
    return false;
  }

  if (!hasPaymentMethod(subscription)) {
    return isOptInSubscriptionUpgradeEnabled();
  }

  return true;
};
