import { postConstruct, injectable } from 'inversify';
import {
  all,
  compose,
  equals,
  filter as filterList,
  find,
  head,
  ifElse,
  isEmpty,
  not,
  objOf,
  path,
  pipe,
  propSatisfies,
} from 'ramda';
import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  Observable,
} from 'rxjs';
import {
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { filterEmpty } from 'utils/filter-empty';
import { RequestService } from '../RequestService';
import { Router } from '../Router';
import { displayId } from './helpers';
import { SubscriptionPlans, SubscriptionPlan } from './types';

const REACTIVATION_PLAN = 'blendlepremium_monthly_reactivation';

const SUBSCRIPTION_PLANS_SORT = [
  'blendlepremium_monthly',
  'blendlepremium_yearly',
  REACTIVATION_PLAN,
  'blendlepremium_6weeks_trial',
  'blendlepremium_migration_yearly_paid_per_month',
];

interface SubscriptionPlanResponse {
  plans: SubscriptionPlans;
}

export enum SubscriptionTypes {
  UpSell = 'upsell',
  OptOut = 'optout',
}

const userGetsTrial = propSatisfies(
  (days: number) => days > 0,
  'remaining_trial_days',
);
const eachPlanIsOptOut = all(userGetsTrial);
const toSubscriptionType: (
  plans: SubscriptionPlans,
) => SubscriptionTypes = ifElse(
  eachPlanIsOptOut,
  () => SubscriptionTypes.OptOut,
  () => SubscriptionTypes.UpSell,
);
const toValueObject = objOf('value');
const subscriptionTypeToValueObject: (
  plans: SubscriptionPlans,
) => { value: SubscriptionTypes } = pipe(toSubscriptionType, toValueObject);

const mapToSubscriptionType = pipe(
  filterEmpty,
  map(subscriptionTypeToValueObject),
  distinctUntilKeyChanged('value'),
);

const equalsDisplayId = (displayIdFromSort: string) =>
  compose<SubscriptionPlan, string, boolean>(
    equals(displayIdFromSort),
    displayId,
  );

const sortPlans = (
  plans: SubscriptionPlans,
): (SubscriptionPlan | undefined)[] =>
  SUBSCRIPTION_PLANS_SORT.map((displayIdFromSort) =>
    find(equalsDisplayId(displayIdFromSort), plans),
  );

const sortAndFilterPlans = compose(filterList(Boolean), sortPlans);

const isNotEmpty = compose(not, isEmpty);

const hasReactivationPlanProductIdParam = pipe(
  path(['params', 'productId']),
  equals(REACTIVATION_PLAN),
);

@injectable()
export class SubscriptionPlansService {
  readonly subscriptionPlans$ = new BehaviorSubject<SubscriptionPlans | null>(
    null,
  );
  readonly selectedPlan$: Observable<null | SubscriptionPlan>;
  readonly selectedPlanWithDefault$: Observable<SubscriptionPlan>;

  readonly subscriptionType$: Observable<{
    value: SubscriptionTypes;
  }>;

  readonly subscriptionPlanRoutes$: Observable<string>;

  private readonly subscriptionTypeSubject$ = new BehaviorSubject<{
    value: SubscriptionTypes;
  } | null>(null);

  constructor(
    private readonly request: RequestService,
    readonly router: Router,
  ) {
    mapToSubscriptionType(this.subscriptionPlans$).subscribe(
      this.subscriptionTypeSubject$,
    );
    this.subscriptionType$ = filterEmpty(this.subscriptionTypeSubject$);

    this.subscriptionPlanRoutes$ = this.subscriptionPlans$.pipe(
      filterEmpty,
      map((plans) => plans.map(displayId).join('|')),
    );

    this.selectedPlan$ = observableCombineLatest([
      router.route$,
      filterEmpty(this.subscriptionPlans$),
    ]).pipe(
      map(([route, plans]) => {
        const planFromRoute = find(
          compose<SubscriptionPlan, string, boolean>(
            equals(route.params.productId),
            displayId,
          ),
          plans,
        );

        return planFromRoute || null;
      }),
    );

    this.selectedPlanWithDefault$ = observableCombineLatest([
      this.selectedPlan$,
      filterEmpty(this.subscriptionPlans$),
    ]).pipe(
      map(
        ([selectedPlanFromRoute, plans]) =>
          selectedPlanFromRoute || head(plans),
      ),
      filterEmpty,
    );
  }

  @postConstruct()
  afterCreate() {
    this.fetchPlans();
  }

  fetchPlans() {
    // To make sure that all users get a 14 day trial instead of the default 30,
    // we fetch the plan with an extra query param to tell the stripe-service to
    // return plans with a trial of 14 days.
    this.router.route$
      .pipe(
        switchMap((route) => {
          // For the reactivation users the plans need to be fetched with this specific param
          if (hasReactivationPlanProductIdParam(route)) {
            return this.fetchPlansWithCampaign('14dayreactivationtrial');
          }

          return this.fetchPlansWithCampaign('14daytrial');
        }),
        map(path(['data', 'plans'])),
        map(sortAndFilterPlans),
        filter(isNotEmpty), // prevent redirect loop when there is no public product
      )
      .subscribe((plans) => this.subscriptionPlans$.next(plans));
  }

  private fetchPlansWithCampaign(campaign?: string) {
    return this.request.get<SubscriptionPlanResponse>(
      'subscription_plans',
      campaign ? { campaign } : undefined,
    );
  }
}
