import { SourceService } from 'shared/services/SourceService';
import { ReactStripeElements } from '../../../node_modules/@types/react-stripe-elements';
import { Metadata } from 'shared/services/SourceService/types';

export async function createPaymentMethod(
  createPaymentMethod: ReactStripeElements.StripeProps['createPaymentMethod'],
  metadata: Metadata,
  nextPaymentMethod: typeof SourceService.prototype.nextPaymentMethod,
) {
  const { paymentMethod, error } = await createPaymentMethod('card');

  if (paymentMethod) {
    nextPaymentMethod({ ...paymentMethod, metadata });
  }

  return { paymentMethod, error };
}
