import { injectable } from 'inversify';
import { prop, has, omit, assoc } from 'ramda';
import { of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { filterEmpty } from 'utils/filter-empty';
import { Preferences, User } from '../../models/login';
import { RequestService } from '../RequestService';
import { UserService } from '../UserService';
import {
  convertBooleanStringsToBooleans,
  savingPreferencesFailed,
} from './helper';
import { ErrorLoggerService } from '../ErrorLoggerService';

const getPreferences = prop<'preferences', Preferences>('preferences');
const hasSignupCouponCode = has('signup_coupon_code');
const omitSignupCouponCode = omit(['signup_coupon_code']);

@injectable()
export class PreferencesService {
  readonly user$ = this.userService.user$.pipe(filterEmpty, first());

  readonly preferences$ = this.user$.pipe(map(getPreferences));

  public removeSignupCouponCode$ = this.user$.pipe(
    mergeMap((user) => {
      const currentPreferences = getPreferences(user);

      if (!hasSignupCouponCode(currentPreferences)) {
        return of(true);
      }

      const preferencesWithoutCouponCode = omitSignupCouponCode(
        currentPreferences,
      ) as Preferences;

      return this.updatePreferences(user, preferencesWithoutCouponCode);
    }),
  );

  constructor(
    private userService: UserService,
    private requestService: RequestService,
    private errorLoggerService: ErrorLoggerService,
  ) {}

  public updatePreferences(user: User, newPreferences: Preferences) {
    return this.requestService
      .put('preferences', convertBooleanStringsToBooleans(newPreferences), {
        user_id: user.id,
      })
      .pipe(
        mergeMap(({ response, data }) => {
          if (savingPreferencesFailed(response)) {
            this.errorLoggerService.captureMessage(
              'Updating preferences failed',
              {
                uuid: user.uuid,
                newPreferences,
              },
            );

            return of(true);
          }

          const userWithNewPreferences = assoc('preferences', data, user);
          this.userService.saveUser(userWithNewPreferences);

          return of(true);
        }),
      );
  }
}
