import { Container } from 'dependency-injection';
import { lifecycle } from '@blendle/recompose';
import { UtmService } from 'shared/services';

export const pageLoadUtmDataFactory = (container: Container) =>
  lifecycle({
    componentDidMount() {
      const utmService = container.get(UtmService);
      const paramsMap = new URLSearchParams(window.location.search);

      utmService.storeUtmData(paramsMap);
    },
  });
