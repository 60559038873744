import { SourceService } from 'shared/services/SourceService';
import { ReactStripeElements } from 'react-stripe-elements';
import { SourceWithMetadata } from 'shared/services/SourceService/types';

export async function createSepaDebitSource(
  stripeCreateSource: ReactStripeElements.StripeProps['createSource'],
  {
    type,
    metadata,
    currency,
    fullName,
  }: {
    type: stripe.SourceOptions['type'];
    metadata?: stripe.SourceOptions['metadata'];
    currency: stripe.SourceOptions['currency'];
    fullName: string;
  },
  nextSource: typeof SourceService.prototype.nextSource,
) {
  const { source, error } = await stripeCreateSource({
    type,
    ...(metadata ? { metadata } : {}),
    currency,
    owner: {
      name: fullName,
    },
  });

  if (source) {
    nextSource(source as SourceWithMetadata);
  }

  return { source, error };
}
