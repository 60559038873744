import { container } from 'dependency-injection';
import { bindModules } from 'bindModules';
import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router5';
import { setObservableConfig } from '@blendle/recompose';
import { from } from 'rxjs';
import { Router } from 'shared/services/Router';
import App from './App';
import './index.css';
import './polyfills';
import { getEnvVariable } from './utils/env';

setObservableConfig({
  // tslint:disable-next-line
  fromESObservable: from,
});

// output the build version
try {
  const gitCommit = getEnvVariable('REACT_APP_GIT_COMMIT');
  // tslint:disable-next-line:no-any
  (window as any)._version = gitCommit;
} catch (e) {
  // tslint:disable-next-line:no-any
  (window as any)._version = 'HEAD';
}

bindModules(container);

const { router } = container.get(Router);

const render = (Component: React.ComponentClass) => {
  return router.start(() => {
    ReactDOM.render(
      <RouterProvider router={router}>
        <Component />
      </RouterProvider>,
      document.getElementById('root')!,
    );
  });
};

render(App);

if ((module as NodeModule).hot) {
  (module as NodeModule).hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
